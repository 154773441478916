
import { useState } from "react";
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    
    Avatar,
    Radio,
    Progress,
    Tooltip,
    Divider,
    Segmented,
    Select,
    Typography,
    Empty,
    Table,


} from "antd";
import './history.css';
import {
    FacebookOutlined,
    TwitterOutlined,
    InstagramOutlined,
    VerticalAlignTopOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const project = [
    {
        title: "PROJECTS",
        dataIndex: "name",
        width: "32%",
        sorter: (a, b) => a.to - b.to,

    },
    {
        title: "RATE",
        dataIndex: "rate",
    },
    {
        title: "INVESTED",
        dataIndex: "invested",
    },
    {
        title: "DURATION",
        dataIndex: "duration",
    },
    {
        title: "COMPLETION",
        dataIndex: "completion",
    },
];



const History = (props) => {


    const [tabChoice, setTabChoice] =  useState('All');

    const { Option } = Select;

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };


    const dataproject = [
        {
            key: "1",
            name: (
                <>
                    <Avatar.Group>
                        <Avatar className="shape-avatar" src={'T'} size={25} alt="" />
                        <div className="avatar-info">
                            <Title level={5}>Vila in Brno</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            duration: (
                <>
                    <div className="semibold">9 months</div>
                </>
            ),
            rate: (
                <>
                    <div className="semibold">10%</div>
                </>
            ),
            invested: (
                <>
                    <div className="text-sm">1 940 000 kč</div>
                </>
            ),
            completion: (
                <>
                    <div className="ant-progress-project">
                        <Progress percent={72} size="small" />
                        <span>
                            <Link to="/">
                                <img src={'T'} alt="" />
                            </Link>
                        </span>
                    </div>
                </>
            ),
        },
        {
            key: "2",
            name: (
                <>
                    <Avatar.Group>
                        <Avatar className="shape-avatar" src={'T'} size={25} alt="" />
                        <div className="avatar-info">
                            <Title level={5}>Building in Prague</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            duration: (
                <>
                    <div className="semibold">18 months</div>
                </>
            ),
            rate: (
                <>
                    <div className="semibold">13.8%</div>
                </>
            ),
            invested: (
                <>
                    <div className="text-sm">350 000 kč</div>
                </>
            ),
            completion: (
                <>
                    <div className="ant-progress-project">
                        <Progress percent={100} size="small" />
                        <span>
                            <Link to="/">
                                <img  style={{background: props.colors.success}}  src={'T'} alt="" />
                            </Link>
                        </span>
                    </div>
                </>
            ),
        },
        {
            key: "3",
            name: (
                <>
                    <Avatar.Group>
                        <Avatar className="shape-avatar" src={'T'} size={25} alt="" />
                        <div className="avatar-info">
                            <Title level={5}>House in Ostrava</Title>
                        </div>
                    </Avatar.Group>
                </>
            ),
            duration: (
                <>
                    <div className="semibold">6 months</div>
                </>
            ),
            rate: (
                <>
                    <div className="semibold">7.9%</div>
                </>
            ),
            invested: (
                <>
                    <div className="text-sm">100 000 kč</div>
                </>
            ),
            completion: (
                <>
                    <div className="ant-progress-project">
                        <Progress percent={10} size="small" />
                        <span>
                            <Link to="/">
                                <img src={'T'} alt="" />
                            </Link>
                        </span>
                    </div>
                </>
            ),
        },
    ];
    

    return (
        <div className="history-page" >

            <Row gutter={[24, 24]} >
                <Col span={24} >


                    <h1 style={{color: props.colors.primary}}>
                        My investments 
                    </h1>

                </Col>
                <Col span={3} >
                    <span>city : </span>
                    <Tooltip title="Show projects of which city ?">
                        <Select defaultValue="Brno" style={{ width: 120 }} onChange={handleChange}>
                            <Option value="Prague">Prague</Option>
                            <Option value="Brno">Brno</Option>
                            <Option value="disabled" disabled>
                                Bratislava
                            </Option>
                            <Option value="Plzen">Plzen</Option>
                        </Select>
                    </Tooltip>

                </Col>
                <Col span={3} >
                    <span>Rate : </span>
                    <Tooltip title="Select the rate">
                        <Select defaultValue="15" style={{ width: 120 }} onChange={handleChange}>
                            <Option value="10"> {'>10%'}</Option>
                            <Option value="15"> {'>15%'}</Option>

                            <Option value="20">{'>20%'}</Option>
                        </Select>
                    </Tooltip>
                </Col>
                <Divider style={{ height: '60px' }} type="vertical" />

                <Col span={3} >
                    <Radio.Group name="radiogroup" defaultValue={1}>
                        <Radio value={1}>A</Radio>
                        <Radio value={2}>B</Radio>
                        <Radio value={3}>C</Radio>

                    </Radio.Group>
                </Col>

                <Col span={10} >
                </Col>
            </Row>
            <Divider />
            <Row gutter={[24, 24]} >
                <Col span={24}>
                    <Segmented onChange={setTabChoice}

                        value={tabChoice} block options={['All', ' In progress', 'Completed', 'Not Funded']} />

                </Col>


                <Col span={24}>
                    <div className="table-responsive">
                        {
                            tabChoice === 'All' ?
                                <Table
                                    columns={project}

                                    dataSource={dataproject}
                                    pagination={true}
                                    className="ant-border-space"
                                />
                                :
                                <Empty />

                        }

                    </div>
                </Col>

            </Row>

        </div>
    );
};

export default History;
