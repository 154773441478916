
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import printPrice from "../../../Tools/printPrice";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  BackTop,
  Divider,
  Typography,
  message,
} from "antd";
import { EuroOutlined, LineChartOutlined, HourglassOutlined, SmileOutlined } from '@ant-design/icons';
import './dashboard.css';
import notification from '../../../components/Notifications/Notification'
import StackedBarChart from "../../../components/Charts/StackedBarChart";
import TimelineHistory from '../../../components/Charts/TimelineHistory';
import LineCharts from "../../../components/Charts/LineChart";




const Dashboard = (props) => {
  const { t, i18n } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [dashboardHeader, setDashboardHeader] = useState([]);
  const [quarters, setQuarters] = useState({});


  function getMyQuarters() {
    let myToken = localStorage.getItem('bearer_token');

    axios.post('api/statistics/getQuartersDashboard', { userid: props.user.id }, //TODO: check if i cant just say -> api/auth/verify
      { headers: { authorization: `Bearer ${myToken}` } }
    )
      .then((res) => {
        if (res.data.status) {
         
          setQuarters(res.data.myQuarters)
        } else {

          notification('warning', t('keyword_problem'), 'We could not reach your profits'); //TOTRANSLATE

        }
      })
      .catch((err) => {
        console.log(err);
        notification('error', t('keyword_problem') , err);

      });

  }

  function getMyTransactions() {
    let myToken = localStorage.getItem('bearer_token');

    axios.post('api/statistics/getMyTransactions', { userid: props.user.id }, //TODO: check if i cant just say -> api/auth/verify
      { headers: { authorization: `Bearer ${myToken}` } }
    )
      .then((res) => {
        if (res.data.status) {
          setTransactions(res.data.transactions);
        } else {
          notification('warning',  t('keyword_problem'), 'Server problem, please contact an administrator');//TOTRANSLATE
        }
      })
      .catch((err) => {
        console.log(err);
        notification('error',  t('keyword_problem') , err);

      });

  }
  function getDashboardHeader() {
    let myToken = localStorage.getItem('bearer_token');

    axios.post('api/statistics/getDashboardHeader', { userid: props.user.id }, //TODO: check if i cant just say -> api/auth/verify
      { headers: { authorization: `Bearer ${myToken}` } }
    )
      .then((res) => {
        if (res.data.status) {

          let dashboard=[
            {
              today: t('dashboard_top_card_title_1'),
              title: `${printPrice(res.data.dahsboardHeader.total_promised)} kč`,
              persent: `${t('keyword_promised')} : ${printPrice(res.data.dahsboardHeader.total_paid)} kč`,
              icon: <LineChartOutlined />,
              bnb: "green-font-text",
            },
            {
              today: t('dashboard_top_card_title_2'),
              title: printPrice(res.data.dahsboardHeader.profit) + " kč",
              persent: t('keyword_newest')+" : "+ (res.data.dahsboardHeader.newest_date && res.data.dahsboardHeader.newest_date.split('T')[0]),

              icon: <SmileOutlined />,
              bnb: "green-font-text",
            },
            {
              today: t('dashboard_top_card_title_3'),
              title: res.data.dahsboardHeader.my_transactions,
             // persent: t.cz.keyword_newest+" : "+ (res.data.dahsboardHeader.newest_date && res.data.dahsboardHeader.newest_date.split('T')[0]),
              persent: "Smazáno : "+ res.data.dahsboardHeader.deleted_transactions  ,
             icon: <EuroOutlined />,
              bnb: "red-font-text",
            },
            {
              today: t('dashboard_top_card_title_4'),
              title: res.data.dahsboardHeader.my_projects,
              persent: t('keyword_ended') + ' : '+ res.data.dahsboardHeader.my_ended_projects,
              icon: <HourglassOutlined />,
              bnb: "green-font-text",
            },


          ]


          setDashboardHeader(dashboard);

        } else {

          notification('warning', 'Missing data', 'We could not reach your header informations');

        }
      })
      .catch((err) => {
        console.log(err);
        notification('error',  t('keyword_problem'), err);

      });

  }
  useEffect(() => {
    getMyTransactions();
    getDashboardHeader();
    getMyQuarters();
  }, []);




  const { Title, Text } = Typography;


  return (
    <div className="dashboard-page" >
      <Row align="center" >
        <Divider ><h1 style={{ fontSize: '120%' }} >{`${props.user.first_name} ${props.user.last_name} ${t('keyword_dashboard')}`} { }</h1></Divider>
      </Row>

      <Row className="rowgap-vbox" gutter={[24, 24]}>
        {dashboardHeader && dashboardHeader.map((c, index) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>{c.today}</span>
                    <Title level={3}>
                      {c.title}<br /> <small className={c.bnb}>{c.persent}</small>
                    </Title>
                  </Col>
                  <Col xs={6}>
                    <div className="icon-box">{c.icon}</div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      <Divider dashed />
      <Row gutter={[24, 24]} >

        <Col
          style={{ maxHeight: '50vh', minHeight: '30vh', overflow: 'scroll' }}
          key={'chart1'}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="mb-24"
        >
          <StackedBarChart  datas={quarters} colors={props.colors} />

        </Col>
        <Col
          style={{ maxHeight: '50vh', overflow: 'scroll' }}
          key={'chart2'}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="mb-24"
        >
          <TimelineHistory transactions={transactions} color={props.colors} />
        </Col>
        <Col
          style={{ maxHeight: '50vh', minHeight: '30vh', overflow: 'scroll' }} key={'chart3'}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="mb-24"
        >


          {/* <LineCharts/> */}

        </Col>


      </Row>





    </div>
  );
};

export default Dashboard;