import { useState } from 'react';
import { useTranslation } from "react-i18next";
import './auth.css';
import axios from 'axios';
import {
  Result,
  Card,
  Row,
  Col,
  Button,
  Checkbox,
  Modal,
  Form,
  Input,
} from 'antd';
import { FrownOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import losanges from '../../../images/losanges.jpeg';
import house from '../../../images/house.webp';
import pfg from '../../../images/pfg-logo-blue.png';
import notification from '../../../components/Notifications/Notification';
import { Navigate } from 'react-router-dom';

const Register = (props) => {
  const { t, i18n } = useTranslation();

  const [registered, setRegistered] = useState(false);
  const [gdprAccept, setGdprAccept] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);

  const onFinish = (values) => {

    if (values.password !== values.conf_password) {
      setButtonPressed(false);
      return notification('error', 'Wrong data', 'Both password are different'); //TOTRANSLATE
    }
    if (!gdprAccept) {
      setRegistered(true);

      return false;

    } else {

      setButtonPressed(true);


      axios
        .post(`api/auth/register`, values)
        .then((res) => {
          notification('success', 'Login success', 'You can now log in');

          window.location.href = '/login'

        })
        .catch((err) => {
          console.log(err.response.data.msg);
          setButtonPressed(false);
          notification('error', 'Registration error', err.response.data.msg);
        });


    }



  };

  return (
    <div className="losanges1">
      <Row className="losanges" md={24} xs={24} justify="center" align="center">
        <Col
          md={14}
          xs={0}
          className="losanges2"
          style={{
            backgroundImage: `url('${house}')`,
            backgroundRepeat: 'no-repeat',
          }}
        ></Col>

        <Col
          xs={10}
          style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Row align="center" justify="center">
            <img style={{ width: '130px' }} src={pfg} />
          </Row>

          <Row align="center" justify="center">
            <h2 style={{ color: props.colors.primary }}> {t('keyword_sign_up')} </h2>
          </Row>

          <Row align="center" justify="center" xs={24}>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                phone_number: '+420',
              }}
              onFinish={onFinish}
              style={{ width: '80%' }}
            >
              <Input.Group compact>
                <Form.Item
                  name="first_name"
                  rules={[
                    { required: true, message: t('keyword_firstname') },
                    { min: 2, message: '2 characters minimum' },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(50% - 5px)',
                  }}
                >
                  <Input
                    style={{ height: '60px', borderRadius: '10px' }}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="First name"
                  />
                </Form.Item>

                <Form.Item
                  className="dashboardSearch"
                  name="last_name"
                  style={{
                    display: 'inline-block',
                    width: 'calc(50% - 5px)',
                    margin: '0 0 0 10px ',
                  }}
                  rules={[
                    { required: true, message: t('keyword_lastname') },
                    { min: 2, message: t('register_verifications_name_1') },
                  ]}
                >
                  <Input
                    style={{ height: '60px', borderRadius: '10px' }}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Last name"
                  />
                </Form.Item>
              </Input.Group>

              <Form.Item
                className="dashboardSearch"
                name="email"
                style={{ borderRadius: '15px' }}
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: t('login_verification_email_1'),
                  },
                ]}
              >
                <Input
                  style={{ height: '60px', borderRadius: '10px' }}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder={t('keyword_email')}
                />
              </Form.Item>

              <Form.Item
                className="dashboardSearch"
                name="phone_number"
                style={{ borderRadius: '15px' }}
                rules={[
                  { required: true, message: t('keyword_phone') },
                  { min: 9, message: t('register_verifications_phone_1') },
                ]}
              >
                <Input
                  style={{ height: '60px', borderRadius: '10px' }}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder={t('keyword_phone')}
                />
              </Form.Item>

              <Input.Group>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: t('keyword_password') },
                    {
                      min: 8,
                      message: t('login_verification_password_1'),
                    },
                  ]}
                >
                  <Input
                    style={{ height: '60px', borderRadius: '10px' }}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t('keyword_password')}
                    type="password"
                  />
                </Form.Item>

                <Form.Item
                  className="dashboardSearch"
                  name="conf_password"
                  style={{ borderRadius: '15px' }}
                  rules={[
                    {
                      required: true,
                      message: t('keyword_repeat_password'),
                    },
                  ]}
                >
                  <Input
                    style={{ height: '60px', borderRadius: '10px' }}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t('keyword_repeat_password')}
                  />
                </Form.Item>
              </Input.Group>

              <br />

              <Form.Item
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  id='regButton'
                  loading={buttonPressed}
                  //   className="login-form-button"
                  style={{
                    borderRadius: '10px',
                    background: '#003464',
                    color: 'white',
                    border: '#003464',
                    height: '50px',
                    width: '300px',
                  }}
                >
                  {t('keyword_sign_up') }
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </Col>

        <Modal
          title={t('register_gdpr_title')}
          centered
          closable={false}
          open={registered}
          footer={[
            <Button
              key="reject"
              type="danger"
              onClick={() => {
                location.href = '/';
              }}
            >


              {t('keyword_refuse')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setGdprAccept(true);
                setRegistered(false);
                document.getElementById("regButton").click();

              }}
            >
              {t('keyword_accept')}
            </Button>,
          ]}
          width={1000}
        >
          {/* TODO: set real text there */}
          <p>
            {' '}
            {t('register_gdpr_description')}

          </p>


        </Modal>


      </Row>
    </div>
  );
};

export default Register;
