import React, { Component } from "react";

// Import antd
import { Button, Col, Result, Row } from "antd";

class Error extends Component {
  defaultMessage(status) {
  
    switch (status) { //TOTRANSLATE
      case "404":
        return "Sorry, the page you visited does not exist.";
      case "500":
        return "Sorry, something went wrong.";
      case "403":
        return "Sorry, you are not authorized to access this page.";
      default:
        return "Sorry, something went wrong.";
    }
  }

  render() {
    return (
      <Row justify="center" align="middle">
        <Col>
          <Result
            status={this.props.status}
            title={this.props.title}
            subTitle={this.defaultMessage(this.props.status)}
            extra={
              <Button type="primary" href="/">
                Back Home
              </Button>
            }
          />
        </Col>
      </Row>
    );
  }
}

export default Error;
