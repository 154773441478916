import { CaretRightOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Image, Collapse, Divider, Row, Col, Button, Card, Result } from 'antd';
import React, { useState } from 'react';
import CardProjectDetails from '../../../components/Cards/CardProjectDetails';
import axios from 'axios';
import notification from "../../../components/Notifications/Notification";
import CardProjectDetails2 from '../../../components/Cards/CardProjectDetails2';
const NewProject_preview = (props) => {
    const { t, i18n } = useTranslation();


    const { Panel } = Collapse;
    const { project, mediaList, fileList, freezeButtons, setFreezeButtons } = props;
    const [createdProject, setCreatedProject] = useState({status: false, project: {}});
   






    function upload() {

        let verify = verifyData();
      


        if (verify) {
            setFreezeButtons(true);
            axios.post(`api/project/createProject`, {
                medias: mediaList, project: project, fileList: fileList
            }, { headers: { authorization: `Bearer ${localStorage.getItem('bearer_token')}` } })
                .then(res => {
                    
                    if(res.data.isCreated){
                        setCreatedProject({status: res.data.isCreated, project: res.data.project });
                    }else{
                        notification('error', t('keyword_problem') , res.data.message );
                        setFreezeButtons(false);

                    }
            

                }).catch(err =>{
                
                    console.error(err.response.data);
                    notification('error', t('keyword_problem'), err.response.data );

                    setFreezeButtons(false);
                }
                );

        }

    }


    function verifyData() {

        const { annual_rate, title, description, target, due_date, start_date,
            closing_date, partner_id, location, is_public, minimum_amount } = project;
        let today = new Date();
        let e = 'error';
        let i = t('create_project_verifications_main_2');
        let m = t('create_project_verifications_main_3');

        if (!annual_rate) {
            notification(e, m, t('create_project_verifications_annual_rate_1'));
            return false;
        }
        if (!title) {
            notification(e, m, t('create_project_verifications_title_1'));
            return false;
        } if (!description) {
            notification(e, m,  t('create_project_verifications_description_1'));
            return false;
        } if (!target) {
            notification(e, m,  t('create_project_verifications_total_1'));
            return false;
        } if (!minimum_amount) {
            notification(e, m,  t('create_project_verifications_minimum_amount_1'));
            return false;
        } if (!location) {
            notification(e, m,  t('create_project_verifications_city_1') );
            return false;
        } if (!partner_id) {
            notification(e, m,  t('create_project_verifications_parttner_1')
                );
            return false;
        }if (minimum_amount>target) {
            notification(e, m, `Minimum amount can't be higher than target -> Main informations tab ` 
                );
            return false;
        }

        if (!mediaList.length) {
            notification(e, m,  t('create_project_verifications_pictures_1'));
            return false;
        }
        if (today > due_date) {
            notification(e, i,  t('create_project_verifications_due_date_1'));
            return false;
        }if (due_date < closing_date) {
            notification(e, i,  t('create_project_verifications_due_date_closing_date_1'));
            return false;
        }

        if (start_date > closing_date) {
            notification(e, i, t('create_project_verifications_start_date_1'));
            return false;
        }

        return true;
    }

    return (


        createdProject.status ?

        <Result
        status="success"
        title={t('created_project_title_1')}
        subTitle={project.title}
        extra={[
          <Button type="primary" key="projectView" href={`project?id=${createdProject.project.id}`}   >
            {t('created_project_button_project_1')}
          </Button>,
          <Button key="Market" href='market' >{t('created_project_button_market_1')}</Button>,
        ]}
      />
            :
            <>
                <Row align='center'  >
                    <Button primary
                        loading={freezeButtons}
                       
                        onClick={() => { upload() }}>
                       {t('keyword_create_project')}
                    </Button>
                </Row>
                <Divider />

                <Row gutter={[24, 48]} >
                    <Col xs={24} sm={24} md={24} lg={10} >

                        <CardProjectDetails isPreview={true} project={project} colors={props.colors} />


                    </Col>




                    <Col xs={24} sm={24} md={24} lg={14} /* xl={8} xxl={8}  */ >

                        <CardProjectDetails2 url={mediaList} isPreview={true} project={project} fileList={fileList} colors={props.colors} />

                    </Col>
                </Row>

            </>


    );
}
export default NewProject_preview;