import React, { useState, useMemo, useEffect, useRef, useLayoutEffect } from 'react'
import { Navigate, Link } from "react-router-dom"
import axios from 'axios'
import {
    BrowserRouter as Router,
    Route,
    Routes,

} from "react-router-dom";
import jwt_decode from "jwt-decode";

import { Spin,Button, Result } from 'antd';
import RefreshTokenComponent from '../components/RefreshTokenComponent/RefreshTokenComponent';


const PrivateRoute = ({ path, Element, user, rolesAllowed, killUser }) => {

    const [tokenValid, setTokenValid] = useState(true);





    useEffect(() => {

        const token = localStorage.getItem('bearer_token');
        if (token) {
            const decoded = jwt_decode(token);
         
            const dateNow = new Date();

            if ((decoded.exp * 1000) < dateNow.getTime()) {



                setTokenValid(false);
                killUser();
            }

        } else {
            location.href = '/login'
        }

    }, [path]);

    return (

        !tokenValid ?
            <RefreshTokenComponent />

            :
            rolesAllowed.includes(user.role) ?
                React.cloneElement(Element, { user: user })
                :
                <Result
                    status="403"
                    title="403"
                    subTitle="Sorry, you are not authorized to access this page." //TOTRANSLATE
                    extra={<Button href='dashboard' type="primary">Back Home</Button>}
                />


    )


}


export default PrivateRoute;