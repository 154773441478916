import React, { Component, useState, useEffect, createContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
// CSS IMPORT
import './Main.css';
import COLORS from '../Tools/Colors';
import routes from './Routes';
// VIEWS IMPORT LOGOUT
import house from '../images/pfg-logo.png'
import LoadingScreen from "react-loading-screen";

// VIEWS IMPORT LOGIN
import Dashboard from '../views/logIn/Dashboard/Dashboard';
import Error from '../views/logIn/Errors/Error';
import History from '../views/logIn/History/History';
import Market from '../views/logIn/Market/Market';
import Project from '../views/logIn/Project/Project';
import Settings from '../views/logIn/Settings/Settings';
import AdminPage from '../views/logIn/AdminPage/AdminPage';

import Transactions from '../views/logIn/Transactions/Transactions';
import newProject from '../views/logIn/NewProject/NewProject';
import { UpOutlined, MenuOutlined } from '@ant-design/icons';

import logo from '../images/pfg-logo.png';

// COMPONENTS IMPORT
import FooterPage from '../components/Footer/LogOutFooterPage';
import Navigation from '../components/Header/LogOutNavigation';
import NotificationsAvatar from '../components/HeaderItems/NotificationsAvatar';
import Sidnav from './Sidenav';

// ANTD IMPORT
import { Layout, Button, Menu, Avatar, Spin, Drawer, Row, Col } from 'antd';
import 'antd/dist/antd.min.css';
import Register from '../views/logOut/Auth/Register';
import PrivateRoute from './PrivateRoute';
import NewProject from '../views/logIn/NewProject/NewProject';
import PasswordReset from '../views/logOut/Auth/PasswordReset';

const { Content, Footer, Header, Sider } = Layout;



class LoggedMain extends Component {

  constructor(props) {
    super(props);


  this.state = {
    isToggled: true,
    mobileDrawer: false,
    user: {},
    loggedIn: false,
    firstToggle:true,

  };


}

  toggleTrueFalse = () => {
    this.setState({
      isToggled: !this.state.isToggled,
      mobileScreen: false,
    
    });
  };

  onClose = () => {
    this.setState({
      isToggled: false,
    });
  };



  killUser = () =>{
    this.setState({user: {}});
  }


  componentDidMount() {

    let myToken = localStorage.getItem('bearer_token');
    axios.post("verify", {},
      { headers: { authorization: `Bearer ${myToken}` } }).then((response) => {


        if (response.data.authed === true) {
          
       
          this.setState({ user: response.data.user, loggedIn: true, isToggled:true });
          
          return null;

        } else {
    
          this.setState({loggedIn: false });
         location.href = "/login";
        }
      }).catch((err) => {
        console.log(err);
        
        location.href = "/login";
      });



  }




  render() {

    return (

      !this.state.loggedIn ?
        <div>
       
          <LoadingScreen
            loading={true}
            bgColor="#003464"
            spinnerColor="#FFFFFF"
            textColor="#FFFFFF"
            logoSrc={house}
            text="Loading... please wait"
          >
           fghjk
          </LoadingScreen>
        </div>

        :


        <>
          <Header
            style={{
              backgroundColor: COLORS.primary,
              height: '10vh',
              padding: 0,
            }}
          >
            <Row
              xs={24}
              style={{ height: '100%', background: COLORS.primary }}
            >
              <Col xs={2} md={2} lg={0} style={{ margin: 'auto' }}>
                <Avatar
                  style={
                    !this.state.isToggled
                      ? { display: 'none' }
                      : { display: 'block', background: COLORS.primary }
                  }
                  type="primary"
                  onClick={() => this.setState({ mobileDrawer: true })}
                  size={48}
                >
                  {' '}
                  <MenuOutlined />
                </Avatar>

                <Drawer
                  title="Menu"
                  placement="left"
                  onClose={() => this.setState({ mobileDrawer: false })}
                  visible={this.state.mobileDrawer}
                >
                  <Sidnav
                    isToggled={this.state.isToggled}
                    isMobile={!this.state.mobileDrawer}
                    colors={COLORS}
                    user={this.state.user}
                  />
                </Drawer>
              </Col>

              <Col xs={19} md={19} align="left" justify="left">
                <img
                  onClick={() => {
                    window.location.href = '/app/dashboard';
                  }}
                  style={{
                    size: '100%',
                    width: '105px',
                    marginLeft: '20px',
                    cursor: 'pointer',
                  }}
                  src={logo}
                  alt=""
                />
              </Col>
              <Col
                align="right"
                justify="right"
                xs={2}
                md={2}
                lg={4}
                style={{ margin: 'auto', right: 15 }}
              >
                <NotificationsAvatar userId={this.state.user.id} colors={COLORS} />
              </Col>
              <Col xs={1}></Col>

              {/* <UserAvatar colors={COLORS} />
          <SettingsAvatar colors={COLORS} />
          <NotificationsAvatar colors={COLORS} /> */}
            </Row>
          </Header>
          <Layout>
            <Sider
              style={this.state.mobileScreen ? { display: 'none' } : {}}
              theme="light"
              breakpoint="lg"
              defaultCollapsed={true}
              collapsedWidth={'70'}
              collapsed={this.state.firstToggle ? true : this.state.isToggled}
              onBreakpoint={(broken) => {
                this.setState({
                  isToggled: broken,
                  mobileScreen: broken,
                });
              }}
              className="hideOnMobile"
              onCollapse={this.onCollapse}
              //  style={this.state.isToggled ? { display: 'none' } : { display: 'block' }}
              onMouseEnter={() => {
                this.setState({ isToggled: false, firstToggle:false });
              }}
              onMouseLeave={() => {
                this.setState({ isToggled: true, firstToggle:false });
              }}
            >
              <Sidnav user={this.state.user} colors={COLORS} />
            </Sider>

            <Content
              style={{
                minHeight: 280,
                overflow: 'scroll',
                padding: '20px',
              }}
            >


              <Routes>
                {/* ================================================== */}
                {/* LOGOUT ROUTES */}

                {/* While error with routes */}
                {/* <Route path="/error/404" element={<Error status='404' title='404' />} />  */}
                {/* ================================================== */}

                {/* ================================================== */}
                {/* LOGIN ROUTES */}





                {/* {routes.map(route =>
                <Route
                  path={route.path}
                  key={route.key}
                  element={
                    <PrivateRoute
                      path={route.path}
                      key={route.path}
                      Element={React.cloneElement(route.component, { colors: COLORS })}
                    />
                  }
                />



              )
              } */}

                <Route
                  path="/app/dashboard"
                  element={
                    <PrivateRoute
                      path="/app/dashboard"
                      killUser={this.killUser}
                      user={this.state.user}
                      rolesAllowed={['investor', 'creator', 'admin', 'super_admin']}
                      Element={<Dashboard colors={COLORS} />}
                    />
                  }
                />

                <Route
                  path="/app/newProject"
                  element={
                    <PrivateRoute
                    killUser={this.killUser}
                      rolesAllowed={['admin', 'super_admin']}
                      path="/app/newProject"
                      user={this.state.user}
                      Element={<NewProject colors={COLORS} />}
                    />
                  }
                />
                <Route
                  path="/app/settings"
                  element={
                    <PrivateRoute
                      path="/app/settings"
                      killUser={this.killUser}
                      user={this.state.user}
                      rolesAllowed={['investor', 'creator', 'admin', 'super_admin']}
                      Element={<Settings colors={COLORS} />}
                    />
                  }
                />
                <Route
                  path="/app/admin"
                  element={
                    <PrivateRoute
                      path="/app/admin"
                      killUser={this.killUser}
                      user={this.state.user}
                      rolesAllowed={['admin', 'super_admin']}
                      Element={<AdminPage colors={COLORS} />}
                    />
                  }
                />
                <Route
                  path="/app/project"
                  element={
                    <PrivateRoute
                    killUser={this.killUser}
                      user={this.state.user}
                      path="/app/project"
                      rolesAllowed={['investor', 'creator', 'admin', 'super_admin']}
                      Element={<Project colors={COLORS} />}
                    />
                  }
                />
                {/* <Route
                path="/app/history"
                element={
                  <PrivateRoute
                    path="/app/history"
                    rolesAllowed={[]}
                    Element={<History colors={COLORS} />}
                  />
                }
              /> */}
                {/* <Route
                path="/app/transactions"
                element={
                  <PrivateRoute
                    path="/app/transactions"
                    rolesAllowed={[]}
                    Element={<Transactions colors={COLORS} />}
                  />
                }
              /> */}
                {/* <Route
                path="/app/report"
                element={<PrivateRoute
                  path="/app/report"
                  Element={<Projects colors={COLORS} />}
                />}
              /> */}
                <Route
                  path="/app/market"
                  element={
                    <PrivateRoute
                      path="/app/market"
                      user={this.state.user}
                      
                      rolesAllowed={['investor', 'creator', 'admin', 'super_admin']}
                      killUser={this.killUser}
                      Element={<Market colors={COLORS} />}
                    />
                  }
                />

                {/* While error with routes */}
                <Route
                  path="/app/error"
                  element={
                    <PrivateRoute
                      path="/app/error"
                      rolesAllowed={['investor', 'creator', 'admin', 'super_admin']}
                      killUser={this.killUser}
                      user={this.state.user}
                      Element={<Error status="404" title="404" />}
                    />
                  }
                />

                {/* Redirection */}
                <Route
                  path="/app"

                  element={<Navigate to="/app/dashboard" />}
                />
                <Route
                  path="/app/*"
                  element={<Navigate to="/app/dashboard" />
                  }
                />

                <Route path="/*" element={<Navigate to="/" />} />
                {/* ================================================== */}
              </Routes>
            </Content>
          </Layout>
          <Footer
            style={{
              textAlign: 'center',
              background: COLORS.primary,
              color: 'white',
            }}
          >
            Created with love by PFG dev team
          </Footer>
        </>





    );
  }

}

export default LoggedMain;