import React from "react";

import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18lng', lng);
  }


  return (
    <Row
      style={{ maxWidth: "1500px", margin: "auto", backgroundColor: "#f0f0f0" }}
      justify="space-between"
    >
      <Col xs={24} lg={8}>
        <Row style={{ fontSize: "1.5em", fontWeight: "bold" }}>
          {t('footer_links_title')}
        </Row>
        <Row><a href="/" style={{ color: "#000" }}>{t('footer_links_manual')}</a></Row>
        <Row><a href="/manual" style={{ color: "#000" }}>{t('footer_links_main_page')}</a></Row>
        <Row><a href="/Q&A" style={{ color: "#000" }}>{t('footer_links_FAQ')}</a></Row>
        <Row><a href="/contact" style={{ color: "#000" }}>{t('footer_links_contact')}</a></Row>
        <Row><a href="/login" style={{ color: "#000" }}>{t('footer_links_login')}</a></Row>
        <Row><a href="/register" style={{ color: "#000" }}>{t('footer_links_registration')}</a></Row>
      </Col>
      <Col xs={24} lg={8} style={{ marginTop: "20px" }}>
        <Row style={{ fontSize: "1.5em", fontWeight: "bold" }}>{t('keyword_address')}</Row>
        <Row>Hlinky 138/27</Row>
        <Row>603 00 Brno</Row>
      </Col>
      <Col xs={24} lg={8} style={{ marginTop: "20px" }}>
        <Row style={{ fontSize: "1.5em", fontWeight: "bold" }}>{t('keyword_language')}</Row>
        <Row>
          <a style={i18n.language === 'en' ? { color: '#003464' } : { color: 'gray' }}
            onClick={() => { handleChangeLng('en') }} >English</a>
          /
          <a style={i18n.language === 'cz' ? { color: '#003464' } : { color: 'gray' }}
            onClick={() => { handleChangeLng('cz') }} >Czech</a>
        </Row>

      </Col>
      <Col xs={24} lg={8} style={{ marginTop: "20px" }}>
        {/* <Row style={{ fontSize: "1.5em", fontWeight: "bold" }}>
          {t('footer_documents_title')}
        </Row>
         <Row>{t('footer_documents_text_1')}</Row>
          <Row>{t('footer_documents_text_2')}</Row>
           <Row>{t('footer_documents_text_3')}</Row>
            <Row>{t('footer_documents_text_4')}</Row>
             <Row>{t('footer_documents_text_5')}</Row>
              <Row>{t('footer_documents_text_6')}</Row> */}
      </Col>
    </Row>
  );
};

export default Footer;
