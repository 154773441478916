import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios';


// Import antd
import { Button, Col, Drawer, Menu, Row,Result, Typography} from "antd";
import { ManOutlined, MenuOutlined, InfoCircleOutlined } from "@ant-design/icons";


const { Paragraph, Text } = Typography;


const RefreshTokenComponent = (props) => {
  const { t, i18n } = useTranslation();



    useEffect(() => {


       


    }, []);



    function refreshToken(){

        // axios.get(`api/project/refresh-token`,  {headers: { authorization: `Bearer ${localStorage.getItem('bearer_token')}` }})
        // .then(res => {
         
        //     setHeaderInfos(res.data)
        // }).catch(err =>
        //     console.log(err)
        // )


    }

    return (
    
       
       <Result
    status="error"
    title= {t('session_expired_title_1')}
    subTitle= {t('session_expired_subtitle_1')}
    extra={[
      <Button type="primary" href='/login'  key="loginPage">
        {t('session_expired_button_1')}
      </Button>

    ]}
  >
    <div className="desc">
      <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
          }}
        >
           {t('session_expired_question_1')}
        </Text>
      </Paragraph>
      <Paragraph>
      <InfoCircleOutlined />  {t('session_expired_answer_1')}
      </Paragraph>
   
    </div>
  </Result>

      
    );
};

export default RefreshTokenComponent;
