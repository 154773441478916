import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Link,useNavigate } from "react-router-dom";
import routes from "./Routes";
import { Menu, Row, Col, Divider } from "antd";

import logo from "../images/pfg-logo.png";

import {
   
    StopTwoTone,
} from '@ant-design/icons';


import FakeSuspense from "../Tools/FakeSuspense";



const Sidenav = (props) => {
    const { t, i18n } = useTranslation();

    let navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);

    const { user } = props;




    const logout = () => {
        localStorage.removeItem('bearer_token');

        window.location.reload(false);

    }

    return (
        <>


            <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">


                {
                    routes.map(item =>
                        item.allowedRoles.includes(user.role) && !item.hidden &&
                        <Menu.Item key={item.key}>
                            {item.logo}
                            <span>{item.navName}</span>
                            <Link to={item.url} />
                        </Menu.Item>


                    )
                }

                <Divider />




                <Menu.Item onClick={logout} key='logout'>
                    <StopTwoTone />
                    <span>{t('keyword_logout')}</span>
                    {/* <Link to='/' /> */}
                </Menu.Item>


            </Menu>


        </>
    );
}

export default Sidenav;