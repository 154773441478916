import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';

import {
  Row,
  Col,
  Tooltip,
  Card,
  Divider,
  Spin,
  Progress,
  Carousel,
  Image,
} from "antd";
import { useTranslation } from "react-i18next";

import { EyeTwoTone, BellOutlined, SettingOutlined } from "@ant-design/icons";



const Map = () => {
  return (
    <GoogleMap
      zoom={14}
      center={{ lat: 49.190302, lng: 16.596358 }}
      
      mapContainerClassName="map-container"
    ><Marker position={{ lat: 49.1896251, lng: 16.5867397 }} />
    <Marker position={{ lat: 49.1913006, lng: 16.6087425 }} />
    </GoogleMap>
  );
};


const CardContactInfos = (props) => {
  const { t, i18n } = useTranslation();


    const { isLoaded } = useLoadScript({
      googleMapsApiKey: 'AIzaSyBbBR4COwa-sLqoQtgjS9g0naPd75_4HAs',
    });



  return (
    <Card style={{height: "100%", borderRadius: "10px"}}>
      <Row gutter={[24, 24]} classname="contact">
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <h2>{t('contact_details_company_title')}</h2>
          <br />

          <h5>PFG a.s.</h5>
          <h5>Pražákova 1008/69, Štýřice,</h5>
          <h5>639 00, Brno</h5>
          <h5>Pondělí - pátek: 9.00 - 17.00</h5>
        </Col>
        <Divider xs={1} type="vertical" dashed />
          <Col xs={23} sm={23} md={11} lg={11} xl={7}>
          <h2>{t('contact_details_bank_details')}</h2>
          <br />
          <h5>č.ú. 799 299 4002/5500</h5>
          <h5> IBAN: CZ7155000000007992994002</h5>
          <h5>SWIFT/BIC: RZBCCZPP</h5>
       
          <h5>Raiffeisenbank a.s. </h5>
        </Col>
        <Divider xs={1} type="vertical" dashed />
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <h2>{t('contact_details_contact_us')}</h2>
          <br />
          <h5> Tel. +420 533 534 440</h5>
          <h5> info@pfg.cz</h5>
          <h5>www.pfg.cz</h5>
        </Col>

       

        <Col style={{width: '400px'}} xs={24}>
          {!isLoaded ? (
            <Spin size="large" className="custom-spin" />
          ) : (
            <Row justify="left" align="left" xs={24}>
              <Col align="center" xs={24} justify="center">
                <Card style={{ height: '100%', borderRadius: '10px' }}>
                  <Map />
                </Card>
              </Col>
            </Row>
          )}
        </Col>
        
      </Row>
    </Card>
  );
};

export default CardContactInfos;
