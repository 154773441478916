import { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import axios from 'axios';
import check from '../../../images/check.svg';
import nocheck from '../../../images/no-check.svg';
import {
  Row,
  Col,
  Divider,
  Input,
  Table,
  Button,
  DatePicker,
  Dropdown,
  Rate,
  Steps,
  Popover,
  Upload,
  Select,
  Tag,
  Space,
  Modal,
} from 'antd';

import {
  SearchOutlined,
  CrownTwoTone,
  CheckSquareFilled,
  CloseSquareFilled,
} from '@ant-design/icons';
import DragUpload from '../../../components/CRUD_project/DragUpload';
import PopupUser from './PopupUser';
import notification from '../../../components/Notifications/Notification';

const { MonthPicker, RangePicker } = DatePicker;

const { Dragger } = Upload;
const { Step } = Steps;
const { TextArea } = Input;
const { Column, ColumnGroup } = Table;
import ConfirmationPopup from './ConfirmationPopup';

const Transactions = (props) => {
  const { t, i18n } = useTranslation();

  const [transactions, setTransactions] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [idDeleteTransaction, setIdDeleteTransaction] = useState(null);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const deleteTransaction = (id) => {
    axios
      .post(
        'api/transaction/deleteTransaction',
        { transaction_id: id },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          getTransaction();
          notification(
            'success',
            t('keyword_deleted_successfully'),
            res.data.message
          );
        }
      })
      .catch((err) => {
        notification('error', t('keyword_problem'), res.data.message);
        console.log(err);
      });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  function getTransaction() {
    axios
      .get('api/transaction/getTransactions', {
        headers: {
          authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setTransactions(res.data.transactions);
        } else {
          notification('error', t('keyword_problem'), res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        notification('error', t('keyword_problem'), err.response.data.message);
      });
  }

  function updateTransaction(id, step, value, user_id) {
    axios
      .post(
        'api/transaction/updateTransaction',
        { id: id, step: step, value: value, user_id: user_id  },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setTransactions(res.data.transactions);
        } else {
          notification('error', t('keyword_problem'), res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        notification('error', t('keyword_problem'), err.response.data.message);
      });
  }

  useEffect(() => {
    getTransaction();
  }, []);


  function deleteButtonPushed(text) {

    setOpenDelete(true);
    setIdDeleteTransaction(text);
  }



  const columns = [
    {
      title: t('keyword_project_title'),
      dataIndex: 'title',
      //   render: (text) => <a>{text}</a>,
    },
    {
      title: t('keyword_investors'),
      dataIndex: 'user',
      ...getColumnSearchProps('user'),
    },
    {
      title: t('keyword_amount'),
      dataIndex: 'amount',
      render: (text) => text && <span>{text} kč</span>,
    },
    {
      title: t('keyword_to_target'),
      dataIndex: 'amount',
      responsive: ['lg'],
      render: (text, row) =>
        text && <span>{eval(row.target) - eval(row.total_paid)} kč</span>,
    },

    {
      title: t('keyword_creation_date'),
      dataIndex: 'date',
      responsive: ['md'],
      render: (text) => text && <span>{text.split('T')[0]}</span>,
    },
    {
      title: t('keyword_account'),
      dataIndex: 'bank_account',
      ...getColumnSearchProps('bank_account'),
      responsive: ['xl'],
      render: (text) => (
        <>
          {text && (
            <Tag color="blue" key={text}>
              {text}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: `${t('keyword_contract_sent')} ?`,
      dataIndex: 'contract_sent',
      render: (text) => (
        <div style={{ maxWidth: '30px' }}>
          {' '}
          {text ? <img src={check} /> : <img src={nocheck} />}
        </div>
      ),
    },
    {
      title: t('keyword_approved'),
      dataIndex: 'status',
      render: (text) => (
        <div style={{ maxWidth: '30px' }}>
          {' '}
          {text ? <img src={check} /> : <img src={nocheck} />}
        </div>
      ),
    },
    // {
    //     title: 'refunded',
    //     dataIndex: 'refunded',
    //     render: (text) => <div  style={{maxWidth: '30px'}}> {text ? <img src={check} /> : <img src={nocheck } />}</div>,
    // },

    {
      title: `${t('keyword_step_back')} - ${t('keyword_step_forward')}`,
      dataIndex: 'id',
      render: (text, row) =>
        // <Popover content={content(row)} title="Title" trigger="click">
        //     <Button   >Click me</Button>
        // </Popover>
        row.status ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItem: 'center',
            }}
          >
          
            <div>

              <Button
                onClick={() => {
                  !row.contract_sent ?
                    deleteButtonPushed(text)
                    :
                    updateTransaction(text, row.status ? 'status' : 'contract_sent', false, row.user_id)

                }}
                type="primary"
                danger
                style={{
                  marginTop: '3px',
                }}
              >
                {t('keyword_step_back')}
              </Button>
            </div>
            <div style={{ maxWidth: '30px', width:'100%' }}>
              {' '}
              <img src={check} />

            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItem: 'center',
            }}
          >

            <Button
              onClick={() => {
                updateTransaction(text, !row.contract_sent ? 'contract_sent' : 'status', true, row.user_id)

              }}
              style={{ background: '#5ba15d', color: 'white' }}
            >
              {t('keyword_step_forward')}
            </Button>


            <Button
              onClick={() => {
                !row.contract_sent ?
                  deleteButtonPushed(text)
                  :
                  updateTransaction(text, row.status ? 'status' : 'contract_sent', false, row.user_id)

              }}
              type="primary"
              danger
              style={{
                marginTop: '3px',
              }}
            >
              {t('keyword_step_back')}
            </Button>
          </div>
        ),
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={transactions}></Table>

      <ConfirmationPopup
        trigger={deleteTransaction}
        open={openDelete}
        openFunction={setOpenDelete}
        text={t('admin_delete_project_text_1')}
        id={idDeleteTransaction}
      />
    </>
  );
};

export default Transactions;
