import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Col, Row } from 'antd';
import { BiBuildings } from 'react-icons/bi';
import { useTranslation } from "react-i18next";
import officeBuildingIcon from '../../../images/officeBuildingIcon.png';
import increaseIcon from '../../../images/increaseIcon.png';
import moneyIcon from '../../../images/moneyIcon.png';

import './CreatorInvestor.css';

const CreatorInvestors = () => {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();
  return (
    <Row
      justify="space-around"
      style={{
        maxWidth: '1500px',
        margin: 'auto',
        backgroundColor: '#FFF',
        padding: '15px',
      }}
    >
      {/* <Col xs={23} lg={11}>
        <Card
          hoverable
          style={{
            backgroundColor: "#003464",
            backgroundSize: "100vw 100vh",
            textAlign: "left",
            color: "#FFF",
            height: "300px",
            marginBottom: "5px",
            borderRadius: "10px",
          }}
          onClick={() => navigate("/manual")}
        >
          <Row>
            <div style={{ height: "100%", width: "100%" }}>
              <Col xs={24}>
                <h2 style={{ color: "#FFF", fontWeight: "bold" }}>
                  {t('home_page_creator_investor_creator_title')}
                </h2>
              </Col>
              <Col xs={24} style={{ fontSize: "1.2em", paddingLeft: "5%" }}>
                <p>{t('home_page_creator_investor_creator_text_1')}</p>
                <p>
                {t('home_page_creator_investor_creator_text_2')}
                </p>
                <p>{t('home_page_creator_investor_creator_text_3')}</p>
              </Col>
              <Col
                xs={24}
                style={{
                  fontSize: "0.8em",
                  fontStyle: "italic",
                  textAlign: "right",
                }}
              >
                {t('home_page_creator_investor_click_button')}
              </Col>
            </div>
          </Row>
        </Card>
      </Col> */}
      <Col xs={24}>
        <Card
          hoverable
          style={{
            backgroundColor: '#003464',
            backgroundSize: '100vw 100vh',
            textAlign: 'left',
            color: '#FFF',
            marginBottom: '5px',
            borderRadius: '10px',
            maxWidth: '1500px',
            margin: 'auto',
          }}
          onClick={() => navigate('/manual')}
        >
          <Row style={{ height: '100%' }} gutter={[16, 24]}>
            <Col xs={24}>
              <h2
                style={{ fontWeight: 'bold', color: '#FFF', fontSize: '2.5em' }}
              >
                {t('home_page_creator_investor_investor_title')}
              </h2>
            </Col>
            <Col
              xs={24}
              lg={12}
              style={{ fontSize: '1.6em', paddingLeft: '5%' }}
            >
              <p>{t('home_page_creator_investor_investor_text_1')}</p>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  style={{ width: '100px' }}
                  src={officeBuildingIcon}
                  alt=""
                />
              </div>
            </Col>
            <Col
              xs={24}
              lg={12}
              style={{ fontSize: '1.6em', paddingLeft: '5%' }}
            >
              <p>{t('home_page_creator_investor_investor_text_2')}</p>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  style={{ width: '100px' }}
                  src={increaseIcon}
                  alt=""
                />
              </div>
            </Col>
            <Col
              xs={24}
              lg={12}
              style={{ fontSize: '1.6em', paddingLeft: '5%' }}
            >
              <p>{t('home_page_creator_investor_investor_text_3')}</p>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  style={{ width: '100px' }}
                  src={moneyIcon}
                  alt=""
                />
              </div>
            </Col>
            <Col
              xs={24}
              style={{
                fontSize: '0.8em',
                fontStyle: 'italic',
                textAlign: 'right',
              }}
            >
              {t('home_page_creator_investor_click_button')}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default CreatorInvestors;
