import Dashboard from "../views/logIn/Dashboard/Dashboard";
//import t from "../components/Fixtures/allTexts";
import Market from "../views/logIn/Market/Market";
import History from "../views/logIn/History/History";
import Transactions from "../views/logIn/Transactions/Transactions";
import AdminPage from "../views/logIn/AdminPage/AdminPage";
import NewProject from "../views/logIn/NewProject/NewProject";
import i18n from '../i18next';


import Settings from "../views/logIn/Settings/Settings";
import {
    CreditCardTwoTone, ProjectTwoTone, QuestionCircleTwoTone,
    ShopTwoTone, PieChartTwoTone, SettingTwoTone, HomeTwoTone,
    StopTwoTone, CrownTwoTone, PlusSquareTwoTone
} from '@ant-design/icons';







const routes =

 [

  
        {
            logo: <HomeTwoTone />,
            navName: i18n.t('keyword_dashboard'),
            url: '/app/dashboard',
            component: Dashboard,
            key: '1',
            hidden: false,
            allowedRoles:['investor', 'creator', 'admin', 'super_admin'],
    
        },
        {
            logo: <ShopTwoTone />,
            navName: i18n.t('keyword_market'),
            url: '/app/market',
            component: Market,
            key: '2',  
             hidden: false,
            allowedRoles:['investor', 'creator', 'admin', 'super_admin'],
        },
        {
            logo: <PieChartTwoTone />,
            navName: i18n.t('navbar_my_investments'),
            url: '/app/history',
            component: History,
            key: '3',
            hidden: true,
            allowedRoles:['investor', 'creator', 'admin', 'super_admin'],
        },
        {
            logo: <CreditCardTwoTone />,
            navName: i18n.t('keyword_transactions'),
            url: '/app/transactions',
            component: Transactions,
            hidden: true,
            allowedRoles:['investor', 'creator', 'admin', 'super_admin'],
            key: '4'
        },
        // {
        //     logo: <ProjectTwoTone />,
        //     name: 'Yearly report',
        //     url: '/app/Report',
        //     key: '5',
    
        // },
        {
            logo: <SettingTwoTone />,
            navName: i18n.t('keyword_settings'),
            url: '/app/settings',
            hidden: false,
            allowedRoles:['investor', 'creator', 'admin', 'super_admin'],
            component: Settings,
            key: '6'
        },
        {
            logo: <CrownTwoTone />,
            navName: i18n.t('keyword_admin'),
            url: '/app/admin',
            hidden: false,
            allowedRoles:['admin', 'super_admin'],
            component: AdminPage,
            key: '7'
        },
        {
            logo: <PlusSquareTwoTone />,
            navName: i18n.t('keyword_create_project') ,
            url: '/app/newProject',
            hidden: false,
            allowedRoles:['admin','super_admin'],
            component: NewProject,
            key: '8'
        },
    
    
    
    ]






export default routes;