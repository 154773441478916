
import { useState } from "react";
import t from "../../../components/Fixtures/allTexts";
import { Button, Result, Row, Col } from 'antd';
import { FrownOutlined } from '@ant-design/icons';


const Error = (props) => {


    function defaultMessage(status) {

        switch (status) { //TOTRANSLATE
            case '404':
                return "Sorry, the page you visited does not exist.";
            case '500':
                return 'Sorry, something went wrong.';
            case '403':
                return 'Sorry, you are not authorized to access this page.';
            default:
                return 'Sorry, something went wrong.';
        }

    }


    return (
        <Row justify='center' >
            <Col justify='center' xs={0} sm={24} md={24} lg={24}>

                <Result
                    status={props.status}
                    title={props.title}
                    subTitle={defaultMessage(props.status)}
                    extra={<Button type="primary" href='/Dashboard' >Back Home</Button>}
                />
            </Col>

            <Col xs={24} sm={0} md={0} lg={0}  >
                <Result
                    icon={<FrownOutlined />}
                    title={defaultMessage(props.status)}
                    extra={<Button type="primary" href='/Dashboard' >Back Home</Button>}
                />
                {/* <span>  {defaultMessage(props.status)} </span>
                <Button type="primary" href='/Dashboard' >Back Home</Button> */}
            </Col>

        </Row>


    );
};

export default Error;
