import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import moment from 'moment';
import axios from 'axios';
import {
  InputNumber,
  Modal,
  Rate,
  Space,
  Divider,
  Tooltip,
  Radio,
  Row,
  Col,
  Card,
  Image,
  Input,
  DatePicker,
  Switch,
  Button,
  notification,
} from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import NewProject_pictures from '../NewProject/NewProject_pictures';
import NewProject_files from '../NewProject/NewProject_files';

import notif from '../../../components/Notifications/Notification';
import printPrice from '../../../Tools/printPrice';



const { TextArea } = Input;
const { Meta } = Card;
const { RangePicker } = DatePicker;

const PopupProject = (props) => {
  const { t, i18n } = useTranslation();

  const dateFormat = 'YYYY-MM-DD';
  const [newProject, setNewProject] = useState({});
  const [newPictures, setNewPictures] = useState([]);
  const [newFiles, setNewFiles] = useState([]);

  const [currentStep, setCurrentStep] = useState('1');


  const onChangeCurrentStep = (step) => {

    setCurrentStep(step.target.value);

  }

  function deletePicture(url, index) {
    axios
      .post(
        'api/admin/deleteprojectPicture',
        { url: url },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
          },
        }
      )
      .then((res) => {

        notif('success', t('keyword_deleted_successfully'), res.data.message);

        let temp = { ...newProject };
        let newPics = temp.url;
        newPics.splice(index, 1);
        temp['url'] = newPics;
        setNewProject(temp);
      })
      .catch((err) => {
        console.error(err);
        notif('error', t('keyword_problem'), err.response.data.message);

      });


  }

  function callCorrectUpdate() {


    if (currentStep === '1') {
      EditProject();
      return false;
    } else if (currentStep === '2') {
      EditPictures();
      return false;
    } else if (currentStep === '3') {
      EditFiles();
      return false;
    }
    else {

      alert(t('keyword_problem'));
    }

  }



  useEffect(() => {
    console.log(props.chosenProject);
    setNewProject(props.chosenProject);
  }, [props.chosenProject]);

  function EditProject() {
    axios
      .put(
        'api/admin/updateProject',
        { newProject: newProject },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
          },
        }
      )
      .then((res) => {
        notif('success', 'Success', res.data.message);

        props.setOpenProjectEdit(false);
        props.setChosenProject({});
        props.getProjects();
      })
      .catch((err) => {
        console.error(err);
        notif('error', t('keyword_problem'), err.response.data.message);

      });
  }


  function EditPictures() {

    axios.post(`api/admin/updateProjectPictures`, {
      medias: newPictures, projectId: newProject.id
    }, { headers: { authorization: `Bearer ${localStorage.getItem('bearer_token')}` } })
      .then(res => {

        props.setOpenProjectEdit(false);
        props.setChosenProject({});
        props.getProjects();
        setNewPictures([]);
        setCurrentStep('1');
        notif('success', 'Great', res.data.message);

      }).catch(err => {

        console.error(err.response.data.message);
        notification('error', t('keyword_problem') , err.response.data);


      }
      );


  }
  function EditFiles() {

    axios.post(`api/admin/updateProjectFiles`, {
      fileList: newFiles, projectId: newProject.id
    }, { headers: { authorization: `Bearer ${localStorage.getItem('bearer_token')}` } })
      .then(res => {

        props.setOpenProjectEdit(false);
        props.setChosenProject({});
        props.getProjects();
        setNewFiles([]);
        setCurrentStep('1');

        notif('success', 'Great', res.data.message);

      }).catch(err => {

        console.error(err.response.data.message);
        notification('error', t('keyword_problem'), err.response.data);


      }
      );
  }

  const updown =(key, e) =>{
    console.log(e)
    let newValue;
  if(e<newProject[key]){
    newValue= e-10000;
  }else{
    newValue= e+10000;
  }



onChange(key, newValue);
}

  const onChange = (key, value) => {
    if (key === 'closing_date') {
      setNewProject((prevState) => ({
        ...prevState,
        start_date: moment(value[0]._d),
        closing_date: moment(value[1]._d),
      }));
      return null;
    }
    if (key === 'due_date') {
      setNewProject((prevState) => ({
        ...prevState,
        due_date: moment(value._d),
      }));
      return null;
    }
    if (key === 'target' || key === 'minimum_amount' ) {
 
      setNewProject(params => ({
          ...params,             // Reuse the previous properties
          [key]: Math.round(value/10000)*10000, // Overwrite the new ones
      }));
      return null;
  }

    setNewProject((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <>
      <Modal
        title={
          <Row gutter={[24, 30]}>
            <Col xs={24} sm={10} >
              <div>{`${props.chosenProject.title} - ${t('keyword_edit')}`} </div>

            </Col>
            <Col xs={24} sm={14}>
              <Radio.Group defaultValue="1" value={currentStep} onChange={onChangeCurrentStep} buttonStyle="solid">
                <Radio.Button value="1">{t('settings_tab_1')}</Radio.Button>
                <Radio.Button value="2">{t('create_project_pictures_tab')}</Radio.Button>
                <Radio.Button value="3">{t('create_project_files_tab')}</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        }
        centered
        open={props.openProjectEdit}
        onOk={() => callCorrectUpdate()}
        okText={t('keyword_submit')}
        onCancel={() => props.setOpenProjectEdit(false)}
        cancelText={t('keyword_cancel')}
        width={1000}
      >
        {currentStep === '1' ?
          <>
            <Row xs={24} justify="center">
              <Space size="middle">
                <span>{t('new_project_main_ispublic_1') } </span>
                <Switch
                  checkedChildren={t('keyword_public')}
                  unCheckedChildren={t('keyword_private')}
                  checked={newProject.is_public}
                  onChange={(e) => {
                    onChange('is_public', e);
                  }}
                />
              </Space>

              {/* <Button
          onClick={() => {
            props.handleDeleteProject(props.idDeleteProject, false);
          }}
          type="primary"
          danger
          style={{
            marginTop: '3px',
          }}
        >
          Delete
        </Button> */}
            </Row>
            <Row xs={24} gutter={16}>
              <Col xs={24}>
                <Row>
                  <Col xs={24}>
                    <span>{t('keyword_title')}</span>
                    <Input
                      value={newProject.title}
                      onChange={({ target: { value } }) => {
                        onChange('title', value);
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <span>{t('keyword_description') }</span>
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 10 }}
                      value={newProject.description}
                      onChange={({ target: { value } }) => {
                        onChange('description', value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <span>{t('keyword_creation_date') }</span>
                <Input
                  value={
                    newProject.creation_date &&
                    newProject.creation_date.split('T')[0]
                  }
                  disabled
                />
              </Col>
              <Col xs={24} sm={12}>
                <Row>
                  <Col xs={24}>
                    <span>{t('keyword_due_date')}</span>
                  </Col>
                  <Col xs={24}>
                    <DatePicker
                      style={{ width: '100%' }}
                      format={dateFormat}
                      defaultValue={
                        newProject.due_date
                          ? moment(
                            new Date(newProject.due_date)
                              .toISOString()
                              .split('T')[0],
                            dateFormat
                          )
                          : moment(new Date())
                      }
                      onChange={(e) => {
                        onChange('due_date', e);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <span>{t('new_project_main_start_end_title_1') }</span>
                <RangePicker
                  style={{ width: '100%' }}
                  value={[
                    newProject.start_date &&
                    moment(
                      new Date(newProject.start_date)
                        .toISOString()
                        .split('T')[0] || '',
                      dateFormat
                    ),
                    newProject.closing_date &&
                    moment(
                      new Date(newProject.closing_date)
                        .toISOString()
                        .split('T')[0] || '',
                      dateFormat
                    ),
                  ]}
                  format={dateFormat}
                  onChange={(e) => {
                    onChange('closing_date', e);
                  }}
                />
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col xs={24} sm={8}>
                <Row>
                  <span>{t('keyword_annual_return')}</span>
                </Row>
                <Row justify="space-between" gutter={16}>
                  <Col xs={18}>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={newProject.annual_rate || 0}
                      placeholder="10000"
                      min={1}
                      max={100}
                      onChange={(e) => {
                        onChange('annual_rate', e);
                      }}
                      addonAfter='%'




                    />
                  </Col>
                
                </Row>
              </Col>
              <Col xs={24} sm={8}>
                <Row>
                  <span>{t('keyword_total')}</span>
                </Row>
                <Row justify="space-between" gutter={16}>
                  <Col xs={18}>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={newProject.target || 0}
                      placeholder="10000"
                      min={10000}
                      onChange={(e) => {
                        onChange('target', e);
                      }}
                      addonAfter='kč'
                      formatter={(value) => `${ printPrice(value)}`}
                     parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                     onStep={(e)=>{updown('target', e)}}
                      
                    />
                  </Col>
                
                </Row>
              </Col>
              <Col xs={24} sm={8}>
                <Row>
                  <span> {t('keyword_minimum_amount')} </span>
                </Row>
                <Row justify="space-between" gutter={16}>
                  <Col xs={18}>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={newProject.minimum_amount || 0}
                      placeholder="10000"
                      min={10000}
                      max={newProject.target}
                      onChange={(e) => {
                        onChange('minimum_amount', e);
                      }}
                      addonAfter='kč'
                      formatter={(value) => `${ printPrice(value)}`}
                     parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                     onStep={(e)=>{updown('minimum_amount', e)}}
                    />
                  </Col>
                
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row xs={24} gutter={[48, 48]}>
              <Col xs={24} sm={8} lg={8}>
                <span>{t('keyword_active')} ? </span>
                <Switch
                  checkedChildren={t('keyword_yes')}
                  unCheckedChildren={t('keyword_no')}
                  checked={newProject.active && newProject.active}
                  onChange={(e) => {
                    onChange('active', e);
                  }}
                />
              </Col>

              <Col xs={24} sm={8} lg={8}>
                <span>Rating </span>
                <Rate
                  allowClear={false}
                  value={newProject.partner_id}
                  onChange={(e) => {
                    onChange('partner_id', e);
                  }}
                />
              </Col>
            </Row>
          </>
          :
          currentStep === '2' ?
            <>
              <div><Divider> <h2> {t('keyword_current')} {t('create_project_pictures_tab')} <ArrowDownOutlined /></h2></Divider> </div>

              <Row xs={24}>


                {newProject.url && newProject.url.map((item, index) =>


                  <Col xs={24} sm={12} md={8} >
                    <Card
                      hoverable
                      style={{ width: 250 }}
                      cover={
                        <div style={{ overflow: "hidden", width: '250px', height: '150px' }}>
                          <Image
                            alt="example"

                            src={item}
                          />
                        </div>
                      }
                    >
                      <Meta title={<div ><Button danger onClick={() => { deletePicture(item, index) }} >Delete</Button></div>} />

                    </Card>


                  </Col>



                )}


              </Row>
              <Divider><h2>{t('keyword_add')} {t('create_project_pictures_tab')} <ArrowDownOutlined /> </h2></Divider>

              <NewProject_pictures mediaList={newPictures} setMediaList={setNewPictures} />



            </>
            :

            <>
              <div><Divider> <h2>{t('keyword_current')} {t('create_project_files_tab')} <ArrowDownOutlined /></h2></Divider> </div>

              <Row gutter={[48, 24]} xs={24}>


                {newProject.filelist && newProject.filelist.map((item, index) =>

                  <>
                    <Col xs={24} sm={12} md={8} >

                      <a href={item && item.replace('/upload/', '/upload/fl_attachment/')} >{newProject.names[index]}</a>

                    </Col>
                    <Col xs={24} sm={24} md={8} >
                      <div>{t('keyword_title')} </div>
                      <Tooltip title={t('keyword_title')} trigger={['focus']}  >
                        <Input style={{width:'100%'}} disabled value={newProject.titles[index] || ''} />
                      </Tooltip>


                    </Col>
                    <Col xs={24} sm={24} md={8} >

                      <div>{t('keyword_description')} </div>
                      <Tooltip title={t('keyword_description')} trigger={['focus']} placement="topLeft" overlayClassName="numeric-input">
                        <TextArea style={{width:'100%'}}  autoSize disabled value={newProject.descriptions[index] || ''} />
                      </Tooltip>
                    </Col>

                  </>

                )}


              </Row>
              <Divider><h2>{t('keyword_add')} {t('create_project_files_tab')} <ArrowDownOutlined /> </h2></Divider>

              <NewProject_files fileList={newFiles} setFileList={setNewFiles} />



            </>


        }

      </Modal>
    </>





  );
};

export default PopupProject;
