
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from 'axios'
import { Link } from 'react-router-dom';
import Fixtures from "../../../components/Fixtures/ProjectsFixtures";

import CardProject from '../../../components/Cards/CardProject';
import {
    Row,
    Col,
    Tabs,
    Empty,
    Divider,
    Modal,



} from "antd";
import { EuroOutlined } from '@ant-design/icons';
import {
    AppstoreOutlined, BarsOutlined
} from "@ant-design/icons";

import Users from "./Users";
import Projects from "./Projects";
import Transactions from "./Transactions";




const AdminPage = (props) => {
    const { t, i18n } = useTranslation();


    const [isList, setIsList] = useState(false);
    const [projects, setProjects] = useState([]);

    // const test = (tes) => {
    // }
const array = [t('keyword_users'),t('keyword_projects'), t('keyword_transactions')]
    function showComponent(key) {


        switch (key) {

            case array[0]:
                return <Users />
            case array[1]:
                return <Projects />
            case array[2]:
                return <Transactions
                 />

        }

        return <span> {t('keyword_not_found') } </span>

    }


    return (
        <div className="admin-page" >

            <Tabs
                defaultActiveKey="1"
                centered

                // onChange={test}
                items={array.map((_, i) => {
                    const id = String(i + 1);
                    return {
                        label: _,
                        key: _,
                        children: showComponent(_),
                    };
                })}
            />


        </div>
    );
};

export default AdminPage;