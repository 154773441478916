import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import styled from 'styled-components';

import './newProject.css';
import {
    Row,
    Col,
    Input,
    InputNumber,
    Button,
    DatePicker, TimePicker,
    Card,
    Steps,
    Tooltip,
    Upload,
    Form,
    Switch,
    Divider,
    Rate,

} from "antd";


import {
    InboxOutlined, PlusOutlined
} from "@ant-design/icons";
import DragUpload from '../../../components/CRUD_project/DragUpload';
import printPrice from "../../../Tools/printPrice";

const StyledCard = styled(Card)`
  ::global .ant-card: {
    border-radius: 15px!important;
  }
  `;

const styleBorder = {
    borderRadius: '15px',
}


const { MonthPicker, RangePicker } = DatePicker;


const { Dragger } = Upload;
const { Step } = Steps;
const { TextArea, } = Input;





const NewProject_mainInfos = (props) => {
    const { t, i18n } = useTranslation();

    const dateFormat = 'YYYY-MM-DD';
    var form = props.form;


    const updown =(key, e) =>{
        console.log(e)
        let newValue;
      if(e<form[key]){
        newValue= e-10000;
      }else{
        newValue= e+10000;
      }
    
    
    console.log(newValue);
    handleChangeForm(key, newValue);
    }

    const handleChangeForm = (key, e) => {
    console.log(key, e);



        let date_array = ['closing_date', 'due_date'];
        if (date_array.includes(key)) {
            if (key === 'closing_date') {
                props.setForm(params => ({
                    ...params,             // Reuse the previous properties
                    start_date: moment(e[0]._d),
                    closing_date: moment(e[1]._d) // Overwrite the new ones
                }));
            } else if (key === 'due_date') {
                props.setForm(params => ({
                    ...params,             // Reuse the previous properties
                    due_date: moment(e._d),
                }));
            }
            return null;
        }

        var temp = ['annual_rate', 'target', 'minimum_amount', 'partner_id', 'is_public'].includes(key) ? e : e.target.value;
      
        if (key === 'target' || key === 'minimum_amount' ) {

            if(e.toString().includes('.')){

                e=e*10000;
            }
            
            props.setForm(params => ({
                ...params,             // Reuse the previous properties
                [key]: Math.round(temp/10000)*10000, // Overwrite the new ones
            }));
            return false;
        }


        props.setForm(params => ({
            ...params,             // Reuse the previous properties
            [key]: temp, // Overwrite the new ones
        }));
    };



    // useEffect(() => {

    // }, []);



    return (

        <Row xs={24} gutter={[24, 24]} >

            <Col xs={24} lg={12} >
                <StyledCard style={styleBorder} >


                    <Form initialValues={{
                        title: form.title,
                        due_date: form['due_date'],
                        annual_rate: form.annual_rate,
                        target: form.target,
                        location: form.location,
                        minimum_amount: form.minimum_amount || 10000,
                        title: form.title,

                    }}>

                        <Form.Item
                            label={t('keyword_title') }
                            value={form.title || ''}
                            name='title'
                            autoSize
                            onChange={(e) => { handleChangeForm('title', e) }}
                        >
                            <TextArea autoSize style={{ borderRadius: '10px' }}
                                maxLength={150} value={form.title || ''} placeholder={t('keyword_title')} />
                        </Form.Item>

                        <Form.Item
                            label={t('keyword_city')}
                            value={form.location || ''}
                            name='location'
                            onChange={(e) => { handleChangeForm('location', e) }}
                        >
                            <Input style={{ borderRadius: '10px' }} value={form.location || ''} placeholder={t('keyword_city')} />
                        </Form.Item>



                        <Form.Item

                            name='start_close'
                        >
                            <div>{t('new_project_main_start_end_title_1')}</div>
                            <RangePicker value={
                                [
                                    form.start_date && moment(new Date(form.start_date._d).toISOString().split('T')[0] || '', dateFormat),
                                    form.closing_date && moment(new Date(form.closing_date._d).toISOString().split('T')[0] || '', dateFormat),
                                ]
                            } format={dateFormat} onChange={(e) => { handleChangeForm('closing_date', e) }} />
                            <p style={{ fontSize: '12px', borderRadius: '10px' }} >{t('new_project_main_start_end_subtitle_1')}</p>

                        </Form.Item>



                        <Row>

                        <Col xs={12} md={12} lg={8} >
                                <Form.Item

                                    name={t('keyword_annual_return')}
                                >
                                    <div>
                                        {t('keyword_annual_return')}
                                    </div>
                                    <Tooltip trigger={['focus']} placement="topLeft" overlayClassName="numeric-input">
                                        <InputNumber style={{ width: '80%', borderRadius: '10px' }} value={form.annual_rate || 0} placeholder={t('keyword_annual_return')} size={'large'} min={1} max={100}
                                            onChange={(e) => { handleChangeForm('annual_rate', e) }} addonAfter='%' />


                                    </Tooltip>

                                </Form.Item>
                            </Col>
                            <Col xs={12} md={12} lg={8} >
                                <Form.Item
                                    /* label="Total expected" */
                                    name='target'
                                >
                                    <div>
                                        {t('keyword_total')}
                                    </div>
                                    <Tooltip trigger={['focus']} placement="topLeft" overlayClassName="numeric-input">
                                        <InputNumber style={{ width: '80%', borderRadius: '10px' }} value={form.target || 0} placeholder="Target" size={'large'} min={10000}
                                            onChange={(e) => { handleChangeForm('target',parseInt(e.toString().replace('.', ''))) }} 
                                            addonAfter='kč'
                                             formatter={(value) => `${printPrice(value)}`}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            onStep={(e)=>{updown('target', e)}}

                                            />
                                     
                                    </Tooltip>
                                </Form.Item>


                            </Col>
                            <Col xs={12} md={12} lg={8} >
                                <Form.Item

                                    name={t('keyword_minimum_amount')}
                                >
                                    <div>{t('keyword_minimum_amount')}</div>
                                    <Tooltip trigger={['focus']} placement="topLeft" overlayClassName="numeric-input">
                                        <InputNumber style={{ width: '80%', borderRadius: '10px' }} value={form.minimum_amount || 0} 
                                        placeholder="10.000" size={'large'} max={form.target || 0}
                                            onChange={(e) => { handleChangeForm('minimum_amount', parseInt(e.toString().split('.'))) }} addonAfter={'kč'}
                                            formatter={(value) => `${printPrice(value)}`}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            
                                            onStep={(e)=>{updown('minimum_amount', e)}} />
                                  
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        </Row>




                        <Row>
                            <Col xs={12} >
                                <Form.Item

                                    name='due_date'
                                >
                                    <div>
                                        {t('new_project_main_due_date_1')}
                                    </div>
                                    <DatePicker format={dateFormat} defaultValue={form.due_date && moment(new Date(form.due_date).toISOString().split('T')[0], dateFormat)}
                                      placeholder='HELLO'  onChange={(e) => { handleChangeForm('due_date', e) }} />

                                </Form.Item>
                            </Col>

                            <Col xs={12} >


                                <Form.Item

                                    name='partner_id'
                                >
                                    <div>
                                        {t('new_project_main_necessary_rank_1')}
                                    </div>

                                    <Rate allowClear={false} defaultValue={1} value={form.partner_id}
                                        onChange={(e) => { handleChangeForm('partner_id', e) }} />


                                </Form.Item>



                            </Col>
                            <Col xs={24} >


                                <Form.Item
                                    name='is_public'
                                >
                                    <div>
                                        {t('new_project_main_ispublic_1')}
                                    </div>

                                    <Switch checkedChildren={t('keyword_yes')} unCheckedChildren={t('keyword_no')}
                                     checked={form.is_public} onChange={(e) => { handleChangeForm('is_public', e) }} />


                                </Form.Item>



                            </Col>



                        </Row>



                    </Form>

                </StyledCard>
            </Col>

            <Col xs={24} lg={12}>

                <StyledCard style={styleBorder} >
                    <Form
                        initialValues={{
                            description: form.description
                        }}
                    >
                        <Form.Item
                            label={t('keyword_description')}
                            name='description'



                        >

                            <TextArea value={form.description || ''}
                                style={{ borderRadius: '10px' }}
                                onChange={(e) => { handleChangeForm('description', e) }}
                                placeholder={t('keyword_description')}
                                autoSize />
                            <div

                            />
                        </Form.Item>



                    </Form>


                </StyledCard>
            </Col>


        </Row >

    );
};

export default NewProject_mainInfos;