import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import './auth.css';
import axios from 'axios';
import { Result, Card, Row, Col, Button, Checkbox, Form, Input } from 'antd';
import { FrownOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import losanges from '../../../images/losanges.jpeg';
import house from '../../../images/house.webp';
import pfg from '../../../images/pfg-logo-blue.png';
import notification from '../../../components/Notifications/Notification';

const inpuStyle = {
  height: '60px', borderRadius: '10px'
}


const Login = (props) => {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();
  const [passwordForgot, setPasswordForgot] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [disableSendMail, setDisableSendMail] = useState(false);

  const onFinish = (values) => {

    if (values.remember) {
      localStorage.setItem('remember_email', values.email);
    } else {
      localStorage.removeItem('remember_email');
    }


    setButtonPressed(true);
    axios.post(`api/auth/login`, { login_infos: values })
      .then((res) => {


        localStorage.setItem('bearer_token', res.data.accessToken);
        location.href = '/app/dashboard';

      })
      .catch((err) => {
        setButtonPressed(false);
        console.log(err.response.data.message);

        notification('error', 'Login error', err.response.data.message);
      });
  };

  const sendEmailPassword = (values) => {

    axios
      .post(`api/auth/forgot-password`, { email: values.email })
      .then((res) => {

      
        setDisableSendMail(true);
        notification('success', 'Great !', res.data.message);

      })
      .catch((err) => {
        console.log(err.response.data.message);
        notification('error', t('keyword_problem'), err.response.data.message);
      });
  };




  useEffect(() => {
    let myToken = localStorage.getItem('bearer_token');
    axios
      .post(
        'app/verify',
        {}, //TODO: check if i cant just say -> api/auth/verify
        { headers: { authorization: `Bearer ${myToken}` } }
      )
      .then((response) => {
        if (response.data.authed === true) {
          location.href = '/app/dashboard';
          // navigate("/app/dashboard", { replace: true }) TODO: -> would be better but impossible for now
        } else {
          
          notification('warning', t('keyboard_welcome'), t('login_welcome_message_1'));

        }
      })
      .catch((err) => {
        console.log(err);
        notification('error', t('keyword_problem') , err);

      });
  }, []);

  return (
    <div className="losanges1">
      <Row className="losanges" md={24} xs={24} justify="center" align="center">
        <Col
          xs={10}
          style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Row align="center" justify="center">
            <img style={{ width: '130px' }} src={pfg} />
          </Row>

          <Row align="center" justify="center">
            <h2 style={{ color: props.colors.primary }}> {t('keyword_sign_in')} </h2>
          </Row>

          <Row align="center" justify="center" xs={24}>
            {!passwordForgot ?
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                  email:
                    localStorage.getItem('remember_email') !== null
                      ? localStorage.getItem('remember_email')
                      : '',
                }}
                onFinish={onFinish}
                style={{ width: '400px' }}
              // form={form}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: t('login_verification_email_1'),
                    },
                  ]}
                >
                  <Input
                    style={inpuStyle}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t('keyword_email')}
                  />
                </Form.Item>
                <Form.Item
                  className="dashboardSearch"
                  name="password"
                  style={{ borderRadius: '15px' }}
                  rules={[

                    { required: true, min: 8, message: t('login_verification_password_1') },
                  ]}
                >
                  <Input
                    style={inpuStyle}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t('keyword_password')}
                  />
                </Form.Item>
                <Row>
                  <Col xs={24} sm={12}>

                    <Form.Item
                      name="remember"
                      valuePropName="checked"

                    >
                      <Checkbox>{t('login_remember_me_1')}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>

                    <Form.Item
                      name="forgot"


                    >
                      <div >
                        <Button className="login-form-forgot" onClick={() => { setPasswordForgot(true) }} >
                          {t('login_forgot_password_1')}
                        </Button>
                      </div>
                    </Form.Item>

                  </Col>
                </Row>

                <Form.Item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={buttonPressed}
                    style={{
                      borderRadius: '10px',
                      background: '#003464',
                      color: 'white',
                      border: '#003464',
                      height: '50px',
                      width: '300px',
                    }}
                  >
                    {t('keyword_login')}
                  </Button>
                  <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    {t('keyword_or')} <a href="/register">{t('login_register_now_1')}</a>
                  </div>
                </Form.Item>
              </Form>


              :

              <Form
                name="normal_forgotPassword"
                className="forgotPassword-form"
                initialValues={{

                  email:
                    localStorage.getItem('remember_email') !== null
                      ? localStorage.getItem('remember_email')
                      : '',
                }}
                onFinish={sendEmailPassword}
                style={{ width: '400px' }}
              // form={form}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: t('login_verification_email_1'),
                    },
                  ]}
                >
                  <Input
                    style={{ height: '60px', borderRadius: '10px' }}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t('keyword_email')}
                  />
                </Form.Item>

                <Form.Item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={disableSendMail}
                    className="forgot_password-form-button"
                    style={{
                      borderRadius: '10px',
                      background: '#003464',
                      color: 'white',
                      border: '#003464',
                      height: '50px',
                      width: '300px',
                    }}
                  >
                    {t('reset_password_send_email')}
                  </Button>

                </Form.Item>
              </Form>
            }

          </Row>
        </Col>

        <Col
          md={14}
          xs={0}
          className="losanges2"
          style={{
            backgroundImage: `url('${house}')`,
            backgroundRepeat: 'no-repeat',
          }}
        ></Col>
      </Row>
    </div>
  );
};

export default Login;
