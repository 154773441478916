
import {notification } from "antd";



const Notification = (type, title, msg) => {
    notification[type]({
      message: title,
      description:
        msg,
    });
  };



  export default Notification