import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import partner1 from '../../images/partner1.svg';
import ImageNotFound from '../../images/ImageNotFound.png';
import './cards.css';
import {
  Row,
  Col,
  Tooltip,
  Card,
  Avatar,
  Progress,
  Button,
  Carousel,
  Image,
  Rate,
} from 'antd';

import { EyeTwoTone, BellOutlined, SettingOutlined } from '@ant-design/icons';
const { Meta } = Card;

const contentStyle = {
  height: '100px',
  Width: '300px',
  objectFit: 'fill',
};
const carouselStyle = {
  overflow: 'hidden',
  height: '200px',
  background: 'teal',
};

const CardProject = (props) => {
  const { t, i18n } = useTranslation();


  function renderPartner(partner_id) {
    if (partner_id === 1) {
      return partner1;
    }
    return '';
  }

  return (
    <div>
      {props.edit && (
        <Row align="center">
          <div className="card-project__edit">
            <Button
              onClick={() => {
                location.href = `newProject?id=${props.infos.id}`;
              }}
            >
              {t('keyword_edit')}
            </Button>
          </div>
        </Row>
      )}
      <Card
        className="full-card"
        hoverable
        onClick={() => {
          window.location.href = 'project?id=' + props.infos.id;
        }}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
        cover={
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="card-project__image col-md-12">
                  <div className="card-project__rate">
                    <Rate
                      disabled
                      value={props.infos.partner_id}
                      style={{ fontSize: 15 }}
                    />
                  </div>
                  <div className="card-project__city">{props.infos.city}</div>
                  <div style={{ height: '200px', overflow: 'hidden' }}>
                    <Carousel autoplaySpeed={5000} autoplay>
                      {!props.infos.url ? (
                        <Image  preview={false} src={ImageNotFound} />
                      ) : (
                        props.infos.url.map((image) => (
                          <div>
                            <Image
                              autoplay
                              preview={false}
                              fluid='true'
                              src={image}
                              style={{ width: '100%', marginTop: '0px' }}
                            ></Image>
                          </div>
                        ))
                      )}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>

            <Progress
              percent={Math.floor(props.infos.total_promised * 100 / props.infos.target)}
              success={{
                percent: ( (props.infos.total_paid * 100) /
                  props.infos.target
                ).toFixed(0),
              }}
              type="line"
              
              className="project-progress"
            />
          </>
        }
        actions={[
          <Tooltip title={t('keyword_annual_return')}>
            <span>{t('keyword_annual_return')}</span>
            <h5 key="5678">{props.infos.annual_rate} %</h5>
          </Tooltip>,
          <Tooltip title={t('keyword_due_date')}>
            <span>{t("keyword_due_date")}</span>
            <h5 key="1345">{props.infos.due_date.split('T')[0]}</h5>
          </Tooltip>,
          <Tooltip title={t('keyword_investors')}>
            <span>{t('keyword_investors')} </span>
            <h5 key="54332">{props.infos.investors}</h5>
          </Tooltip>,
        ]}
      >
        <Meta
          title={
            <Tooltip title={props.infos.title}>
              <h5
                key="card_project_title"
                style={{ fontSize: '1.3em', fontWeight: 'bold' }}
              >
                {props.infos.title}
              </h5>
            </Tooltip>
          }
          description={
            <Tooltip title={t('card_project_click_open_1')}>
              <h5 key="card_project_description" style={{ fontSize: '1em' }}>
                {props.infos.description}
              </h5>
            </Tooltip>
          }
        />
      </Card>
    </div>
  );
};

export default CardProject;
