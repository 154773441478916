import { useState, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import './newProject.css';
import {
    Row,
    Col,
    PageHeader,
    Button,
    Card,
    Steps,
    message,
    Upload,
    Divider,

} from "antd";
import NewProject_mainInfos from "./NewProject_mainInfos";
import NewProject_files from "./NewProject_files";
import NewProject_pictures from "./NewProject_pictures";
import NewProject_preview from "./NewProject_preview";
import {
    InboxOutlined, PlusOutlined
} from "@ant-design/icons";
import DragUpload from '../../../components/CRUD_project/DragUpload';





const { Dragger } = Upload;
const { Step } = Steps;



const NewProject = (props) => {
    const { t, i18n } = useTranslation();

    const [curentStep, setCurrentStep] = useState(0);
    const [form, setForm] = useState({is_public: true, partner_id: 1, target: 1000000, minimum_amount:10000, annual_rate:10 });
    const [medias, setMedias] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [freezeButtons, setFreezeButtons] = useState(false);

    const onChange = (value) => {

        setCurrentStep(value);
    };





    return (
        <div className='new-project' >

            <Row xs={24} align='center' >
                <Col xs={18}>
                    <Steps

                        type="navigation"
                        current={curentStep}
                        onChange={onChange}
                        className="site-navigation-steps">
                        <Step disabled={freezeButtons} status="wait" title={t('create_project_main_tab')} />
                        <Step disabled={freezeButtons} status="wait" title={t('create_project_pictures_tab')} />
                        <Step disabled={freezeButtons} status="wait" title={t('create_project_files_tab')} />
                        <Step disabled={freezeButtons} status="wait" title={t('create_project_preview_tab')} />
                    </Steps>


                </Col>
            </Row>
            <Divider />



            {
                curentStep === 0 ?
                    <NewProject_mainInfos form={form} setForm={setForm} />
                    :
                    curentStep === 1 ?
                        <NewProject_pictures mediaList={medias} setMediaList={setMedias} />
                        :
                        curentStep === 2 ?
                          <NewProject_files fileList={fileList} setFileList={setFileList}  />
                            :
                            curentStep === 3 ?
                                <NewProject_preview freezeButtons={freezeButtons} setFreezeButtons={setFreezeButtons}  fileList={fileList}  
                                colors={props.colors}   project={form} mediaList={medias} />

                                : null
            }




        </div>

    );
};

export default NewProject;