import React from 'react';

import { Col, Divider, Row } from 'antd';

import './CardStep.css';

const FullStepPage = (props) => {
  return (
    <Row justify="center" className="card-step">
      {props.position === 'left' ? (
        <Col xs={0} lg={6}>
          <Row
            style={{ height: '100%', textAlign: 'right', marginRight: '40px' }}
          >
            <Col xs={24} className="card-step__title">
              {props.index}. {props.title}
            </Col>
            <Col xs={24}>{props.description}</Col>
          </Row>
        </Col>
      ) : (
        <Col xs={0} lg={6}></Col>
      )}
      <Col xs={24} lg={4}>
        <div className="card-step__bubble">{props.icon}</div>
      </Col>
      {props.position === 'right' ? (
        <Col xs={0} lg={6}>
          <Row
            style={{ height: '100%', textAlign: 'left', marginLeft: '40px' }}
          >
            <Col xs={24} className="card-step__title">
              {props.index}. {props.title}
            </Col>
            <Col xs={24}>{props.description}</Col>
          </Row>
        </Col>
      ) : (
        <Col xs={0} lg={6}></Col>
      )}
      <Col xs={24} lg={0}>
        <Row style={{ height: '100%', textAlign: 'center' }}>
          <Col xs={24} className="card-step__title">
            {props.index}. {props.title}
          </Col>
          <Col xs={24}>{props.description}</Col>
        </Row>
      </Col>
      <Col xs={24} style={{ textAlign: 'center' }}>
        {props.line && (
          <div
            style={{
              display: 'inline-block',
              width: '5px',
              backgroundColor: '#000',
              height: '50px',
              marginTop: '15px',
              borderRadius: "10px"
            }}
          ></div>
        )}
      </Col>
    </Row>
  );
};

const Halfpage = (props) => {
  return (
    <Row justify="center" className="card-step">
       <Col xs={24} style={{ textAlign: 'center' }}>
        {props.upperLine && (
          <div
            style={{
              display: 'inline-block',
              width: '5px',
              backgroundColor: '#000',
              height: '50px',
              marginTop: '15px',
              borderRadius: "10px"
            }}
          ></div>
        )}
      </Col>
      {props.position === 'left' && (
        <Col xs={0} lg={10}>
          <Row
            style={{ height: '100%', textAlign: 'right', marginRight: '40px' }}
          >
            <Col xs={24} className="card-step__title">
              {props.index}. {props.title}
            </Col>
            <Col xs={24}>{props.description}</Col>
          </Row>
        </Col>
      )}
      <Col xs={24} lg={12}>
        <div className="card-step__bubble">{props.icon}</div>
      </Col>
      {props.position === 'right' && (
        <Col xs={0} lg={10}>
          <Row
            style={{ height: '100%', textAlign: 'left', marginLeft: '40px' }}
          >
            <Col xs={24} className="card-step__title">
              {props.index}. {props.title}
            </Col>
            <Col xs={24}>{props.description}</Col>
          </Row>
        </Col>
      )}
      <Col xs={24} lg={0}>
        <Row style={{ height: '100%', textAlign: 'center' }}>
          <Col xs={24} className="card-step__title">
            {props.index}. {props.title}
          </Col>
          <Col xs={24}>{props.description}</Col>
        </Row>
      </Col>
      <Col xs={24} style={{ textAlign: 'center' }}>
        {props.line && (
          <div
            style={{
              display: 'inline-block',
              width: '5px',
              backgroundColor: '#000',
              height: '50px',
              marginTop: '15px',
              borderRadius: "10px"
            }}
          ></div>
        )}
      </Col>
    </Row>
  );
};

const CardStep = (props) => {
  return (
    <>{props.fullPage === 'true' ? FullStepPage(props) : Halfpage(props)}</>
  );
};

export default CardStep;
