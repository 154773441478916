
import React,{ useState } from "react";
import {
    Row,
    Col,
    Card,
    Avatar,
    Radio,
    Progress,
    Tooltip,
    Divider,
    Segmented,
    Select,
    Typography,
    Empty,
    Table,
} from "antd";



const columns = [
    {
      title: "Project",
      render: (record) => (
        <React.Fragment>
          {record.subject}
        </React.Fragment>
      ),
      responsive: ["md"]
    },
    {
      title: "Date",
      dataIndex: "date",
    
      responsive: ["xs", "sm", "xl"]

    },
    {
      title: "Total paid",
      dataIndex: "subject",
      sorter: (a, b) => a.subject.length - b.subject.length,
      sortDirections: ["descend", "ascend"],
      responsive: ["sm"]
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.subject.length - b.subject.length,
      sortDirections: ["descend", "ascend"],
      responsive: ["lg"]
    }
  ];
  
  

const Transactions = (props) => {

    const data = [
        {
          key: "1",
          date: "aaa@example.com",
          subject: "[ HR-888 ] Notice of official announcement",
          status: (
            <>
            <span>Success : </span>
                    <Avatar style={{ backgroundColor: props.colors.success, verticalAlign: 'middle' }} size="small">
          </Avatar>
          </>
          )
        },
        {
          key: "2",
          date: "bbb.bbbb@example.com",
          subject: `[web:333] "Web Contact"`,
          status: (
            <>
            <span> Failed : </span>
                    <Avatar style={{ backgroundColor: props.colors.danger, verticalAlign: 'middle' }} size="small">
          </Avatar>
          </>
          )
        },
        {
          key: "2",
          date: "ccc.cccc@example.com",
          subject: `[web:333] "Web Contact"`,
          status: "0:20"
        },
        {
          key: "2",
          date: "dddd.dddd@example.com",
          subject: `[web:333] "Web Contact"`,
          status: "0:20"
        },
        {
          key: "2",
          date: "eeee.eeee@example.com",
          subject: `[web:333] "Web Contact"`,
          status: "0:20"
        },
        {
          key: "2",
          date: "fff.fffff@example.com",
          subject: `[web:333] "Web Contact"`,
          status: "0:20"
        },
     
      ];
      
    


    return (
        <div className="transactions-page" >
<Card  >

<h1 style={{color: props.colors.primary}} >All Wallet transactions</h1>

</Card>
<Divider/>
<Table columns={columns} dataSource={data} pagination={true}
      mobileBreakPoint={768}

/>,

        </div>
    );
};

export default Transactions;
