import { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import axios from 'axios';
import {
  Row,
  Col,
  Divider,
  Input,
  Table,
  Button,
  DatePicker,
  Dropdown,
  Rate,
  Steps,
  Popover,
  Upload,
  Select,
  Tag,
  Space,
  Modal,
} from 'antd';

import { SearchOutlined, CrownTwoTone } from '@ant-design/icons';
import DragUpload from '../../../components/CRUD_project/DragUpload';
import PopupUser from './PopupUser';

const { MonthPicker, RangePicker } = DatePicker;

const { Dragger } = Upload;
const { Step } = Steps;
const { TextArea } = Input;
const { Column, ColumnGroup } = Table;

const Users = (props) => {
  const { t, i18n } = useTranslation();

  const [users, setUsers] = useState([]);
  const [chosenUser, setChosenUser] = useState({});
  const [openUserEdit, setOpenUserEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const columns = [
    {
      title: t('keyword_firstname'),
      dataIndex: 'first_name',
      responsive: ['md'],
      //   render: (text) => <a>{text}</a>,
    },
    {
      title: t('keyword_lastname'),
      dataIndex: 'last_name',
      responsive: ['md'],
      ...getColumnSearchProps('last_name'),
    },
    {
      title: t('keyword_email'),
      dataIndex: 'email',
      //   render: (text) => <a>{text}</a>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      //   render: (text) => <a>{text}</a>,
      ...getColumnSearchProps('role'),
    },
    {
      title: t('keyword_active'),
      dataIndex: 'active',
      responsive: ['sm'],
      render: (text) => (
        <span>{text ? t('keyword_yes') : t('keyword_no')}</span>
      ),
    },
    {
      title: 'Rating',
      dataIndex: 'partner',
      responsive: ['xl'],
      render: (text) => <Rate disabled value={text} />,
    },
    {
      title: `${t('keyword_bank_account')}s`,
      dataIndex: 'bank_accounts',
      responsive: ['xl'],
      render: (tags) => (
        <>
          {tags &&
            tags.map((tag, index) => (
              <Tag color="blue" key={tag + index}>
                {tag}
              </Tag>
            ))}
        </>
      ),
    },

    {
      title: t('keyword_action'),
      dataIndex: 'id',
      render: (text, row) =>
        // <Popover content={content(row)} title="Title" trigger="click">
        //     <Button   >Click me</Button>
        // </Popover>
        row.role !== 'super_admin' ? (
          <Button
            onClick={() => {
              setChosenUser(row);
              setOpenUserEdit(true);
            }}
          >
            {t('keyword_view_edit')}
          </Button>
        ) : (
          <CrownTwoTone twoToneColor="#fbda15" style={{ fontSize: '150%' }} />
        ),
    },
  ];

  function getUsers() {
    axios
      .get('api/admin/getUsers', {
        headers: {
          authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
        },
      })
      .then((res) => {
        
        setUsers(res.data.users);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Table columns={columns} dataSource={users}></Table>

      <PopupUser
        getUsers={getUsers}
        openUserEdit={openUserEdit}
        chosenUser={chosenUser}
        setChosenUser={setChosenUser}
        setOpenUserEdit={setOpenUserEdit}
      />
    </>
  );
};

export default Users;
