import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Image, Card, Form, List, Row, Input, Typography, Divider, Col, Button, Upload } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ImageUploader from 'react-images-upload'

const { TextArea, } = Input;
const { Meta } = Card;
import notification from '../../../components/Notifications/Notification';

const NewProject_files = (props) => {
    const { t, i18n } = useTranslation();





    useEffect(() => {

    }, [props.fileList]);

    const fileBase64 = (img) => {
        let result = [...img];

        // setUrlImage(img);

        result && result.map(function (img) {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(img);
            fileReader.onloadend = async () => {
                let res = await fileReader.result;
                props.setFileList(prevstate => [...prevstate, { file64: res, name: img.name, title: '', description: '' }])
            };
        })
    }

    const handleFile = (e) => {
     

        let testExtension = Object.values(e.target.files).some(item =>  ['png', 'jpg', 'jpeg', 'svg'].includes(item.name.split('.').pop()) );

if(testExtension){ 
   notification('error', 'File extension', 'No pictures allowed -> .png, .jpg, .jpeg, .svg');//TOTRANSLATE

   return false;
}
     
        if (!validate(e.target)) {
            alert('1 file is bigger than the allowed limit : 10Mo');//TOTRANSLATE
            return false;
        }

        if (props.fileList && (props.fileList.length + e.target.files.length) >= 6) {

            alert('You cant have more than 6 picture for this project');//TOTRANSLATE
            return false;
        }

        let file = [...e.target.files];
   
        fileBase64(file);

    }


    function validate(el) {
        var maxfilesize = 1024 * 1024 * 20;  // 1 Mb

        for (var i in el.files) {

            if (el.files[i].size > maxfilesize) {

                return false;
            }

        }


        return true;


    }



    const editValue = (key, value, index) => {

        let temp = [...props.fileList];

        temp[index][key] = value;
      
        props.setFileList(temp);

    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>



            <h2>{t('new_project_files_title_1')}</h2>

            <Form>

                <Form.Item
                    name='image'

                >



                    {props.fileList.map((item, index) =>
<>
                        <Row gutter={[48, 48]}>

                            <Col  sm={12} md={8} xs={24}>

                                <span> {item && item.name} </span>
                            </Col>
                            <Col   sm={12} md={8} xs={24}>

                                <TextArea autoSize value={item.title} onChange={(e) => { editValue('title', e.target.value, index) }} maxLength={30} placeholder="Section title" />


                            </Col>
                            <Col  sm={12} md={8} xs={24}>

                                <TextArea autoSize maxLength={300} onChange={(e) => { editValue('description', e.target.value, index) }} value={item.description} placeholder="Section description" />


                            </Col>


                        </Row>
<br/>
                        </>

                    )}



                    <Divider />

                    <Row>
                        <Col>
                            <input type="file" /* accept=".png,.jpg,.jpeg,.svg" */ multiple={true} onChange={handleFile} />
                        </Col>
                        <Col>
                            <Button onClick={() => { props.setFileList([]); }} > {t('keyword_clearall')}</Button>
                        </Col>

                    </Row>



                </Form.Item>
            </Form>




        </div>
    );
}

export default NewProject_files;