
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Row,
    Col,
    Card,
    Avatar,
    Rate,
    Divider,
    Segmented,
    Tabs,


} from "antd";
import { CheckOutlined, DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import Translator from "../../../components/Translator/Translator";
import GeneralInfos from "../../../components/Settings/GeneralInfos";
import SecurityInfos from "../../../components/Settings/SecurityInfos";
import PaymentInfos from "../../../components/Settings/PaymentInfos";
import thumb_up from '../../../images/thumb_up.png';
import thumb_down from '../../../images/thumb_down.png';


const { TabPane } = Tabs;



const Settings = (props) => {
    const { t, i18n } = useTranslation();

    const [size, setSize] = useState('small');

    const onChange = (e) => {
        setSize(e.target.value);
    };



    const { user } = props;

    return (
        <div className="settings-page" >
            <Row gutter={[24, 24]} >

                <Col xs={24} sm={24} md={24} lg={15}>

                    <Tabs type="card">
                        <TabPane tab={t('settings_tab_1')} key="1">

                            <GeneralInfos user={props.user} colors={props.colors} />

                        </TabPane>
                        <TabPane tab={t('settings_tab_2')} key="2">
                            <SecurityInfos colors={props.colors} />
                        </TabPane>
                        <TabPane tab={t('settings_tab_3')} key="3">
                            <PaymentInfos colors={props.colors} />

                        </TabPane>
                    </Tabs>

                </Col>

                <Col justify='center' xs={24} sm={24} md={24} lg={9}>


                    <Row style={{ height: '56px' }} gutter={[24, 24]}  >

                    </Row>

                    <Card style={{ width: '80%', borderRadius: '15px' }}  >

                        <Row gutter={[24, 24]} >
                            <Col span='8' >
                                <h3> {t('keyword_identity')} </h3>
                            </Col>
                            <Col span='16' >

                                <img width={30} src={user.is_verified ? thumb_up : thumb_down} />
                                {user.is_verified ? t('keyword_verified') : t('keyword_not_verified')}

                            </Col>
                        </Row>
                    </Card>
                    <Divider dashed />

                    <Card className="roundBorder" style={{ width: '80%', borderRadius: '15px' }}  >

                        <Row gutter={[24, 24]} >
                            <Col span='8' >
                                <h3>{t('keyword_my_rating')}</h3>

                            </Col>
                            <Col span='16' >
                                <Rate allowClear={false} disabled value={user.partner} />

                            </Col>


                        </Row>
                    </Card>
                    <Divider dashed />

                    <Card className="roundBorder" style={{ width: '80%', borderRadius: '15px' }}  >

                        <Row gutter={[24, 24]} >
                            <Col span='8' >
                                <h3>{t('keyword_language')}</h3>

                            </Col>
                            <Col span='16' >
                              <Translator/>

                            </Col>


                        </Row>
                    </Card>

                </Col>


            </Row>


        </div>
    );
};

export default Settings;
