import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import texts from './components/Fixtures/allTexts';
i18next.use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
   
    resources: texts,
    lng: localStorage.getItem('i18lng') || 'cz',
    keySeparator: false, 
    interpolation: {
        escapeValue: false 
    },


    debug: true
  });

export default i18next;