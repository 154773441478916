import React from 'react';
import { useTranslation } from "react-i18next";
import { Button, Modal, Divider } from 'antd';

const ConfirmationPopup = (props) => {
  const { t, i18n } = useTranslation();

const {text, trigger, id, openFunction, open} = props;
  return (
    <Modal
      title={t('keyword_confirmation')}
      centered
      open={open}
      cancelText={t('keyword_cancel')}
      onOk={() => trigger(id, true)}
      okText={t('keyword_submit')}
      onCancel={() => openFunction(false)}
      width={300}
      footer={[
        <Button key="back" onClick={() => openFunction(false)}>
          {t('keyword_no')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          danger
          onClick={() => {trigger(id, true); openFunction(false);  }}
        >
           {t('keyword_yes')}
        </Button>,
      ]}
    >
      {text}
    </Modal>
  );
};

export default ConfirmationPopup;
