import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import printPrice from '../../Tools/printPrice';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import axios from 'axios';

// Import antd
import { Button, Col, Drawer, Menu, Row } from 'antd';
import { ManOutlined, MenuOutlined } from '@ant-design/icons';

const MarketHeader = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Col xs={24}>
        <h2
          style={{ fontSize: '2.5em', fontWeight: 'bold', padding: '0 15px' }}
        >
          {t('market_title_1')}
        </h2>
      </Col>
      <Col xs={24}>
        <Row gutter={[16, 16]} style={{ fontSize: '1.5em', padding: '0 15px' }}>
          <Col xs={24} md={12} lg={8} xl={6}>
            {t('market_header_money_invested_1')} <br />
            <span style={{ fontWeight: 'bold' }}>
              {printPrice(props.headerInfos.total_amount.toString()) || ''} kč
            </span>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            {t('market_header_created_projects_1')}
            <br />
            <span style={{ fontWeight: 'bold' }}>
              {props.headerInfos.total_projects || ''}
            </span>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            {t('market_header_investment_number_1')}
            <br />
            <span style={{ fontWeight: 'bold' }}>
              {props.headerInfos.total_invested || ''}
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default MarketHeader;
