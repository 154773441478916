
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
    Row,
    Col,
    Card,

    Button,

    Form,
    Input,
    Divider,


} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import notification from "../Notifications/Notification";



const CardBankAccount = (props) => {

    const { bank_account } = props;



    function onFinish() {


        let myToken = localStorage.getItem('bearer_token');



        axios.post("api/settings/deleteBankAccount", { id: bank_account.id },
            { headers: { authorization: `Bearer ${myToken}` }  }).then((res) => {
               

              

                if (res.data.status) {
                    notification('success', 'Success', res.data.message); //TOTRANSLATE
                    props.getBankAccounts();
                }
              
            }).catch((err) => {
                console.log(err)
                notification('error', 'Fail', 'Error while deleting this bank account') //TOTRANSLATE

            });





    };






    return (

        <Card className="payment-method-card">
            <h6 className="card-number">{bank_account.bank_account}</h6>
            <Button type="link" className="ant-edit-link">
                <DeleteOutlined onClick={() => { onFinish() }} />

            </Button>
        </Card>

        //
    );
};

export default CardBankAccount;
