import React from 'react';

import { Button, Col, Row } from 'antd';
import { useTranslation } from "react-i18next";

import CardProject from '../../../components/Cards/CardProject';

const ListProjects = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <Row style={{ margin: 'auto', maxWidth: '1400px', padding: '30px 0' }}>
      <Col xs={24}>
        <h2
          style={{ fontSize: '3em', fontWeight: 'bold', textAlign: 'center' }}
        >
          {t('home_page_list_projects_title')}
        </h2>
      </Col>
      <Col
        xs={24}
        style={{
          backgroundColor: '#FFF',
          marginTop: '20px',
          padding: '15px',
        }}
      >
        <Row gutter={[10, 10]}>
          {props.projects.map((project, key) => (
            <Col xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={8}>
              <CardProject infos={project} key={key} edit={false} />
            </Col>
          ))}
        </Row>
      </Col>
      {props.seeMore && (
        <Col xs={24}>
          <a
            href="/projects"
            style={{
              backgroundColor: '#003464',
              color: '#FFF',
              borderRadius: '10px',
              height: '50px',
              width: '200px',
              fontSize: '1.2em',
              margin: 'auto',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {t('home_page_list_see_more')}
          </a>
        </Col>
      )}
    </Row>
  );
};

export default ListProjects;
