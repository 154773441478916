import React from 'react';
import axios from 'axios';
import { Col, Row } from 'antd';

import CreatorInvestors from '../../../components/HomePage/CreatorInvestors/CreatorInvestors';
import CalculatorBorrow from '../../../components/HomePage/Calculator/CalculatorBorrow';
import LandPage from '../../../components/HomePage/LandPage/LandPage';
import ListProjects from '../../../components/HomePage/ListProjects/ListProjects';
import Partners from '../../../components/HomePage/Partners/Partners';
import Project from '../../../components/HomePage/Project/Project';

import './HomePage.css';

import background from '../../../images/abstract-background-with-monochrome-low-poly-design_1048-14453.jpg';
import home_picture from '../../../images/pfg_home_background.jpg';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      errorLoading: false,
      loading: true,
    };

    this.getPublicProjects = this.getPublicProjects.bind(this);
  }

  componentDidMount() {
    this.getPublicProjects();
  }

  getPublicProjects() {
    axios
      .get('api/project/getpublicprojects')
      .then((response) => {
        const projects = response.data.projects.slice(0, 10);
        this.setState({
          loading: false,
          projects: projects,
        });
      })
      .catch((error) => {
        console.log('ERROR ', error);
        this.setState({
          loading: false,
          errorLoading: true,
        });
      });
  }

  render() {
    return (
      <Row className="home-page">
         <Col
          span={24}
          className="home-page__land-page"
          style={{
            backgroundImage: `url(${home_picture})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            height: '0',
            paddingBottom: '56%',

          }}
        >

{/* <div className='' ></div> */}
        </Col>

        <Col
     
          className="home-page__land-page"
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: '100% auto',
            backgroundRepeatX: 'repeat',
            width:'100%',
        
         
            


          }}
        >
          <LandPage />
        </Col>
        {/* <Col span={24} className="home-page__project">
          <Project />
        </Col> */}
        <Col span={24} className="home-page__creatorinvestor">
          <CreatorInvestors />
        </Col>
        {/* <Col span={24} style={{ height: 'auto', backgroundColor: '#F0F0F0' }}>
          <CalculatorBorrow />
        </Col> */}
        <Col
          span={24}
          style={{ height: 'auto', display: 'flex', backgroundColor: '#FFF' }}
        >
          <ListProjects
            projects={this.state.projects}
            error={this.state.errorLoading}
            loading={this.state.loading}
            seeMore={true}
          />
        </Col>
        <Col
          span={24}
          style={{
            height: 'auto',
            display: 'flex',
            backgroundColor: '#F0F0F0',
          }}
        >
          <Partners />
        </Col>
      </Row>
    );
  }
}

export default HomePage;
