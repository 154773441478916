import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import './auth.css';
import axios from 'axios';
import { Result, Card, Row, Col, Button, Checkbox, Divider, Form, Input } from 'antd';
import { FrownOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import losanges from '../../../images/losanges.jpeg';
import house from '../../../images/house.webp';
import pfg from '../../../images/pfg-logo-blue.png';
import notification from '../../../components/Notifications/Notification';

const PasswordReset = (props) => {
    const { t, i18n } = useTranslation();

    const [validUrl, setValidUrl] = useState(false);
    const [message, setMessage] = useState('Wait a moment');



    const resetPassword = (values) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const id = urlParams.get('id');
        const token = urlParams.get('token');

        if (values.password != values.repeat_password)
            return null;

        axios.post(`api/auth/reset-password/${id}/${token}`, { password: values.password })
            .then((res) => {

                
                setMessage(res.data.message);
                setValidUrl(!res.data.status);
            })
            .catch((err) => {
                console.log(err.response.data.msg);

                notification('error', 'Login error', err.response.data.msg);
            });
    };




    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const id = urlParams.get('id');
        const token = urlParams.get('token');
      
        axios
            .get(`api/auth/reset-password/${id}/${token}`)
            .then((res) => {
                setValidUrl(res.data.status);
                setMessage(res.data.message);

            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div className="losanges1">
            <Row className="losanges" md={24} xs={24} justify="center" align="center">
                <Col
                    xs={10}
                    style={{
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <Row align="center" justify="center">
                        <img style={{ width: '130px' }} src={pfg} />
                    </Row>



                    <Row align="center" justify="center" xs={24}>

                        {validUrl ?

                            <Form
                                name="normal_forgotPassword"
                                className="forgotPassword-form"

                                onFinish={resetPassword}
                                style={{ width: '400px' }}
                            // form={form}
                            >
                                <Form.Item
                                    name="password"
                                    rules={[
                                        { required: true,
                                            min: 8,
                                            message: t('login_verification_password_1'),
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{ height: '60px', borderRadius: '10px' }}
                                        prefix={<UserOutlined className="site-form-item-icon" />}
                                        placeholder={t('settings_securitytab_new_password_1')}
                                        type="password"
                                    />
                                </Form.Item>


                                <Form.Item
                                    name="repeat_password"
                                    rules={[
                                        { required: true,
                                            min: 8,
                                            message: t('register_verifications_password_1'),
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{ height: '60px', borderRadius: '10px' }}
                                        prefix={<UserOutlined className="site-form-item-icon" />}
                                        placeholder={t('settings_securitytab_repeat_password1')}
                                        type="password"
                                    />
                                </Form.Item>

                                <Form.Item
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="forgot_password-form-button"
                                        style={{
                                            borderRadius: '10px',
                                            background: '#003464',
                                            color: 'white',
                                            border: '#003464',
                                            height: '50px',
                                            width: '300px',
                                        }}
                                    >
                                        {t('keyword_reset_password')}
                                    </Button>

                                </Form.Item>
                            </Form>


                            :
                            <>
                                <Row span='24' align="center" justify="center">
                                    <h2 style={{ color: props.colors.primary }}> {message} </h2>
                                </Row>
                                <Divider />
                                <Row span='24' >
                                    <Button onClick={() => { window.location.href = '/login' }}  >
                                        {t('keyword_back_login')}
                                    </Button>
                                </Row>


                            </>

                        }

                    </Row>
                </Col>

                <Col
                    md={14}
                    xs={0}
                    className="losanges2"
                    style={{
                        backgroundImage: `url('${house}')`,
                        backgroundRepeat: 'no-repeat',
                    }}
                ></Col>
            </Row>
        </div>
    );
};

export default PasswordReset;
