const faq = {
    "Jak začít investovat? ": [
        {
            title: "Jak začít investovat? ",
            description:
                `1.Registrujte se na našich stránkách a získejte přístup do Vašeho portálu investora
          2. Zašlete 2 potřebné dokumenty k registraci ( o.p. a ř.p. popř. Jiné z možností)
          3. Mezi aktuálními investičními příležitostmi si vyberte projekt do kterého chcete investovat
          4. K vybrané investici vložíte závazný příslib odeslání fin. Prostředků. Peníze odesíláte až ve chvíli,
          kdy Vám příjde do e-mailu výzva k investici.
          5.Výnosy z investic Vám začnou chodit na Vámi registrovaný bankovní účet dle předem
          stanovéného termínu. `,
        },

    ],
    "Proč investovat na crowdfundingovém portálu společnosti PFG a.s. ? ": [
        {
            title: "Co nás motivovalo založit investiční portál? ",
            description:
                `Začátky našeho podnikání sahají do dob první světové finanční krize roku 2008, během které jsme
                se naučili chodit a reagovat v rámci realitního trhu na zajímavé možnosti financování rezidenčních
                nemovitostí takřka po celé České republice. Po takřka 15 letech nás začali oslovovat i externí
                subjekty podobného ražení a proto když nabídka začala převyšovat poptávku jsme se rozhodli
                založit crowd-fundingový investiční portál s ryze českými nemovitosti a jedinečným zajištěním
                zástavním věřitelským právem stejným jako má banka v hypotečním úvěru. `      },
        {
            title: "Proč by si měl investor vybrat právě nás?",
            description:
                `Každá investice má svoje riziko, likviditu a výnos kvůli, kterému investoři nejčastěji sahají po
                investičních nástrojích. U nás v PFG věříme, že jsou si všechny tři pilíře rovné, ale jeden je jim
                rovnější a to právě zajištění, které mnohdy rozhoduje o návratu finančních prostředků na
                bankovní účet investora, divoké devadesátky jsou již dávno za námi proto každý klient, který se
                rozhodne investovat prostřednictví našeho portálu má své prostředky zajištěné zástavním
                věřitelským právem. Zároveň mu z investovaných financí chodí i fixní předem stanovený úrok na
                jeho bankovní účet.`,
        },
        {
            title: "Jak dlouho trvá ověřit můj investorský profil? ",
            description:
                `Po dokončení registrace a zaslání 2 dokumentů totožnosti na email info@pfg.cz dojde k ověření
                Vašeho investorského profilu z pravidla do 30 minut od zaslání dokumentů. `,
        },
      
    ],
    "Investice ": [
        {
            title: "Jaká je minimální suma pro investování",
            description:
                `Všechny projekty mají minimální vstupní limit začínající na hodnotě 10.000 kč horní hranice je
                omezená aktuálním stavem projektu. `
        },
        {
            title:
                "Kdy a kam mám poslat peníze do vybraného projektu? ",
            description:
                `Po uzavření možnosti vstupu do projektu Vám na email dojde výzva a platební instrukce s
                unikátním variabilním symbolem. V den připsání finančních prostředků na učet úvěrovaného
                dochází k úročení financí. `,
        },
        {
            title: "Jak je spolupráce smluvně zajištěna? ",
            description:
               `Každá spolupráce vyžaduje i právní základ. To co se nám časem osvědčilo jako nejlepší
               proklientská varianta je smlouva o účelovém úvěru a smlouva o zřízení zástavního práva k
               nemovité věci. `,
        },
        {
            title: `Kdy mi příjde výnos a investované peníze? `,
            description:
                `Výnos klient obdrží na svůj bankovní účet, dle předem stanoveném dni uvedeném ve smlouvě.
                Současně se jistina klientovi vrátí v den splatnosti úvěru tzn. V den konce projektu. `,
        },
        {
            title: "Jak je to s daní z příjmu? ",
            description:
                `Příjmy investora z účasti na projektech podléhají zdanění ve smyslu ust. § 10 zákona č. 586/1992
                Sb., o daních z příjmů, ve znění pozdějších předpisů. Základem daně je tedy celý příjem obdržený
                z participace na projektu, snížený o částku poskytnutých prostředků na danou participaci. Každý
                investor má povinnost podat daňové přiznání sám, a to do 1. dubna následujícího roku.
                
                V bance většinou klienti využívají spořící produkty. Například spořící účty, termínované vklady
                nebo stavební spoření. Hlavní rozdíl je ten, že na našem portále peníze nespoříte, ale investujete
                velice podobně jako to dělá samotná banka když se rozhodne financovat hypotéku čili poskytne
                úvěr a vložené peníze má zajištěné nemovitým majetkem. Díky tomu, že investiční portál
                nevyžaduje tak velké provozní náklady jako velké banky je investor schopný se dostat na výnosy
                okolo 10-12% p.a. z investované sumy které jsou realizovány na klientův bankovní účet.`,
        },
    ],
}


export default faq;