
const COLORS = {
  black: '#000',
  primary:'#003465',
  success: '#52c41a',
  danger: '#ff0000',
white:'#FFF',
secondary:'#1990ff',
background: '#f4f4f5',
orange: '#f86b02',
purple: '#6c04b9',
darkGray: '#f5f5f5'

}


export default COLORS;