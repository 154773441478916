import { Segmented } from 'antd';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';

const MySegmented=  (props) => {



    return (
  <Segmented
  onChange={()=>{props.setIsList(!props.isList)}}
    options={[
        {
            value: 'Kanban',
            icon: <AppstoreOutlined />,
          },
      {
        value: 'List',
        icon: <BarsOutlined />,
      },
    
    ]}
  />

  )
};

export default MySegmented;