
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
    Row,
    Col,
    Card,

    Button,
    AutoComplete,
    Form,
    Input,
    Divider,


} from "antd";
import { UserOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons';
import notification from "../Notifications/Notification";
import codes from "../Fixtures/InternationalPhoneCodes";

const inpuStyle = {
    height: '40px', borderRadius: '10px'
}

const GeneralInfos = (props) => {
    const { t, i18n } = useTranslation();

    const [form] = Form.useForm();


    const onFinish = () => { 



        axios.post(`api/user/editGlobalUserInfos`, { user: form.getFieldsValue() }, { headers: { authorization: `Bearer ${localStorage.getItem('bearer_token')}` } })
            .then(res => {

                if (res.data.status) {
                    notification('success', t('keyword_edited_successfully'), res.data.message);//TOTRANSLATE

                }


            }).catch(err => {
                console.log(err.response.data.message);
                notification('error', t('keyword_problem'), err.response.data.message);//TOTRANSLATE

            }
            )

    };



    useEffect(() => {

        const { user } = props;
        form.setFieldsValue({

            id: user.id,
            firstname: user.first_name,
            lastname: user.last_name,
            phone_number: user.phone_number || '+420',
            address: user.address,
            city: user.city,
            country: user.country,

        });
    }, []);



    return (
        <Card style={{ borderRadius: '15px' }}>
            <Row gutter={[24, 24]} xs={24} justify="start">

                <h1 style={{ color: props.colors.primary }} > {t('settings_generaltab_title_1')}</h1>

            </Row>


            <Row justify="start">

                <Divider orientation="center">{t('settings_generaltab_subtitle_1')}</Divider>
                <Form onFinish={onFinish}

                    form={form}
                    layout="vertical"
                    name="horizontal_login" >

                    <Row gutter={[24, 8]} >
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} >

                            <Form.Item name="firstname" label={t('keyword_firstname')} tooltip={{
                                title: t('settings_generaltab_tooltip_firstname_1'),
                            }}  >

                                <Input style={inpuStyle} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="First name" />
                            </Form.Item>


                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <Form.Item
                                label={t('keyword_lastname')}
                                tooltip={{
                                    title: t('settings_generaltab_tooltip_lastname_1'),
                                }}
                                name="lastname"  >
                                <Input style={inpuStyle} placeholder={t('keyword_lastname')}
                                    prefix={<UserOutlined className="site-form-item-icon" />}

                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <Form.Item
                                label={t('keyword_phone')}
                                tooltip={{
                                    title: t('settings_generaltab_tooltip_phone_1'),
                                }}
                                name="phone_number"  >
                                <Input style={inpuStyle} placeholder="+420*********"
                                    prefix={<PhoneOutlined className="site-form-item-icon" />}

                                />
                            </Form.Item>
                        </Col>
                    </Row>



                </Form>
            </Row>
            {/* Address */}

            <Row justify="start" >
                <Divider orientation="center"><span  >{t('settings_generaltab_subtitle_2')}</span> </Divider>
                <Form form={form} layout="vertical"
                    name="normal_login"
                    className="login-form" >

                    <Row gutter={[24, 8]} >
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <Form.Item name="address" label={t('keyword_address')} tooltip={{
                                title: t('settings_generaltab_tooltip_address_1')
                            }}  >
                                <Input style={inpuStyle} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Ceska 00" />
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <Form.Item name="city" label={t('keyword_city')} tooltip={{
                                title: t('keyword_city'),
                            }}  >
                                <Input style={inpuStyle} prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t('keyword_city')} />
                            </Form.Item>


                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <Form.Item name="country" label={t('keyword_country')} tooltip={{
                                title: t('keyword_country'),
                            }}  >
                                {/* <AutoComplete
                                  
                                    options={codes}
                                    placeholder="country"
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                /> */}

                                <Input style={inpuStyle} prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t('keyword_country')} />

                            </Form.Item>


                        </Col>
                    </Row>



                </Form>
            </Row>
            <Row justify="center" gutter={[48, 48]} >
                <Col>

                    <Button style={{
                        background: props.colors.success,
                        color: props.colors.white,
                        margin: '15px',
                        borderRadius: '10px',
                        width: '120%'

                    }} block
                        onClick={onFinish}
                    >
                        {t('keyword_submit')}
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};

export default GeneralInfos;
