

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import partner1 from '../../images/partner1.svg';
import { Avatar, Badge, Divider } from 'antd';
import './cards.css'
import {
    Row,
    Col,
    Menu,
    Dropdown,
    Skeleton,
    Card,
    Button,
    Progress,
    Collapse,
    Image,

} from "antd";

import {
    ArrowLeftOutlined, CaretRightOutlined,
} from "@ant-design/icons";

const styleBorder = {
    borderRadius: '15px',
}


const CardProjectDetails2 = (props) => {
    const { t, i18n } = useTranslation();


    const { project, url, colors, fileList, isPreview } = props;
    const { description } = project
    const { Panel } = Collapse;




console.log(url)

    return (



        <Row >
            <Col xs={24}>
                {
                     (!url || !url.length) ?

                        <Card>
                            <Skeleton.Image active />
                        </Card>
                        :

                        <Card style={styleBorder} >
                            <Row xs={24} gutter={[24, 24]} >
                                <Col xs={12} lg={12}  >
                                    <Image
                                        style={{ width: '100%' }}
                                        src={isPreview ? (url && url.length && url[0]['file64']) : (url && url.length && url[0])}
                                    />

 
                                </Col>

                                <Col xs={12} lg={12} style={{ overflow: 'scroll', maxHeight: '32vh', paddingLeft: '1px' }} >
                                    <Row gutter={[2, 1]} xs={24}  >


                                        {url && url.map((item, index) => {
                                            return (

                                            

                                                <Col key={item} xs={8}   >
                                                    <Image
                                                        key={index + 'qwertyui'}
                                                        style={{ maxWidth: '100px', width: '100%' }}
                                                        src={isPreview ? item.file64 : item}
                                                    />
                                                </Col>
                                            )
                                        }
                                        )}

                                    </Row>
                                </Col>


                            </Row>
                        </Card>
                }
            </Col>


            <Divider dashed />

            {
                !props.project ?

                    <Col xs={24}  >
                        <Skeleton active />
                    </Col>
                    :


                    <Col xs={24} >

                        <Collapse
                            bordered={false}
                            style={{ background: colors.white, borderRadius: '10px' }}
                            defaultActiveKey={['1']}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            className="site-collapse-custom-collapse"
                        >
                            <Panel header={<h3>{t('keyword_description')}</h3>} key="1673219" className="site-collapse-custom-panel" style={{ background: 'white' }} >

                                {project.description && project.description.split('\n').map(item =>
                                    <>
                                        <br />
                                        <span>
                                            {item}

                                        </span>
                                    </>
                                )}

                            </Panel>

                        </Collapse>

                    </Col>

            }
            <Divider  />


            {
                fileList && fileList.map((item, index) =>

                    <Col xs={24} >
                    
                        <Collapse
                            bordered={false}
                            style={{ background: props.colors.white, borderRadius: '10px' }}
                            defaultActiveKey={['1']}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            className="site-collapse-custom-collapse"
                        >
                            <Panel header={<h3>{item.title}</h3>} key={index + 'item'} style={{ background: 'white' }} className="site-collapse-custom-panel">
                                {
                                !isPreview ?

                                item.descriptions && item.descriptions.split('\n').map(item2 =>
                                    <>
                                        <br />
                                        <span>
                                            {item2}

                                        </span>
                                    </>
                                )
                            :
                            item.description && item.description.split('\n').map(item2 =>
                                <>
                                    <br />
                                    <span>
                                        {item2}

                                    </span>
                                </>
                            )
                            
                            }
                                <br /><br />
                                <a href={item.url && item.url.replace('/upload/', '/upload/fl_attachment/')} >{item.name}</a>
                            </Panel>

                        </Collapse>
                       
                        {item.url && index != item.url.length - 1 ? <Divider dashed /> : null

                        }
                    </Col>

                )

            }
        </Row>





    );
}

export default CardProjectDetails2;



