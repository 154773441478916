import React, { useState } from "react";
import {
  SettingOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { Collapse, Select, Divider, Row, Col, Card, Space } from "antd";
import "./questionAnswers.css";
const { Panel } = Collapse;
import faq from "../../../components/Fixtures/questionsAnswers";
const QuestionAnswers = () => {
  const genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );



  return (
    <div>
      <Row xs={24} style={{ padding: "100px 0" }}>
        <Col xs={0} md={0} sm={0} lg={0} xl={5}></Col>
        <Col lg={24} xl={14}>
          {Object.keys(faq).map((item) => (
            <>
              <div style={{ padding: 0, background: "white", borderRadius: "10px" }}>
                <div
                  style={{
                    textAlign: "left",
                    padding: "20px",
                    borderBottom: "1px #b5b5b540 solid",
                  }}
                >
                  <h1>{item}</h1>
                </div>

                <Collapse
                  defaultActiveKey={["1"]}
                  bordered={false}
                  expandIconPosition="end"
                  expandIcon={({ isActive }) =>
                    !isActive ? (
                      <MinusCircleTwoTone twoToneColor="#52c41a" />
                    ) : (
                      <PlusCircleTwoTone twoToneColor="#eb2f96" />
                    )
                  }
                >
                  {faq[item].map((panel) => (
                    <Panel
                      style={{
                        background: "white",
                        textAlign: "start",
                        fontSize: "120%",
                      }}
                      header={panel.title}
                      key={panel.title} /* extra={genExtra()} */
                    >
                      <div style={{display: "flex"}}>
                        <p style={{wordWrap: "break-word", fontSize: "0.8em"}}> {panel.description.split('\n').map(linebreak =>
                         <span> {linebreak} <br/></span>
                         )}</p>
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              </div>

              <br />
              <br />
            </>
          ))}
        </Col>
        <Col xs={0} md={0} sm={0} lg={0} xl={5}></Col>
      </Row>
    </div>
  );
};

export default QuestionAnswers;
