import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import './project.css';
import {
  Row,
  Col,
  PageHeader,
  Button,
  Card,
  Image,
  Collapse,
  Divider,
} from 'antd';

import CardProjectDetails from '../../../components/Cards/CardProjectDetails';
import CardProjectDetails2 from '../../../components/Cards/CardProjectDetails2';
import Error from '../Errors/Error';
import notification from '../../../components/Notifications/Notification';

const Project = (props) => {
  const { t, i18n } = useTranslation();

  const [project, setProject] = useState({ project: {}, status: true });
  const { Panel } = Collapse;

  function getProjectById() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const id = urlParams.get('id');
    let myToken = localStorage.getItem('bearer_token');

    axios
      .post(
        'api/project/getProjectById',
        { project_id: id },
        { headers: { authorization: `Bearer ${myToken}` } }
      )
      .then((response) => {
        console.log("")
        if (response.data.found) {
          setProject({ status: true, project: response.data.project });
        } else {
          setProject({ status: false, project: {} });
        }
      })
      .catch((err) => {
        console.log(err);
        notification('error', 'Server error', err.response.data.message);
        setProject({ status: false, project: {} });
      });
  }

  useEffect(() => {
    getProjectById();
  }, []);

  return (
    <>
      <Row gutter={[0, 24]} justify="start">
        <Col>
          <PageHeader
            className="site-page-header"
            onBack={() => window.history.back()}
            title={t('project_header_1')}
            subTitle={t('project_subheader_1')}
          />
        </Col>
      </Row>

      <Divider dashed />

      {!project.status ? (
        <Error status={'404'} title="Oups... We do not find this project" /> //TODO
      ) : (
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={10}>
            <CardProjectDetails
              getProject={getProjectById}
              project={project.project}
              colors={props.colors}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={14} /* xl={8} xxl={8}  */>
            <CardProjectDetails2
              project={project.project}
              url={project.project.url}
              fileList={project.project.fileList}
              colors={props.colors}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Project;
