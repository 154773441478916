import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import axios from 'axios';
import {
  Row,
  Col,
  Input,
  Table,
  Button,
  DatePicker,
  Dropdown,
  Card,
  Image,
  Steps,
  Popover,
  Upload,
  Select,
  Tag,
  Space,
  Modal,
} from 'antd';

import { InboxOutlined, DownOutlined } from '@ant-design/icons';
import DragUpload from '../../../components/CRUD_project/DragUpload';
import PopupProject from './PopupProject';
import ConfirmationPopup from './ConfirmationPopup';

const { MonthPicker, RangePicker } = DatePicker;

const { Dragger } = Upload;
const { Step } = Steps;
const { TextArea } = Input;
const { Column, ColumnGroup } = Table;

const Projects = (props) => {
  const { t, i18n } = useTranslation();

  const [projects, setProjects] = useState([]);
  const [chosenProject, setChosenProject] = useState([]);
  const [openProjectEdit, setOpenProjectEdit] = useState(false);
  const [deleteProjectConfirmation, setDeleteProjectConfirmation] =
    useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [idDeleteProject, setIdDeleteProject] = useState(null);

  useEffect(() => {
    getProjects();
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t('keyword_search')}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t('keyword_reset')}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {t('keyword_filter')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleDeleteProject = (project_id, confirmation) => {
    if (!confirmation) {
  
      setOpenConfirmation(true);
      setIdDeleteProject(project_id);
    } else {

      axios
        .delete(`api/admin/deleteProject/${project_id}`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
          },
        })
        .then((res) => {
         
          getProjects();
          setIdDeleteProject(null);
        })
        .catch((err) => console.log(err));
    }
  };

  const columns = [
    {
      title: t('keyword_picture'),
      dataIndex: 'url',
      render: (text) => text && text.length && <Image width={100} src={text[0]} />,
      responsive: ['xl'],
    },
    {
      title: t('keyword_title'),
      dataIndex: 'title',
      //   render: (text) => <a>{text}</a>,
      //   ...getColumnSearchProps('title'),
    },

    {
      title: t('keyword_users'),
      dataIndex: 'email',
      //   render: (text) => <a>{text}</a>,
    },
    {
      title: t('keyword_city'),
      dataIndex: 'city',
      responsive: ['md'],
      //   render: (text) => <a>{text}</a>,
      //   ...getColumnSearchProps('role'),
    },
    {
      title: t('keyword_active'),
      dataIndex: 'active',
      responsive: ['md'],
      render: (text) => <span>{text ? t('keyword_yes') : t('keyword_no')}</span>,
    },
    { 
      title: t('keyword_creation_date'),
      dataIndex: 'creation_date',
      render: (text) => <span>{text && text.split('T')[0]}</span>,
      responsive: ['md'],
      //   ...getColumnSearchProps('role'),
    },
    {
      title: t('keyword_due_date'),
      dataIndex: 'due_date',
      render: (text) => <span>{text && text.split('T')[0]}</span>,
      responsive: ['md'],
    },
    {
      title: t('keyword_dates'),
      dataIndex: 'start_date',
      render: (text, row) => <span>{text && text.split('T')[0]} / {row.closing_date && row.closing_date.split('T')[0]}</span>,
      responsive: ['xl'],
    },
    {
      title: t('keyword_action'),
      dataIndex: 'id',
      render: (text, row) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItem: 'center',
          }}
        >
          <Button
            onClick={() => {
              setChosenProject(row);
              setOpenProjectEdit(true);
            }}
            type="primary"
          >
            {t('keyword_view_edit')}
          </Button>
          {/* <Button // DELETE BUTTON ONLY DEV
            onClick={() => {
              handleDeleteProject(row.id, false);
            }}
            type="primary"
            danger
            style={{
              marginTop: '3px',
            }}
          >
            {t('keyword_delete')}
          </Button> */}
        </div>
      ),
    },
  ];

  function getProjects() {
    axios
      .get('api/admin/getProjects', {
        headers: {
          authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
        },
      })
      .then((res) => {
      console.log(res.data.projects)
        setProjects(res.data.projects);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Table columns={columns} dataSource={projects}></Table>
      <PopupProject
        getProjects={getProjects}
        openProjectEdit={openProjectEdit}
        chosenProject={chosenProject}
        setChosenProject={setChosenProject}
        setOpenProjectEdit={setOpenProjectEdit}
        handleDeleteProject={handleDeleteProject}
        idDeleteProject={idDeleteProject}
      />
      <ConfirmationPopup
        trigger={handleDeleteProject}
        open={openConfirmation}
        openFunction={setOpenConfirmation}
        text={t('admin_page_projects_confirmation_1')}
        id={idDeleteProject}
      />
    </>
  );
};

export default Projects;
