import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './charts.css';
import {
  Row,
  Col,
  Card,

} from "antd";





class StackedBarChart extends React.Component {






  render() {


    const { datas, t, i18n } = this.props;
    return (
      <ResponsiveContainer className="full-card" width="95%" height="95%"   >
        {/* <Card bordered={true} className="full-card" > */}


        <BarChart
          width={500}
          height={500}
          data={[

            {
              name: `${t('keyword_quarter')} 1`,
              Profit: parseInt(datas['profit_q1']),
              Invested: parseInt(datas['invested_q1']),

            },
            {
              name: `${t('keyword_quarter')} 2`,
              Profit: parseInt(datas['profit_q2']),
              Invested: parseInt(datas['invested_q2']),
              amt: 2400,
            },
            {
              name: `${t('keyword_quarter')} 3`,
              Profit: parseInt(datas['profit_q3']),
              Invested: parseInt(datas['invested_q3']),
              amt: 2400,
            },
            {
              name: `${t('keyword_quarter')} 4`,
              Profit: parseInt(datas['profit_q4']),
              Invested: parseInt(datas['invested_q4']),
              amt: 2400,
            },




          ]}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}

        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend
            payload={
              [{
                name: t('dashboard_barchart_profit'),
                color: '#52c41b'
              },
              {
                name: t('dashboard_barchart_invested'),
                color: '#003465'
              }].map(
                (item, index) => ({
                  id: item.name,
                  type: "square",
                  value: `${item.name}`,
                  color: item.color,
                })
              )
            }
          />
          <Bar
            dataKey='Invested'
            stackId="a" fill={this.props.colors.primary} />
          <Bar /* dataKey={t.cz.dashboard_barchart_profit} */
            dataKey='Profit'
            stackId="a" fill={this.props.colors.success} />
        </BarChart>

        {/* </Card> */}
      </ResponsiveContainer>
    );
  }
}
export default withTranslation()(StackedBarChart);



