import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

import { Image, Card, Form, List, Row,Typography, Divider, Col, Button, Upload } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ImageUploader from 'react-images-upload'


const { Meta } = Card;


const NewProject_pictures = (props) => {
    const { t, i18n } = useTranslation();




 

    useEffect(() => {

    }, [props.mediaList]);

    const fileBase64 = (img) => {
        let result = [...img];

        // setUrlImage(img);

        result && result.map(function (img) {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(img);
            fileReader.onloadend = async () => {
                let res = await fileReader.result;
                props.setMediaList(prevstate => [...prevstate, {file64:res, name: img.name}])
            };
        })
    }

    const handleImage = (e) => {
        

        if (!validate(e.target)) {
            alert('1 file is bigger than the allowed limit : 10Mo');
            return false;
        }

        if( props.mediaList && (props.mediaList.length + e.target.files.length )>=6){

            alert('You cant have more than 6 picture for this project'); //TOTRANSLATE
            return false;
        }

        let image = [...e.target.files];
        fileBase64(image);
    }


    function validate(el) {
        var maxfilesize = 1024 * 1024 * 10;  // 10 Mb

        for (var i in el.files) {

            if (el.files[i].size > maxfilesize) {

                return false;
            }

        }


        return true;


    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>



<h2>{t('new_project_pictures_title_1')}</h2>

            <Form>

                <Form.Item
                    name='image'
                  
                >


                    {/* <Upload
                        beforeUpload={(file) => {
                            return false;
                        }}
                        onChange={handleChange}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        multiple={true} //to upload multiple pictures
                        listType='picture'
                        defaultMediaList={props.mediaList}
                        onRemove={() =>{props.setMediaList([])}}
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload> */}





                    <Row gutter={[48,48]}>


                        {props.mediaList.map((item, index) =>

                          
<Col>
                                <Card
                                    hoverable
                                    style={{ width: 300 }}
                                    cover={
                                        <div style={{ overflow: "hidden", width: '300px', height: '200px'  }}>
                                            <Image
                                                alt="example"
                                                style={{}}
                                                src={item.file64}
                                            />
                                        </div>
                                    }
                                >
                                    <Meta title={`n°${index+1} - ${item.name}`}  />
                                </Card>


                                </Col>



                        )}


                    </Row>
                    <Divider />

<Row>
    <Col>
<input type="file" accept=".png,.jpg,.jpeg,.svg" multiple={true} onChange={handleImage} />
</Col>
<Col>
<Button onClick={() => { props.setMediaList([]);  }} > {t('keyword_clearall')}</Button>
</Col>

</Row>
               


                </Form.Item>
            </Form>




        </div>
    );
}

export default NewProject_pictures;