import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import './LogOutNavigation.css';

import logo from '../../images/pfg-logo-blue.png';
import { useTranslation } from "react-i18next";

// Import antd
import { Button, Col, Drawer, Menu, Row } from 'antd';
import { ManOutlined, MenuOutlined } from '@ant-design/icons';
import Translator from '../Translator/Translator';

const Navigation = (props) => {
  const { t, i18n } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);

  const navigation = [
    {
      name: t('menu_home'),
      url: '/',
      key: '1',
    },
    {
      name: t('menu_manual'),
      url: '/manual',
      key: '2',
    },
    {
      name: t('menu_questions'),
      url: '/Q&A',
      key: '3',
    },
    {
      name: t('menu_contact'),
      url: '/contact',
      key: '4',
    },
    {
      name: t('menu_projects'),
      url: '/projects',
      key: '5',
    },
  ];

  const onOpen = () => {
    setMenuOpen(true);
  };

  const onClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Row justify="space-between" className="menu--desktop">
        <Col span={4}>
          <Row justify="" start>
            <a href="/" className="menu--desktop__logo">
              <img src={logo} alt="PFG Logo" style={{ height: '50px' }} />
            </a>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="center" align="middle" className="menu--desktop__links">
            <Menu mode="horizontal" className="menu--desktop__links__menu">
              {navigation.map((item) => (
                <Menu.Item key={item.key}>
                  <span>{item.name}</span>
                  <Link to={item.url} />
                </Menu.Item>
              ))}
            </Menu>
          </Row>
        </Col>
        <Col span={8}>
          <Row justify="end" className="menu--desktop__links">
            <Col span={9}>
              <Button
                href="/login"
                style={{
                  borderRadius: '10px',
                  border: '#003464',
                }}
              >
                {t('menu_sign')}
              </Button>
            </Col>
            <Col span={9}>
              <Button
                href="/register"
                style={{
                  borderRadius: '10px',
                  background: '#003464',
                  color: 'white',
                  border: '#003464',
                }}
              >
                {t('menu_register')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="menu--phone" justify="space-between">
        <Col xs={10}>
          <div className="menu--phone__hamburger" onClick={() => onOpen()}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Col>
        <Col xs={10}>
          <Row justify="end" start style={{ marginRight: '40%' }}>
            <a href="/" className="menu--phone__logo">
              <img src={logo} alt="PFG Logo" style={{ height: '50px' }} />
            </a>
          </Row>

        </Col>
    
        <Drawer
          placement="left"
          closable={true}
          onClose={onClose}
          visible={menuOpen}
          key={'left'}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Menu mode="vertical">
              {navigation.map((item) => (
                <Menu.Item
                  key={item.key}
                  style={{ fontSize: '1.5em', textAlign: 'center' }}
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span>{item.name}</span>
                  <Link to={item.url} />
                </Menu.Item>
              ))}
            </Menu>
          
            <Menu mode="vertical">
              <Menu.Item style={{ textAlign: 'center' }}>
                <Button
                  href="/login"
                  style={{
                    borderRadius: '10px',
                    border: '#003464',
                  }}
                >
                  SIGN IN
                </Button>
              </Menu.Item>
              <Menu.Item style={{ textAlign: 'center' }}>
                <Button
                  href="/register"
                  style={{
                    borderRadius: '10px',
                    background: '#003464',
                    color: 'white',
                    border: '#003464',
                    width: '80%',
                  }}
                >
                  JOIN US
                </Button>
              </Menu.Item>
            
            </Menu>
          </div>
        </Drawer>
        {/* {menuOpen && (
          <Col xs={24} className="menu--phone__navigation">
            <Row justify="center" gutter={[0, 16]}>
              <Col span={24}>
                <Menu mode="vertical">
                  {navigation.map((item) => (
                    <Menu.Item key={item.key} style={{ fontSize: "1.5em" }}>
                      <span>{item.name}</span>
                      <Link to={item.url} />
                    </Menu.Item>
                  ))}
                </Menu>
              </Col>
            </Row>
          </Col>
        )} */}
      </Row>
    </>
  );
};

export default Navigation;
