import { useState, useEffect } from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';
import Fixtures from '../../../components/Fixtures/ProjectsFixtures';

import CardProject from '../../../components/Cards/CardProject';
import { Row, Col, Empty, Divider, Modal } from 'antd';
import { EuroOutlined } from '@ant-design/icons';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import Segmented from '../../../components/Segmented/Segmented';
import TableMarket from '../../../components/Tables/TableMarket';
import MarketHeader from '../../../components/MarketHeader/MarketHeader';

const Projects = (props) => {
  const [isList, setIsList] = useState(false);
  const [projects, setProjects] = useState([]);
  const [headerInfos, setHeaderInfos] = useState({
    total_invested: '...',
    total_amount: '...',
    total_projects: '...',
  });

  useEffect(() => {
    axios
      .get(`api/project/getAllProjects`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
        },
      })
      .then((res) => {
        setProjects(res.data.projects);
      })
      .catch((err) => console.log(err));

    axios
      .get(`api/project/marketHeader`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
        },
      })
      .then((res) => {
        setHeaderInfos(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="market-page">
      <Row align="left" justify="left" gutter={[16, 16]}>
        <Col align="left" justify="left" span={24}>
          <Segmented isList={isList} setIsList={setIsList} />
        </Col>

        <MarketHeader headerInfos={headerInfos}/>
        <Divider />
        {isList ? (
          <Col xs={24}>
            <TableMarket infos={projects} colors={props.colors} />
          </Col>
        ) : !projects.length ? (
          <Col align="center" justify="center" span="24">
            <Empty />
          </Col>
        ) : (
          projects.map((project) => (
            <Col
              key={'project-' + project.id}
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
            >
              <CardProject infos={project} colors={props.colors} />
            </Col>
          ))
        )}
      </Row>
    </div>
  );

};

export default Projects;
