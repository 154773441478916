import React from "react";

import "./LandPage.css";
import { useTranslation } from "react-i18next";

//import t from "../../Fixtures/allTextsLogOut";

import { Col, Row } from "antd";

const LandPage = () => {
  const { t, i18n } = useTranslation();



  return (
    <Row style={{}}>
     
      <div className="home-page__land-page__presentation">
        <h1 className="home-page__land-page__presentation__title">{t("home_page_title")}</h1>
        <div className="home-page__land-page__presentation__presentation">
          {t('home_page_description').split('\n').map(line=>
            <h4>{line}</h4>

          )}
        </div>
      </div>
    </Row>
  );
};

export default LandPage;
