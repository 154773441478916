

import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { UserOutlined } from '@ant-design/icons';
import axios from 'axios';
import {
  Row,
  Col,
  Menu,
  Dropdown,
  Button,
  List,
  Input,
  Grid,
  Drawer,
  Switch,
  Avatar, Card, Badge, Divider
} from "antd";
import notification from '../Notifications/Notification';
import {

  SmileOutlined,
  BellOutlined,
  BellTwoTone,
  DeleteTwoTone

} from "@ant-design/icons";



const NotificationsAvatar = (props) => {
  const { t, i18n } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);


  function deleteNotification(id, index) {

    let myToken = localStorage.getItem('bearer_token');

    var temp = [...notifications];
    temp.splice(index, 1);

    setNotifications(temp);

    axios.post("api/settings/deleteNotification", { id: id },
      { headers: { authorization: `Bearer ${myToken}` } }).then((response) => {
        if (response.data.status) {
          notification('success', 'Notification deleted', response.data.message); //TOTRANSLATE
        }
      }).catch((err) => {
        console.log(err);
        notification('error', 'Server error', err.response.data.message); //TOTRANSLATE


      });


  }

  function deleteAllNotifications() {

    let myToken = localStorage.getItem('bearer_token');

    var temp = [...notifications];
    temp.splice(0, temp.length);

    setNotifications(temp);

    axios.post("api/settings/deleteAllNotifications", { id: props.userId },
      { headers: { authorization: `Bearer ${myToken}` } }).then((response) => {
        if (response.data.status) {
          notification('success', 'Notifications deleted', response.data.message); //TOTRANSLATE
        }
      }).catch((err) => {
        console.log(err);
        notification('error', 'Server error', err.response.data.message); //TOTRANSLATE


      });


  }





  useEffect(() => {


    let myToken = localStorage.getItem('bearer_token');

    axios.post("api/settings/getNotifications", { userId: props.userId },
      { headers: { authorization: `Bearer ${myToken}` } }).then((response) => {

        setNotifications(response.data);

      }).catch((err) => {
        console.log(err);
        notification('error', 'Server error', err.response.data.message); //TOTRANSLATE


      });
  }, [visible]);




  return (


    <>
      <Badge count={notifications.length}> {/* Little symbol over the notif bell */}


        <Avatar onClick={() => { setVisible(true) }} style={{ background: props.colors.primary }} size='large' icon={<BellOutlined />} />

        {/* <Button  onClick={()=> setVisible(true)}  style={{color:'white'}} > Notifications</Button> */}

      </Badge>
      <Drawer
        title={t('keyword_notifications')}
        placement={'right'}
        closable={true}
        onClose={() => { setVisible(false) }}
        visible={visible}
        key={'left'}
      >
<Row align='center' justify='center' >
<Button onClick={()=>{deleteAllNotifications()}} >{t('keyword_clearall')}</Button>
</Row>
   

        <List
          itemLayout="horizontal"
          dataSource={notifications}
          renderItem={(item, index) => (
            <>
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar style={{ background: '#dddddd' }}  ><DeleteTwoTone style={{ fontSize: '110%' }}
                    twoToneColor='red' onClick={() => { deleteNotification(item.id, index) }} /></Avatar>
                  }
                  title={<><a style={{color:'#1890ff'}} href={item.url}>{item.title}</a> - <span>{item.date.toString().split('T')[0]}</span>  </> }
                  description={item.description}
                />

              </List.Item>


            </>
          )}
        />
      </Drawer>

    </>

  );
}

export default NotificationsAvatar;
