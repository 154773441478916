const texts = {

    en: {
        translation: {
            //-----Dashboard
            dashboard_top_card_title_1: `TOTAL INVESTMENT`,
            dashboard_top_card_title_2: `TOTAL PROFIT`,
            dashboard_top_card_title_3: `TRANSACTIONS DONE`,
            dashboard_top_card_title_4: `MY PROJECTS`,
            dashboard_timeline_transactions_title_1: `My yearly transactions`,
            dashboard_barchart_invested: `Invested`,
            dashboard_barchart_profit: `Profit`,
            //navbar
            navbar_my_investments: `My investments`,


            //-----Market
            market_title_1: `All realized projects`,
            market_header_money_invested_1: `Total money invested`,
            market_header_created_projects_1: `Created projects`,
            market_header_investment_number_1: `Total number of investments`,

            //-----Login
            login_title_1: `Sign in`,
            login_verification_email_1: `The input is not valid E-mail!`,
            login_verification_password_1: `Please input your password!`,
            login_remember_me_1: `Remember me`,
            login_forgot_password_1: `Forgot password`,
            login_register_now_1: `Register now`,

            //-----reset-password
            reset_password_send_email: `Send email`,

            //-----Register
            register_verifications_name_1: `2 characters minimum`,
            register_verifications_phone_1: `Input your Phone number`,
            register_verifications_password_1: `Password must be minimum 8 characters.`,
            register_verifications_password_2: `Both password are different`,
            register_gdpr_title: `Privacy informations`,
            register_gdpr_description: `This website protects your privacy by adhering to the European Union General Data Protection Regulation
        (GDPR). We will not use your data for any purpose that you do not consent to and only to the extent not
        exceeding data which is necessary in relation to a specific purpose(s) of processing. You can grant your
        consent(s) to use your data for specific purposes below or by clicking “Accept”.
        If you disagree, you can click on the red button "Refuse". As a result your account will not be created.`,
            //----- Settings
            settings_tab_1: `General informations`,
            settings_tab_2: `Security`,
            settings_tab_3: `Bank informations`,

            settings_generaltab_tooltip_firstname_1: `Your first legal name`,
            settings_generaltab_tooltip_lastname_1: `Your last legal name`,
            settings_generaltab_tooltip_phone_1: `Your Phone number`,
            settings_generaltab_tooltip_address_1: `Enter your house number + street`,

            settings_generaltab_title_1: `My personal informations`,
            settings_generaltab_subtitle_1: `User informations`,
            settings_generaltab_subtitle_2: `User Address`,

            settings_securitytab_old_password_1: `Current password`,
            settings_securitytab_new_password_1: `New password`,
            settings_securitytab_repeat_password1: `Repeat passsword`,

            settings_banktab_title_1: `My bank accounts`,
            settings_banktab_add_account_1: `Add bank account`,
            settings_banktab_popup_title_1: `Add a new bank account`,


            //-----Keywords
            keyword_picture: `Picture`,
            keyword_language: 'Language',
            keyword_ended: `Ended`,
            keyword_refuse: `Refuse`,
            keyword_thank_you: `Thank you`,
            keyword_accept: `Accept`,
            keyword_target: `Target`,
            keyword_repeat_password: `Repeat password`,
            keyword_reset_password: `Reset password`,
            keyword_or: `Or`,
            keyword_password: `Password`,
            keyword_back_login: `Back to login`,
            keyword_problem: `Problem`,
            keyword_public: `Public`,
            keyword_private: `Private`,
            keyword_submit: `Submit`,
            keyword_title: `Title`,
            keyword_description: `Description`,
            keyword_firstname: `First name`,
            keyword_lastname: `Last name`,
            keyword_city: `City`,
            'keyword_quarter': `Quarter`,

            keyword_current: `Current`,
            keyword_add: `Add`,

            keyword_address: `Address`,
            keyword_phone: `Phone number`,
            keyword_country: `Country`,
            keyword_duration: `Duration`,
            keyword_completion: `Completion`,
            keyword_annual_return: `Annual return`,
            keyword_open: `Open`,
            keyword_total: `Total`,
            keyword_minimum_amount: `Minimum amout`,
            keyword_yes: `Yes`,
            keyword_no: `No`,
            keyword_total_paid: `Total paid`,
            keyword_invest: `Invest`,
            keyword_investors: `Investors`,
            keyword_market: `Market`,
            keyword_dashboard: `Dashboard`,
            keyword_settings: `Settings`,
            keyword_admin: `Admin`,
            keyword_logout: `Log out`,
            keyword_login: `Log in`,
            keyword_email: `Email`,
            keyword_create_project: `Create project`,
            keyword_sign_in: `Sign in`,
            keyword_sign_up: `Sign up`,
            keyword_edit: `Edit`,
            keyword_verified: `Verified`,
            keyword_not_verified: `Not verified`,
            keyword_identity: `Identity`,
            keyword_my_rating: `My rating`,
            keyword_cancel: `Cancel`,
            keyword_clearall: `Clear all`,
            keyword_date_closed: `Date closed`,
            keyword_confirmation: `Confirmation`,
            keyword_load_more: `Load more`,
            keyword_not_found: `Not found`,
            keyword_users: `Users`,
            keyword_projects: `Projects`,
            keyword_transactions: `Transactions`,
            keyword_delete_user: `Delete user`,
            keyword_delete: `Delete`,
            keyword_view_edit: `View/Edit`,
            keyword_role: `Role`,
            keyword_active: `Active`,
            keyword_creation_date: `Creation date`,
            keyword_due_date: `Due date`,
            keyword_search: `Search`,
            keyword_reset: `Reset`,
            keyword_filter: `Filter`,
            keyword_step_forward: `Step forward`,
            keyword_step_back: `Step back`,
            keyword_remaining: `Remaining`,
            keyword_days: `Days`,
            keyword_money_received: `Money Received`,
            keyword_dates: `Dates`,
            keyword_action: `Action`,
            keyword_deleted_successfully: `Deleted successfully`,
            keyword_added_successfully: `Added successfully`,
            keyword_edited_successfully: `Edited successfully`,
            keyword_project_title: `Project title`,
            keyword_amount: `Amount`,
            keyword_to_target: `To target`,
            keyword_account: `Account`,
            keyword_contract_sent: `Contract sent`,
            keyword_approved: `Approved`,
            keyword_bank_account: `Bank account`,
            keyword_notifications: `Notifications`,
            keyword_location: `Location`,
            keyword_recording: `Recording`,
            keyword_promised: `Promised`,
            keyword_paid: `Paid`,
            keyword_newest: `Newest`,
            keyboard_welcome: `Welcome`,
            login_welcome_message_1: `Join us by logging in`,
            //popupProject
            popup_project_axios_edit_success: `The project has been updated successfully`,
            //Admin page
            admin_page_projects_confirmation_1: `Do you really want to delete this project?`,
            admin_page_transactions_confirmation_1: `Do you really want to delete this transaction?`,




            //----- Footer 
            footer_logged_in_title_1: `Created with love by PFG dev team`,

            //-----Session expired
            session_expired_title_1: `Session expired`,
            session_expired_subtitle_1: `Please click on the button bellow to go to the login page`,
            session_expired_button_1: `Go to login`,
            session_expired_question_1: `Why does it happen?`,
            session_expired_answer_1: `Each session is limited to 1 hour for security purpose`,

            //-----Create project
            new_project_main_start_end_title_1: `Opening and closing paying date`,
            new_project_main_start_end_subtitle_1: `Investors will be able to pay only from the start date 00:01 to the last day 23:59`,
            new_project_main_due_date_1: `Due date of the project`,
            new_project_main_necessary_rank_1: `Necessary rank`,
            new_project_main_ispublic_1: `Is this project public?`,

            new_project_pictures_title_1: `Max size 10Mo - 6 images maximum`,
            new_project_files_title_1: `Max size 10Mo - 6 files maximum`,
            new_project_preview_closing_date_1: `Closing of reservations`,

            create_project_verifications_main_1: `Error`,
            create_project_verifications_main_2: `Incorrect data`,
            create_project_verifications_main_3: `Missing informations`,
            create_project_verifications_title_1: `Title is needed -> Main informations tab`,
            create_project_verifications_description_1: `Description is needed -> Main informations tab`,
            create_project_verifications_annual_rate_1: `Annual rate is needed -> Main informations tab`,
            create_project_verifications_total_1: `Total expected is needed -> Main informations tab`,
            create_project_verifications_minimum_amount_1: `Minimal amount`,
            create_project_verifications_city_1: `City is needed -> Main informations tab`,
            create_project_verifications_parttner_1: `Partner rank is needed -> Main informations tab`,
            create_project_verifications_pictures_1: `You need at least 1 picture -> Pictures tab`,
            create_project_verifications_due_date_1: `Due date cannot be in the past -> Main informations tab`,
            create_project_verifications_due_date_closing_date_1: `Due date has to be after closing date -> Main informations tab`,
            create_project_verifications_start_date_1: `Start date must be before the closing date -> Main informations tab`,
            created_project_title_1: `Project successfully created!`,
            created_project_button_project_1: `Go to project view`,
            created_project_button_market_1: `Go to Market`,

            create_project_main_tab: `General`,
            create_project_pictures_tab: `Pictures`,
            create_project_files_tab: `Files`,
            create_project_preview_tab: `Preview`,
            //-----Project
            project_header_1: `Back to market`,
            project_subheader_1: `All projects`,
            project_invest_popup_title_1: `Make an investment request`,
            project_invest_popup_choose_bank_1: `Choose your bank account`,
            project_invest_popup_choose_currency_1: `Choose your currency`,
            project_opening_reservation: `Opening of reservations`,
            project_reservation_period_ended_1: `The rezervation period has ended`,
            project_investment_created_1: `Your investment request has been sent successfuly`,
            project_investment_period_closed: `The investment period is closed`,
            project_investment_no_investment: `You cannot invest`,

            //-----Card project
            card_project_click_open_1: `Click on the card to open`,



            //Admin page
            admin_delete_project_text_1: `Do you really want to delete this transaction ?`,





            //  LOGGED OUT -----------------

            home_page_title: 'Investment. Reality. Construction.',
            home_page_description: 'Investments in residential real estate throughout the Czech Republic, including purchase, management and possible reconstruction for subsequent sale or rental.',
            home_page_project_title: 'ONE OF OUR SUCCESSFUL PROJECT',
            home_page_creator_investor_creator_title: 'BECOME A CREATOR',
            home_page_creator_investor_creator_text_1:
                'Do you run a business and raise funds?',
            home_page_creator_investor_creator_text_2:
                'Are you starting a new project and looking for an investor?',
            home_page_creator_investor_creator_text_3:
                'Have you been turned down by the bank?',
            home_page_creator_investor_investor_title: 'BECOME AN INVESTOR',
            home_page_creator_investor_investor_text_1:
                'Are you an investor looking for an investment opportunity?',
            home_page_creator_investor_investor_text_2:
                'Do you want more than a low return on conservative investments?',
            home_page_creator_investor_investor_text_3:
                'Do you refuse to invest your capital in risky and unsecured investments?',
            home_page_creator_investor_click_button: 'Click to discover more',
            home_page_list_projects_title: 'Some Projects',
            home_page_list_see_more: 'See More',

            // Projects
            projects_title: 'All realized projects',
            projects_money_invested: 'Total money invested',
            projects_created_projects: 'Created projects',
            projects_title_number_invest: 'Total number of investments',
            projects_subtitle: 'Some Projects',
            projects_see_more: 'Load More',

            // Contact
            contact_details_company_title: 'Company',
            contact_details_business_hours_title: 'Business Hours',
            contact_details_business_hours_text_1: 'Monday-Friday: 09.00 - 17.00',
            contact_details_business_hours_text_2: 'Saturday: Closed',
            contact_details_business_hours_text_3: 'Sunday: Closed',
            contact_details_contact_us: 'Contact Us',
            contact_details_bank_details: 'Bank Details',
            contact_details_bank_code: 'Registration code',
            contact_form_title: 'Contact us',
            contact_form_name: 'Your name',
            contact_form_address: 'Your email address',
            contact_form_message: 'Your message',
            contact_form_button: 'Send Message',
            contact_form_email_sent: `Thank you. Your message has been sent to us successfully.`,
            // Manual
            manual_title: 'What you would like to do?',
            manual_client_title: 'I want to be a client',
            manual_client_description: "I'd like to create a project",
            manual_partner_title: 'I want to be a partner',
            manual_partner_description: "I'd like to invest in projects",
            manual_invest_title: 'Now you can invest!',
            manual_invest_now: 'And now what?',

            // Menu
            menu_home: 'Home',
            menu_manual: 'Manual',
            menu_questions: 'Q&A',
            menu_contact: 'Contact',
            menu_projects: 'Projects',
            menu_sign: 'SIGN IN',
            menu_register: 'JOIN US',

            // Footer
            footer_links_title: 'Important Links',
            footer_links_manual: 'Main page',
            footer_links_main_page: 'How does it work',
            footer_links_FAQ: 'FAQ',
            footer_links_contact: 'Contact',
            footer_links_login: 'Login',
            footer_links_registration: 'Registration',
            footer_documents_title: 'Documents to download',
            footer_documents_text_1: 'SAMPLE - Administration contract',
            footer_documents_text_2: 'SAMPLE - Framework contract',
            footer_documents_text_3: 'SAMPLE - Loan agreement',
            footer_documents_text_4: 'General Terms and Conditions',
            footer_documents_text_5: 'Terms of user registration',
            footer_documents_text_6: 'Principles of personal data processing',

        }

    },
    cz: {
        translation: {
            dashboard_top_card_title_1: `CELKOVÉ INVESTICE`,
            dashboard_top_card_title_2: `CELKOVÉ ZISKY`,
            dashboard_top_card_title_3: `PROVEDENÉ TRANSAKCE`,
            dashboard_top_card_title_4: `MOJE PROJEKTY`,
            dashboard_timeline_transactions_title_1: `Moje roční transakce`,

            dashboard_barchart_invested: `Investováno`,
            dashboard_barchart_profit: `Zisk`,
            //navbar
            navbar_my_investments: `Moje investice`,


            //-----Market

            market_title_1: `Všechny zrealizované projekty`,
            market_header_money_invested_1: `Investované peníze celkem`,
            market_header_created_projects_1: `Vytvořených projektů`,
            market_header_investment_number_1: `Celkový počet investic`,

            //-----Login
            login_title_1: `Přihlásit se`,
            login_verification_email_1: `Zadaný email není platný`,
            login_verification_password_1: `Prosím zadejte vaše heslo`,
            login_remember_me_1: `Zapamatovat`,
            login_forgot_password_1: `Zapomněli jste heslo?`,
            login_register_now_1: `Zaregistrovat`,

            //-----reset-password
            reset_password_send_email: `Odeslat email`,

            //-----Register
            register_verifications_name_1: `Minimálně 2 znaky`,
            register_verifications_phone_1: `Zadejte vaše telefonní číslo`,
            register_verifications_password_1: `Heslo musí mít alespoň 8 znaků`,
            register_verifications_password_2: `Hesla se neshodují`,
            register_gdpr_title: `Ochrana soukromí`,
            register_gdpr_description: `Tato stránka chrání vaše soukromí v souladu s GDPR. Nepoužijeme Vaše vaše data k jinému účelu než za účelem používání tohoto webu a pouze s daty nezbytnými k provedení úkonů souvisejících s tímto webem. Prosím potvrďte Váš souhlas kliknutím na "Akceptovat". Pokud nesouhlasíte, klikněte na "Odmítnout" a vaše registrace neproběhne.`,
            //----- Settings
            settings_tab_1: `Obecné informace`,
            settings_tab_2: `Zabezpečení`,
            settings_tab_3: `Platební údaje`,

            settings_generaltab_tooltip_firstname_1: `Jméno`,
            settings_generaltab_tooltip_lastname_1: `Příjmení `,
            settings_generaltab_tooltip_phone_1: `Vaše telefonní číslo`,
            settings_generaltab_tooltip_address_1: `Zadejte vaši adresu a číslo domu `,

            settings_generaltab_title_1: `Mé osobní informace`,
            settings_generaltab_subtitle_1: `Uživatelské informace`,
            settings_generaltab_subtitle_2: `Uživatelova adresa`,

            settings_securitytab_old_password_1: `Současné heslo`,
            settings_securitytab_new_password_1: `Nové heslo`,
            settings_securitytab_repeat_password1: `Nové heslo ještě jednou`,

            settings_banktab_title_1: `Moje bankovní účty`,
            settings_banktab_add_account_1: `Přidat bankovní účet`,
            settings_banktab_popup_title_1: `Přidat nový bankovní účet`,

            //-----Keywords
            keyword_picture: `Obrázek`,
            keyword_remaining: `Zbývající`,
            keyword_submit: `Potvrdit`,
            keyword_title: `Nadpis`,
            keyword_description: `Popis`,
            keyword_language: 'Jazyk',

            keyword_firstname: `Jméno`,
            keyword_lastname: `Příjmení`,
            keyword_city: `Město`,
            keyword_ended: `skončilo`,
            keyword_refuse: `Odmítnout`,
            keyword_thank_you: `Děkuji`,
            keyword_accept: `Akceptovat`,
            keyword_target: `Cílová`,
            keyword_repeat_password: `Zopakovat heslo`,
            keyword_reset_password: `Obnovit heslo`,
            keyword_or: `Nebo`,
            keyword_newest: `Nejnovější`,
            keyword_password: `Heslo`,
            'keyword_quarter': `čtvrtletí`,
            keyword_back_login: `Zpět k přihlášení`,
            keyword_problem: 'Problém',
            keyword_public: `Veřejnost`,
            keyword_private: `Soukromé`,
            keyword_address: `Adresa`,
            keyword_phone: `Telefonní číslo`,
            keyword_country: `Země`,
            keyword_duration: `Doba trvání`,
            keyword_completion: `Ukončení`,
            keyword_annual_return: `Roční návratnost`,
            keyword_open: `Otevřeno`,
            keyword_total: `Celkem`,
            keyword_minimum_amount: `Minimum`,
            keyword_yes: `Ano`,
            keyword_no: `Ne`,
            keyword_total_paid: `Celkem zaplaceno`,
            keyword_invest: `Investovat`,
            keyword_create_project: `Vytvořit projekt `,
            keyword_investors: `Investoři`,
            keyword_market: `Trh`,
            keyword_dashboard: `Přehled`,
            keyword_settings: `Nastavení`,
            keyword_admin: `Správce`,
            keyword_logout: `Odhlásit`,
            keyword_login: `Přihlásit`,
            keyword_email: `Email`,
            keyword_sign_in: `Přihlásit se`,
            keyword_sign_up: `Zaregistrovat se`,
            keyword_edit: `Editovat`,
            keyword_verified: `Ověřená`,
            keyword_not_verified: `Neověřená`,
            keyword_identity: `Identita`,
            keyword_my_rating: `Můj rating`,
            keyword_cancel: `Zrušit`,
            keyword_clearall: `Vše zrušit`,
            keyword_date_closed: `Datum uzavření`,
            keyword_load_more: `Načíst další`,
            keyword_users: `Uživatelů`,
            keyword_projects: `Projekty`,
            keyword_transactions: `Transakce`,
            keyword_not_found: `Nenalezeno`,
            keyword_confirmation: `Potvrzení`,
            keyword_delete: `Vymazat`,
            keyword_view_edit: `Zobrazit/Upravit`,
            keyword_role: `Role`,
            keyword_active: `Aktivní`,
            keyword_creation_date: `Datum vzniku`,
            keyword_due_date: `Datum splatnosti`,
            keyword_delete_user: `Smazat uživatele`,
            keyword_search: `Vyhledávání`,
            keyword_reset: `Resetovat`,
            keyword_filter: `Filtr`,
            keyword_dates: `Termíny`,
            keyword_action: `Akce`,
            keyword_deleted_successfully: `Úspěšně smazáno`,
            keyword_added_successfully: `Úspěšně přidáno`,
            keyword_edited_successfully: `Úspěšně upraveno`,
            keyword_project_title: `Název projektu`,
            keyword_amount: `Množství`,
            keyword_to_target: `K dosažení cíle`,
            keyword_account: `Účet`,
            keyword_contract_sent: `Smlouva odeslána`,
            keyword_approved: `Schválený`,
            keyword_money_received: `Peníze přijaty`,
            keyword_bank_account: `Bankovní účet`,
            keyword_notifications: `Oznámení`,
            keyword_current: `Aktuální`,
            keyword_promised: `Objednávky`,
            keyword_paid: `Zaplaceno`,
            keyword_location: `Umístění`,
            keyword_recording: `Záznam`,
            keyboard_welcome: `Vítejte`,
            keyword_step_forward: `Potvrdit další krok`,
            keyword_step_back: `Krok zpět`,
            keyword_days: `Dní`,
            keyword_add: `Přidat`,
            login_welcome_message_1: `Přidejte se k nám přihlášením`,


            //error page

            //popupProject
            popup_project_axios_edit_success: `Projekt byl úspěšně aktualizován`,
            //Admin page projects
            admin_page_projects_confirmation_1: `Opravdu chcete smazat tento projekt?`,
            admin_page_transactions_confirmation_1: `Opravdu chcete smazat tuto transakci?`,

            //----- Footer 
            footer_logged_in_title_1: `Created with love by PFG dev team`,

            //-----Session expired
            session_expired_title_1: `Raději jsme vás odhlásili`,
            session_expired_subtitle_1: `Prosím klikněte na tlačítko níže pro opětovné přihlášení`,
            session_expired_button_1: `Přejít na přihlášení`,
            session_expired_question_1: `Proč se to stalo?`,
            session_expired_answer_1: `Každé přihlášení je z bezpečnostních důvodů omezeno na jednu hodinu`,

            //-----Create project
            new_project_main_start_end_title_1: `Počáteční a konečné datum pro investování`,
            new_project_main_start_end_subtitle_1: `Investoři budou moci investovat pouze rozmezí od 00:01 prvního dne do 23:59 posledního dne`,
            new_project_main_due_date_1: `Datum splatnosti projektu`,
            new_project_main_necessary_rank_1: `Potřebný rating`,
            new_project_main_ispublic_1: `Má být tento projekt veřejný na hlavní straně bez přihlášení?`,

            new_project_pictures_title_1: `Max velikost 10MB – Maximálně 6 obrázků`,
            new_project_files_title_1: `Max velikost 10MB – Maximálně 6 obrázků`,
            new_project_preview_closing_date_1: `Uzávěrka rezervací `,

            create_project_verifications_main_1: `Chyba`,
            create_project_verifications_main_2: `Chybná data`,
            create_project_verifications_main_3: `Chybějící informace`,
            create_project_verifications_title_1: `Je třeba zadat název -> Tab Hlavní informace`,
            create_project_verifications_description_1: `Je třeba zadat popis -> Tab Hlavní informace`,
            create_project_verifications_annual_rate_1: ` Je třeba zadat roční úrok -> Tab Hlavní informace`,
            create_project_verifications_total_1: `Je třeba zadat kolik je celkem očekáváno -> Tab Hlavní informace`,
            create_project_verifications_minimum_amount_1: `Je třeba zadat minimum -> Tab Hlavní informace`,
            create_project_verifications_city_1: `Je třeba zadat město -> Tab Hlavní informace`,
            create_project_verifications_parttner_1: `Je třeba zadat rating -> Tab Hlavní informace`,
            create_project_verifications_pictures_1: `Nahrajte alespoň jeden obrázek -> Tab Hlavní informace`,
            create_project_verifications_due_date_1: `Datum splatnosti nemůže být v minulosti -> Tab Hlavní informace`,
            create_project_verifications_due_date_closing_date_1: `Datum splatnosti musí být po datu uzávěrky -> záložka Hlavní informace`,

            create_project_main_tab: `Všeobecné`,
            create_project_pictures_tab: `Obrázky`,
            create_project_files_tab: `Soubory`,
            create_project_preview_tab: `Náhled`,

            create_project_verifications_start_date_1: `Počáteční datum musí být před konečným datem -> Tab Hlavní informace`,
            created_project_title_1: `Projekt byl úspěšně vytvořen!`,
            created_project_button_project_1: `Přejít do seznamu projektů `,
            created_project_button_market_1: `Přejít do trhu`,


            //-----Project
            project_header_1: `Zpět na trh`,
            project_subheader_1: `Všechny projekty`,
            project_invest_popup_title_1: `Požádejte o investici`,
            project_invest_popup_choose_bank_1: `Vyberte svůj bankovní účet`,
            project_invest_popup_choose_currency_1: `Vyberte si měnu`,
            project_opening_reservation: `Začátek rezervací`,
            project_reservation_period_ended_1: `Doba rezervace skončila`,
            project_investment_created_1: `Vaše žádost o investici byla úspěšně odeslána`,
            project_investment_period_closed: `Investiční období je uzavřeno`,
            project_investment_no_investment: `Nemůžete investovat`,

            //-----Card project
            card_project_click_open_1: `Kliknutím na kartu otevřete`,


            //-----Admin
            admin_delete_project_text_1: `Chcete tuto transakci opravdu smazat?`,


            //LOGGED OUT-----------
            home_page_title: 'ZAJIŠTĚNÁ INVESTICE JINAK',
            home_page_description: `Investice do rezidenčních nemovitostí po celé ČR včetně koupě, správy a případné rekonstrukce za účelem následného prodeje či pronájmu. Investice do zajištěných podnikatelských úvěrů do 50% LTV, financujeme bezpečné projekty s jistým zajištěním! Financujeme výstavbu, zpětné výkupy a podnikatelské zajištěné úvěry, které mají smysl. 
Jsme PFG vaše jistota.
Jedna registrace a zbytek zařídíme za vás! Vysoké zhodnocení, zajištěné zástavním právem a bez zprostředkovatelů. Jediný rozdíl je provize, založili jsme investiční fond, který funguje na platformě a nevyplácíme provize zprostředkovatelům oslepeným ziskem provize! Žádné sliby, žádné lži jen fakta. Obchod má vstupní investici x, výstup y a čas je z. Jednoduchá rovnice, které každý rozumí: Zisk =  x-y-z.

`,
            home_page_project_title: `Jeden z našich úspěšných projektů`,
            home_page_creator_investor_creator_title: 'stát se tvůrcem',
            home_page_creator_investor_creator_text_1: `Podnikáte a získáváte finanční prostředky?`,
            home_page_creator_investor_creator_text_2: `Začínáte nový projekt a hledáte investora?`,
            home_page_creator_investor_creator_text_3: `Byli jste odmítnuti bankou?`,
            home_page_creator_investor_investor_title: `STÁT SE INVESTOREM?`,
            home_page_creator_investor_investor_text_1: `Jste investor, který hledá investiční příležitost?`,
            home_page_creator_investor_investor_text_2: `Chcete více od svých konzervativních investic?`,
            home_page_creator_investor_investor_text_3: `Odmítáte investovat svůj kapitál do rizikových a nezajištěných investic?`,
            home_page_creator_investor_click_button: `Kliknutím objevíte více`,
            home_page_list_projects_title: `Vybrané projekty`,
            home_page_list_see_more: `Ukázat víc`,

            // Projects
            projects_title: `Všechny realizované projekty`,
            projects_money_invested: `Celkem investované peníze`,
            projects_created_projects: `vytvořené projekty`,
            projects_title_number_invest: `celkový počet investic`,
            projects_subtitle: `Vybrané projekty`,
            projects_see_more: `Načíst další`,

            // Contact
            contact_details_company_title: `Společnost`,
            contact_details_business_hours_title: `Pracovní doba`,
            contact_details_business_hours_text_1: `pondělí - pátek: 09.00 - 17.00`,
            contact_details_business_hours_text_2: `Sobota: zavřeno`,
            contact_details_business_hours_text_3: `Neděle: zavřeno`,
            contact_details_contact_us: `Kontaktujte nás`,
            contact_details_bank_details: `Bankovní detaily`,
            contact_details_bank_code: `Registrační kod`,
            contact_form_title: `Kontaktujte nás`,
            contact_form_name: `Vaše jmeno`,
            contact_form_address: `Vaše emailová adresa`,
            contact_form_message: `Vaše zpráva`,
            contact_form_button: `Odeslat zprávu`,
            contact_form_email_sent: `Děkujeme, váš email byl v pořádku odeslán.`,

            // Manual
            manual_title: `Chci investovat`,
            manual_client_title: `Chci investovat`,
            manual_client_description: `Chci vytvořit projekt`,
            manual_partner_title: `Chci být partner`,
            manual_partner_description: `Chci investovat do projektů`,
            manual_invest_title: `Nyní můžete investovat!`,
            manual_invest_now: `A co teď?`,
            manual_list_cards_card_1_title: 'Registrace',
            manual_list_cards_card_1_text:
                'Tento první krok Vám umožní přístup k projektům. Registrace je zdarma.',
            manual_list_cards_card_2_title: 'Nabídka projektu',
            manual_list_cards_card_2_text:
                'Vyberte si projekt který vás zajímá a získejte potřebnou dokumentaci na financování.',
            manual_list_cards_card_3_title:
                'Platební proces',
            manual_list_cards_card_3_text:
                'Projekt je připraven k realizaci. Vyzveme vás k zaslání platby.',
            manual_list_cards_card_4_title: 'Zajištění',
            manual_list_cards_card_4_text:
                'Toto je nejdůležitější část pro investora. Veškeré fin. prostředky jsou do 100% zajištěné zástavním právem na nemovitý majetek dlužníka.',
            manual_list_cards_card_5_title: 'Dlužník nesplnil podmínky',
            manual_list_cards_card_5_text:
                'Prodání zajištění a vrácena jistina včetně výnosu na účet investora.',
            manual_list_cards_card_6_title: 'Zisk - Úspěšné splnění podmínek',
            manual_list_cards_card_6_text:
                'Investice proběhla v pořádku. Jistina a výnosy z úroků jsou automaticky zaslány na účet investora.',
            manual_list_cards_card_7_title: 'Spokojenost',
            manual_list_cards_card_7_text: ' Spolupracujte na dalších zajištěných.',

            // Menu
            menu_home: `Domů`,
            menu_manual: `Manuál`,
            menu_questions: `Otázky a odpovědi`,
            menu_contact: `Kontakt`,
            menu_projects: `Projekty`,
            menu_sign: `Přihlásit se`,
            menu_register: `Registrace`,

            // Footer
            footer_links_title: `Důležité odkazy`,
            footer_links_manual: `Hlavní stránka`,
            footer_links_main_page: `Jak to funguje?`,
            footer_links_FAQ: `často kladené otázky`,
            footer_links_contact: `Kontakt`,
            footer_links_login: `Přihlášení`,
            footer_links_registration: `Registrace`,
            footer_documents_title: `Soubory ke stažení`,
            footer_documents_text_1: `VZOR - Smlouva o správě`,
            footer_documents_text_2: `VZOR - rámcová smlouva`,
            footer_documents_text_3: `VZOR -Smlouva o půjčce`,
            footer_documents_text_4: `Všeobecné obchodní podmínky`,
            footer_documents_text_5: `Podmínky registrace uživatele`,
            footer_documents_text_6: `Zásady zpracování osobních údajů`,

        }
    }




}



export default texts;