
import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
    Row,
    Col,
    Card,
    Avatar,
    Button,
    Progress,
    Tooltip,
    Tabs,
    Form,
    Select,
    Typography,
    Input,
    Table,


} from "antd";
import notification from "../Notifications/Notification";
import styled from 'styled-components';

import { EuroOutlined } from '@ant-design/icons';
import {
    FacebookOutlined,
    TwitterOutlined,
    InstagramOutlined,
    VerticalAlignTopOutlined,
} from "@ant-design/icons";
const { TabPane } = Tabs;


const inpuStyle = {
  height: '40px', borderRadius: '10px'
}


const SecurityInfos = (props) => {
    const { t, i18n } = useTranslation();

    const [form] = Form.useForm();


    const onFinish = () => {


        let fields = form.getFieldsValue();
        

        if (fields.password !== fields.passwordConfirm) {
            notification('error', 'Passwords not matching', 'Your new passwords are different, please type them again'); //TOTRANSLATE
            form.setFieldsValue({
                // password: ''
            });
        } else {


            axios
                .post(`api/user/editPassword`, { oldPassword: fields.oldPassword, newPassword: fields.password }, { headers: { authorization: `Bearer ${localStorage.getItem('bearer_token')}` } })
                .then((res) => {
                    notification('success', 'Password edited successfully', res.data.message);//TOTRANSLATE
                })
                .catch((err) => {
                    console.log(err.response.data.msg);

                    notification('error', 'Login error', err.response.data.msg);//TOTRANSLATE
                });

        }




    };


    useEffect(() => {

    }, []);

    function enableButton() {

        if (form.getFieldsValue().password && form.getFieldsValue().password.length) {
            return true;
        }
        return false;
    }

    return (
        <Card style={{borderRadius: '15px'}}>

            <Form form={form} layout="vertical"
                name="horizontal_login"
                onFinish={onFinish}

            >

                <Row justify="start" gutter={[24, 24]} >
                    <Col span='24'>
                        <Form.Item
                            label={t('settings_securitytab_old_password_1')}
                            name="oldPassword"
                            rules={[
                                {required: true, min: 8, message: t('login_verification_password_1') }
                            ]}
                        >
                            <Input.Password style={inpuStyle} />
                        </Form.Item>


                    </Col>
                </Row>
                <Row justify="start" gutter={[24, 24]} >
                    <Col xs={24} md={12} >
                        <Form.Item
                            label={t('settings_securitytab_new_password_1')}
                            name="password"
                            rules={[
                                {required: true, min: 8, message: t('login_verification_password_1') }
                            ]}
                        >
                            <Input.Password style={inpuStyle}  />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12} >
                        <Form.Item
                            label={t('settings_securitytab_repeat_password1')
                            }
                            name="passwordConfirm"
                            rules={[
                                {required: true, min: 8, message: t('login_verification_password_1') },
                            ]}
                        >
                            <Input.Password style={inpuStyle}  />
                        </Form.Item>
                        <Form.Item
                
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="forgot_password-form-button"
                    style={{
                      borderRadius: '10px',
                      background: 'green',
                      color: 'white',
                      border: '#003464',
                      height: '40px',
                      width: '100px',
                    
                    }}
                  >
                      {t('keyword_submit')}
                  </Button>

                </Form.Item>
                    </Col>
                </Row>



            </Form>
          

        </Card>
    );
};

export default SecurityInfos;
