import React from 'react';

import { Card, Col, Row, Steps } from 'antd';
import {
  CheckOutlined,
  CrownOutlined,
  LockOutlined,
  RocketOutlined,
  SearchOutlined,
  StopOutlined,
  UserOutlined
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import CardStep from '../../../components/Cards/CardStep';
import CardProject from '../../../components/Cards/CardProject';

import './Manual.css';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manualOpen: null,
      stepsList: [
        {
          index: 1,
          position: 'right',
          title: this.props.t('manual_list_cards_card_1_title'),
          text: this.props.t('manual_list_cards_card_1_text'),
          icon: <RocketOutlined style={{ color: '#FFF', fontSize: '6em' }} />,
          line: true,
        },
        {
          index: 2,
          position: 'left',
          title: this.props.t('manual_list_cards_card_2_title'),
          text: this.props.t('manual_list_cards_card_2_text'),
          icon: <SearchOutlined style={{ color: '#FFF', fontSize: '6em' }} />,
          line: true,
        },
        {
          index: 3,
          position: 'right',
          title: this.props.t('manual_list_cards_card_3_title'),
          text: this.props.t('manual_list_cards_card_3_text'),
          icon: <CheckOutlined style={{ color: '#FFF', fontSize: '6em' }} />,
          line: true,
        },
        {
          index: 4,
          position: 'left',
          title: this.props.t('manual_list_cards_card_4_title'),
          text: this.props.t('manual_list_cards_card_4_text'),
          icon: <LockOutlined style={{ color: '#FFF', fontSize: '6em' }} />,
          line: true,
        },
      ],
    };

    this.openManual = this.openManual.bind(this);
  }

  openManual(side) {
   
    this.setState({
      manualOpen: side,
    });
  }

  render() {
    const Step = [
      {
        step: 1,
        name: '',
        description: '',
      },
    ];

    const {t, i18n } = this.props;


    return (
      <Row className="manual" gutter={[20, 20]} style={{ margin: 0 }}>
        <Col
          xs={24}
          style={{ fontSize: '3em', color: '#003464', fontWeight: 'bold' }}
        >
          {t('manual_title')}
        </Col>
        <Row style={{ width: '100vw' }}>
          {/* <Col xs={12}>
            <Card
              hoverable
              style={{
                backgroundColor: '#003464',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '10px',
              }}
            >
              <a
                href="#client"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  color: '#FFF',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#FFF',
                    fontSize: '2em',
                  }}
                >
                  {t.cz.manual_client_title}
                </span>
                <br /> {t.cz.manual_client_description}
              </a>
            </Card>
          </Col> */}
          {/* <Col xs={12}>
            <Card
              hoverable
              style={{ backgroundColor: '#f06925', borderRadius: '10px' }}
            >
              <a
                href="#partner"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  color: '#fff',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '2em',
                  }}
                >
                  {t.cz.manual_partner_title}
                </span>
                <br /> {t.cz.manual_partner_description}
              </a>
            </Card>
          </Col> */}
        </Row>
        <Col xs={24} style={{ marginTop: '0px' }}>
          <div name="client" id="client">
            <Row>
              {this.state.stepsList.map((oneStep) => (
                <Col xs={24}>
                  <CardStep
                    position={oneStep.position}
                    step={oneStep.index}
                    title={oneStep.title}
                    description={oneStep.text}
                    index={oneStep.index}
                    icon={oneStep.icon}
                    line={oneStep.line}
                    fullPage="true"
                  />
                </Col>
              ))}
            <div
              style={{
                margin: '0 auto 0 auto',
                display: 'inline-block',
                width: '50%',
                backgroundColor: '#000',
                height: '5px',
                borderRadius: "10px"
              }}
            ></div>
            </Row>
          </div>
        </Col>

        <Col xs={24}>
          <Row justify="space-around">
            <Col xs={24} lg={12}>
              <CardStep
                position="left"
                step="5"
                title={t('manual_list_cards_card_5_title')}
                description={t('manual_list_cards_card_5_text')}
                index="5"
                icon={
                  <StopOutlined style={{ color: '#FFF', fontSize: '6em' }} />
                }
                line={false}
                upperLine={true}
                fullPage="false"
              />
            </Col>
            <Col xs={24} lg={12}>
              <CardStep
                position="right"
                step="5"
                title={t('manual_list_cards_card_6_title')}
                description={t('manual_list_cards_card_6_text')}
                index="5"
                icon={
                  <CrownOutlined style={{ color: '#FFF', fontSize: '6em' }} />
                }
                line={true}
                upperLine={true}
                fullPage="false"
              />
               <CardStep
                position="right"
                step="6"
                title={t('manual_list_cards_card_7_title')}
                description={t('manual_list_cards_card_7_text')}
                index="6"
                icon={
                  <UserOutlined  style={{ color: '#FFF', fontSize: '6em' }} />
                }
                line={false}
                upperLine={false}
                fullPage="false"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(HomePage);
