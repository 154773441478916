import React, { Component, useState, useEffect, createContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
// CSS IMPORT
import './Main.css';
import COLORS from '../Tools/Colors';
import routes from './Routes';
// VIEWS IMPORT LOGOUT
import Contact from '../views/logOut/Contact/Contact';
import HomePage from '../views/logOut/HomePage/HomePage';
import Login from '../views/logOut/Auth/Login';
import Manual from '../views/logOut/Manual/Manual';
import Projects from '../views/logOut/Projects/Projects';
import QuestionsAnswers from '../views/logOut/QuestionsAnswers/QuestionsAnswers';
import LoggedMain from './LoggedMain';
import Project from '../views/logOut/Projects/Project';
import Translator from '../components/Translator/Translator';
// VIEWS IMPORT LOGIN

// COMPONENTS IMPORT
import FooterPage from '../components/Footer/LogOutFooterPage';
import Navigation from '../components/Header/LogOutNavigation';

// ANTD IMPORT
import { Layout, Button, Menu, Avatar, BackTop, Drawer, Row, Col } from 'antd';
import 'antd/dist/antd.min.css';
import Register from '../views/logOut/Auth/Register';
import PrivateRoute from './PrivateRoute';
import NewProject from '../views/logIn/NewProject/NewProject';
import PasswordReset from '../views/logOut/Auth/PasswordReset';

const { Content, Footer, Header, Sider } = Layout;

class MainClient extends Component {
  state = {};

  render() {
    var url = window.location.href;

    return (
      <Router>
        <Layout
          className="layout"
          style={{
            minHeight: '100vh',
            background: COLORS.background,
          }}
        >
          {!url.includes('/app/') ? (
            <>
              <Header
                className="navigation"
                style={{
                  background: '#fff',
                }}
              >
                <Navigation />
              </Header>

              <Routes>
                {/* ================================================== */}
                {/* LOGOUT ROUTES */}
                <Route path="/" element={<HomePage />} />
                <Route path="/manual" element={<Manual />} />
                <Route path="/Q&A" element={<QuestionsAnswers />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/project" element={<Project colors={COLORS} />} />
                <Route path="/login" element={<Login colors={COLORS} />} />
                <Route
                  path="/reset-password"
                  element={<PasswordReset colors={COLORS} />}
                />

                <Route
                  path="/register"
                  element={<Register colors={COLORS} />}
                />

                {/* While error with routes */}
                {/* <Route path="/error/404" element={<Error status='404' title='404' />} />  */}
                <Route
                  path="/app/*"
                  element={<Navigate to="/app/dashboard" />}
                />
                <Route path="/*" element={<Navigate to="/" />} />
                {/* ================================================== */}

                {/* ================================================== */}
                {/* LOGIN ROUTES */}

                {/* ================================================== */}
              </Routes>

              <Footer style={{ backgroundColor: '#f0f0f0' }}>
                <FooterPage />{' '}
              </Footer>
            </>
          ) : (
            <LoggedMain />
          )}
        </Layout>
      </Router>
    );
  }
}
export default MainClient;
