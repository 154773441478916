import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import printPrice from '../../Tools/printPrice';
import partner1 from '../../images/partner1.svg';
import {
  Avatar,
  Badge,
  Radio,
  Divider,
  Skeleton,
  InputNumber,
  Slider,
} from 'antd';
import './cards.css';
import axios from 'axios';
import {
  Row,
  Col,
  Menu,
  Dropdown,
  Modal,
  Card,
  Button,
  Progress,
  Rate,
  Space,
} from 'antd';
import notification from '../Notifications/Notification';
import {
  SmileOutlined,
  BellOutlined,
  SettingOutlined,
} from '@ant-design/icons';

const CardProjectDetails = (props) => {
  const { t, i18n } = useTranslation();

  const [openInvestmentPopup, setOpenInvestmentPopup] = useState(false);
  const [transaction, setTransaction] = useState({
    amount: 0,
    bank_account: null,
    currency_id: 1,
  });
  const [myBankAccounts, setMyBankAccounts] = useState([]);

  const {
    title,
    total_paid,
    annual_rate,
    total_promised,
    due_date,
    partner,
    target,
    start_date,
    minimum_amount,
    is_public,
    closing_date,
    creation_date,
    city,
    partner_id,
  } = props.project;

  function getMonthsDaysDifference(end_date) {
    let date1 = new Date(end_date);
    let date2 = new Date();

    if (date1.getTime() < date2.getTime()) {
      return t('project_reservation_period_ended_1');
    }
    var diff = Math.floor(date1.getTime() - date2.getTime());
    var day = 1000 * 60 * 60 * 24;

    var days = Math.floor(diff / day);
    var months = Math.floor(days / 31);
    var years = Math.floor(months / 12);

    var message = date2.toDateString();
    message += ' is in  ';
    message += days + ' ' + t('keyword_days');
    message += months + ' months ';
    message += years + ' years ago \n';

    if (years > 0) {
      return (
        years + ' years and ' + months + ' months ' + t('keyword_remaining')
      );
    } else if (months > 0) {
      return (
        months +
        ' months and ' +
        (days % 30) +
        ' ' +
        t('keyword_days') +
        ' ' +
        t('keyword_remaining')
      );
    } else if (days > 0) {
      return days + ' ' + t('keyword_days') + ' ' + t('keyword_remaining');
    }

    return t('keyword_date_closed');
  }

  function sendInvestmentRequest() {
    if (!transaction.bank_account) {
      notification(
        'warning',
        'No bank account selected',
        'Go to Settings -> Payment tab -> Add new card'
      ); //TOTRANSLATE
      return false;
    }
    if (!transaction.currency_id) {
      notification('warning', 'Bad Currency', 'You must choose a currency'); //TOTRANSLATE
      return false;
    }
    if (props.is_preview) {
    
      return false;
    }
    if (transaction.amount > target - total_promised) {
      notification('error', 'Impossible action', 'Neplatná částka peněz'); //TOTRANSLATE
      return false;
    }

    let myToken = localStorage.getItem('bearer_token');

    axios
      .post(
        'api/transaction/createTransaction',
        { transaction: transaction, project_id: props.project.id },
        { headers: { authorization: `Bearer ${myToken}` } }
      )
      .then((response) => {
        if (response.data.status) {
          notification(
            'success',
            t('keyword_thank_you'),
            response.data.message
          );
          setTransaction({ amount: 0, bank_account: null, currency_id: 1 });
          setOpenInvestmentPopup(false);
          props.getProject();
        }
      })
      .catch((err) => {
        console.log(err);
        notification('error', 'Server error', err); //TOTRANSLATE
      });
  }

  function getMyBankAccounts() {
    let myToken = localStorage.getItem('bearer_token');

    axios
      .get('api/settings/getMyBankAccounts', {
        headers: { authorization: `Bearer ${myToken}` },
      })
      .then((response) => {
        if (response.data.accounts.length)
          editTransaction('bank_account', response.data.accounts[0].id);
        setMyBankAccounts(response.data.accounts);
      })
      .catch((err) => {
        console.log(err);
        notification('error', t('keyword_problem'), err);
      });
  }

  const updown = (key, e) => {
    console.log(e);
    let newValue;
    if (e < transaction[key]) {
      newValue = e - 10000;
    } else {
      newValue = e + 10000;
    }

    editTransaction(key, newValue);
  };

  function editTransaction(key, e) {
    if (key === 'amount') {
      setTransaction((prevState) => ({
        ...prevState,
        [key]: Math.round(e / 10000) * 10000,
      }));
    } else {
      setTransaction((prevState) => ({
        ...prevState,
        [key]: e,
      }));
    }
  }

  useEffect(() => {
    editTransaction('amount', minimum_amount);
  }, [minimum_amount]);

  return !props.project || !Object.keys(props.project).length ? (
    <Card>
      <Skeleton active />
    </Card>
  ) : (
    <Card style={{ top: 30, position: 'sticky', borderRadius: '15px' }}>
      <Row justify="start" gutter={[24, 24]}>
        <Col span={10} className="gutter-row">
          <h2>
            {' '}
            <Rate allowClear={false} disabled value={partner_id} />{' '}
          </h2>
        </Col>
        <Col span={8} className="gutter-row"></Col>
        <Col span={6} className="gutter-row">
          <span style={{ color: '#108ee9', fontSize: '120%' }}>
            {is_public ? t('keyword_public') : t('keyword_private')}
          </span>
        </Col>
      </Row>
      <Divider>
        <h2> {title}</h2>
      </Divider>
      <Row gutter={[48, 48]}>
        <Col justify="start" xs={12}>
          <h4> {t('keyword_annual_return')}</h4>
          <h2>{annual_rate} %</h2>
        </Col>
        <Col justify="end" xs={12}>
          <h4>{t('new_project_main_due_date_1')}</h4>
          <h2>{due_date && new Date(due_date).toISOString().split('T')[0]}</h2>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[48, 48]}>
        <Col justify="center" xs={24}>
          <Progress
            className="project-progress"
            success={{
              percent: props.isPreview ? 0 : (total_paid * 100) / target,
            }}
            percent={Math.floor((total_promised * 100) / target)}
            style={{ size: '200%' }}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Space size={60}>
          <div>
            {' '}
            <Row gutter={[0, 48]}>
              <Col xs={8}>
                {' '}
                <div className="box red"></div>{' '}
              </Col>
              <Col xs={16}>{t('keyword_promised')}</Col>{' '}
            </Row>{' '}
            <Row>{printPrice(total_promised)} kč</Row>{' '}
          </div>
          <div>
            {' '}
            <Row gutter={[0, 48]}>
              <Col xs={8}>
                {' '}
                <div className="box green"></div>
              </Col>
              <Col xs={16}>{t('keyword_paid')}</Col>{' '}
            </Row>{' '}
            <Row>{printPrice(total_paid)} kč</Row>{' '}
          </div>
        </Space>
      </Row>
      <Divider />
      <Row gutter={[48, 48]}>
        <Col justify="start" xs={12}>
          <h4>{t('keyword_total_paid')}</h4>
          <h2> {printPrice(total_paid)} kč</h2>
        </Col>
        <Col justify="end" xs={12}>
          <h4>{t('keyword_target')}</h4>
          <h2>{printPrice(target)} kč</h2>
        </Col>
      </Row>

      <Divider />
      {!props.noInvest && (
        <>
          <Row gutter={[48, 48]}>
            <Col justify="center" align="center" xs={24}>
              {new Date(start_date) > new Date() ||
              new Date(closing_date) < new Date() ? (
                <span>{t('project_investment_period_closed')} </span>
              ) : (
                <Button
                  disabled={
                    props.isPreview || target <= total_promised ? true : false
                  }
                  onClick={() => {
                    setOpenInvestmentPopup(true);
                    getMyBankAccounts();
                  }}
                  style={{
                    background: props.colors.success,
                    color: 'white',
                    width: '30%',
                    borderRadius: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('keyword_invest')}
                </Button>
              )}
            </Col>
          </Row>
          <Divider />
        </>
      )}

      <Row gutter={[24, 24]}>
        <Col xs={12}>
          <h3> {t('project_opening_reservation') } </h3>
        </Col>
        <Col xs={12}>
          <h3>
            {' '}
            {start_date &&
              new Date(start_date).toISOString().split('T')[0]}{' '}
          </h3>
        </Col>
      </Row>

      <Row align="center" justify="center" gutter={[24, 24]}>
        <Col xs={12}>
          <h3> {t('new_project_preview_closing_date_1')} </h3>
        </Col>
        <Col xs={12}>
          <h3>
            {' '}
            {closing_date &&
              new Date(closing_date).toISOString().split('T')[0]}{' '}
          </h3>
        </Col>
        <Col xs={24}>
          <h3> {getMonthsDaysDifference(closing_date)} </h3>
        </Col>
      </Row>

      <Modal
        title={t('project_invest_popup_title_1')}
        open={openInvestmentPopup}
        onOk={() => {
          sendInvestmentRequest();
        }}
        onCancel={() => {
          setOpenInvestmentPopup(false);
        }}
        okText={t('keyword_submit')}
        cancelText={t('keyword_cancel') }
      >
        <Row>
          <Col span={12}>
            <Slider
              min={minimum_amount}
              max={target - total_promised}
              onChange={(e) => {
                editTransaction('amount', e);
              }}
              value={transaction.amount || minimum_amount}
              defaultValue={minimum_amount}
            />
          </Col>
          <Col span={12}>
            <InputNumber
              autoFocus
              min={minimum_amount}
              defaultValue={minimum_amount}
              max={target - total_promised}
              formatter={(value) => `${printPrice(value)}`}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              addonAfter="kč"
              value={transaction.amount || minimum_amount}
              onChange={(e) => {
                editTransaction('amount', e);
              }}
              onStep={(e) => {
                updown('amount', e);
              }}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <span>{t('project_invest_popup_choose_bank_1')}</span> <br />
          <Col xs={24}>
            <Radio.Group
              value={transaction.bank_account || ''}
              buttonStyle="solid"
            >
              {myBankAccounts.map((acc) => (
                <Radio.Button
                  onClick={(e) => {
                    editTransaction('bank_account', acc.id);
                  }}
                  value={acc.id}
                >
                  {acc.bank_account}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Col>
        </Row>
        <Divider />
        <Row>
          <span>{t('project_invest_popup_choose_currency_1')}</span>
          <br />
          <Col xs={24}>
            <Radio.Group defaultValue={1} buttonStyle="solid">
              <Radio.Button value={1}>kč</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </Modal>
    </Card>
  );
};

export default CardProjectDetails;
