import {
  SettingOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from '@ant-design/icons';
import { Collapse, Select, Divider, Row, Col, Card, Space, Spin } from 'antd';
import React, { useState } from 'react';
import './contact.css';

import notification from '../../../components/Notifications/Notification';
import axios from 'axios';
// import Map from '../../../components/Google/Map';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import CardContactInfos from './CardContactinfos';
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const Map = () => {
  return (
    <GoogleMap
      zoom={15}
      center={{ lat: 49.1896251, lng: 16.5867397 }}

      mapContainerClassName="map-container"
    ><Marker position={{ lat: 49.1896251, lng: 16.5867397 }} />
      <Marker position={{ lat: 49.1913006, lng: 16.6087425 }} />
    </GoogleMap>
  );
};

function Contact() {
  const { t, i18n } = useTranslation();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBbBR4COwa-sLqoQtgjS9g0naPd75_4HAs',
  });
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [mailBody, setMailBody] = useState("");
  const [disable, setDisable] = useState(false);


  function onChange(key, value) {

    if (key === 'name') {
      setUserName(value);
    } else if (key === 'email') {
      setUserEmail(value);
    } else if (key === 'body') {
      setMailBody(value);
    }


  }


  function sendMail() {
    if (disable) {
      notification('warning', `Je nám to líto`, `Již jste odeslali mail`);

    }
    else if (!userName || !userEmail || !mailBody) {
      notification('warning', t('create_project_verifications_main_2'), `Formulář není platný nebo jsou některá pole prázdná`);

    } else {

      axios.post(`api/auth/contactPageSendMail`, {
        name: userName, email: userEmail, body: mailBody
      },
        { headers: { authorization: `Bearer ${localStorage.getItem('bearer_token')}` } })
        .then(res => {

          if (res.data.status) {
            setDisable(true);
            setUserName('');
            setUserEmail('');
            setMailBody('');
            notification('success', t('keyword_thank_you'), t('contact_form_email_sent'));

          } else {
            notification('error', t('keyword_problem'), res.data.message);

          }


        }).catch(err => {
          console.error(err.response.data.message);
          notification('error', t('keyword_problem'), err.response.data.message);

        }
        );
    }
    return false;

  }



  return (
    <div className="contact-page">
      <Row gutter={[24, 24]} type="flex" xs={24}>
        <Col xs={24} lg={16}>
          <CardContactInfos />
        </Col>

        <Col xs={24} lg={8}>
          <Card style={{ height: '100%', borderRadius: '10px' }}>
            <div class="fcf-body">
              <div id="fcf-form">
                <h3 class="fcf-h3">{t('contact_form_title')}</h3>


                <div class="fcf-form-group">
                  <label for="Name" class="fcf-label">
                    {t('contact_form_name')}
                  </label>
                  <div class="fcf-input-group">
                    <input
                      type="text"
                      id="Name"
                      name="Name"
                      class="fcf-form-control"
                      required
                      value={userName}
                      onChange={(e) => { onChange('name', e.target.value) }}
                    />
                  </div>
                </div>

                <div class="fcf-form-group">
                  <label for="Email" class="fcf-label">
                    {t('contact_form_address')}
                  </label>
                  <div class="fcf-input-group">
                    <input
                      type="email"
                      id="Email"
                      name="Email"
                      class="fcf-form-control"
                      required
                      value={userEmail}
                      onChange={(e) => { onChange('email', e.target.value) }}
                    />
                  </div>
                </div>

                <div class="fcf-form-group">
                  <label for="Message" class="fcf-label">
                    {t('contact_form_message')}
                  </label>
                  <div class="fcf-input-group">
                    <textarea
                      id="Message"
                      name="Message"
                      class="fcf-form-control"
                      value={mailBody}
                      height={200}
                      style={{minHeight: '150px'}}
                      required
                      onChange={(e) => { onChange('body', e.target.value) }}
                    ></textarea>
                  </div>
                </div>

                <div class="fcf-form-group">
                  <button
                  //  disabled={disable}

                    class="fcf-btn fcf-btn-primary fcf-btn-lg fcf-btn-block"
                    style={{
                      background: '#003464',
                      border: '#003464 solid 1px',
                    }}
                    onClick={sendMail}
                  >
                    {t('contact_form_button')}
                  </button>
                </div>

              </div>
            </div>
          </Card>
        </Col>
      </Row>



    </div>
  );
}

export default Contact;
