import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import axios from 'axios';
import {
  Row,
  Col,
  Input,
  Table,
  Button,
  DatePicker,
  Rate,
  Card,
  Steps,
  Modal,
  Upload,
  Select,
  Tag,
  Switch,
  Divider,
} from 'antd';

import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import DragUpload from '../../../components/CRUD_project/DragUpload';

const { MonthPicker, RangePicker } = DatePicker;

const { Dragger } = Upload;
const { Step } = Steps;
const { TextArea } = Input;
const { Column, ColumnGroup } = Table;

const PopupUser = (props) => {
  const { t, i18n } = useTranslation();

  const { openUserEdit, setOpenUserEdit, setChosenUser, chosenUser, getUsers } =
    props;
  const [newUser, setNewUser] = useState({});
  const [roles, setRoles] = useState([
    { id: 1, role: 'investor' },
    { id: 2, role: 'creator' },
    { id: 3, role: 'admin' },
  ]);
  useEffect(() => {
    setNewUser(chosenUser);
  }, [chosenUser]);



  function onChange(key, value) {


    if (key === 'active' || key === 'is_verified' || key === 'partner') {
      setNewUser((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
    if (key === 'role_id') {
      for (var i in roles) {
        if (roles[i]['id'] == value) {
          setNewUser((prevState) => ({
            ...prevState,
            [key]: value,
            role: roles[i]['role'],
          }));
        }
      }
    }
  }

  function EditUser() {
    axios
      .post(
        'api/admin/editUser',
        { newUser: newUser },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('bearer_token')}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          getUsers();
          setOpenUserEdit(false);
          setChosenUser({});
          alert(res.data.message);
          notification('error', 'Success', res.data.message);
        } else {
          notification('error', 'error', res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        alert(err);
      });
  }

  return (
    <>
      <Modal
        title={`${chosenUser.first_name} ${chosenUser.last_name} - ${t('keyword_edit')}`}
        centered
        open={openUserEdit}
        onOk={() => EditUser()}
        okText={t('keyword_submit')}
        cancelText={t('keyword_cancel')}
        onCancel={() => setOpenUserEdit(false)}
        width={1000}
      >
        <h2>Not editable values</h2>
        <Row xs={24}>
          <Col xs={24} sm={12} lg={8}>
            <span>{t('keyword_firstname')}</span>
            <Input disabled value={chosenUser.first_name} />
          </Col>

          <Col xs={24} sm={12} lg={8}>
            <span>{t('keyword_lastname')}</span>
            <Input disabled value={chosenUser.last_name} />
          </Col>

          <Col xs={24} sm={12} lg={8}>
            <span>{t('keyword_email')}</span>
            <Input disabled value={chosenUser.email} />
          </Col>
        </Row>

        <Row xs={24}>
          <Col xs={24} sm={12} lg={8}>
            <span>{t('keyword_address')}</span>
            <Input disabled value={chosenUser.address} />
          </Col>

          <Col xs={24} sm={12} lg={8}>
            <span>{t('keyword_city')}</span>
            <Input disabled value={chosenUser.city} />
          </Col>

          <Col xs={24} sm={12} lg={8}>
            <span>{t('keyword_country')}</span>
            <Input disabled value={chosenUser.country} />
          </Col>
        </Row>

        <Row xs={24}>
          <Col xs={24} sm={12} lg={8}>
            <span>{t('keyword_phone')}</span>
            <Input disabled value={chosenUser.phone_number} />
          </Col>
        </Row>

        <Divider />

        <h2>editable values</h2>

        <Row xs={24} gutter={[48, 48]}>
          <Col xs={24} sm={8} lg={8}>
            <span>{t('keyword_active')} </span>
            <Switch
              checkedChildren={t('keyword_yes')}
              unCheckedChildren={t('keyword_no')}
              checked={newUser.active}
              onChange={(e) => {
                onChange('active', e);
              }}
            />
          </Col>

          <Col xs={24} sm={8} lg={8}>
            <span>{t('keyword_identity')} </span>
            <Switch
              checkedChildren={t('keyword_verified')}
              unCheckedChildren={t('keyword_not_verified')}
              checked={newUser.is_verified}
              onChange={(e) => {
                onChange('is_verified', e);
              }}
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <span>Rating </span>
            <Rate
              allowClear={false}
              value={newUser.partner}
              onChange={(e) => {
                onChange('partner', e);
              }}
            />
          </Col>

          <Col xs={24} sm={8} lg={8}>
            <span>Role </span>
            <Select
              value={newUser.role}
              onChange={(e) => {
                onChange('role_id', e);
              }}
              style={{
                width: 120,
              }}
              options={[
                {
                  value: '1',
                  label: 'investor',
                },
                {
                  value: '2',
                  label: 'creator',
                },
                {
                  value: '3',
                  label: 'admin',
                },
              ]}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default PopupUser;
