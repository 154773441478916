import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../views/logIn/Settings/settings.css";
import TablePayments from "../../components/Tables/TablePayments";
import axios from 'axios';
import {
  Row,
  Col,
  Card,
  Input,
  Modal,
  Button,
} from "antd";
import { DeleteOutlined, BankOutlined } from "@ant-design/icons";
import CardBankAccount from "./CardBankAccount";
import notification from "../Notifications/Notification";


const PaymentInfos = (props) => {
  const { t, i18n } = useTranslation();

  const [accounts, setAccounts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBankAccount, setNewBankAccount] = useState('');


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {

    let myToken = localStorage.getItem('bearer_token');

    axios.post("api/settings/addBankAccount", { bankAccount: newBankAccount },
      { headers: { authorization: `Bearer ${myToken}` } }).then((res) => {







        if (res.data.status) {
          notification('success', 'Success', res.data.message);//TOTRANSLATE
          getBankAccounts();
        }

        setIsModalOpen(false);


      }).catch((err) => {
        console.log(err)
        notification('error', 'Fail', 'Error while deleting this bank account')//TOTRANSLATE

      });


  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };




  function getBankAccounts() {

    axios.get("api/settings/getMyBankAccounts",
      { headers: { authorization: `Bearer ${localStorage.getItem('bearer_token')}` } }).then((res) => {
        setAccounts(res.data.accounts);
        console.log('res.data.accounts');

      }).catch((err) => console.log(err));
  }
  useEffect(() => {


    getBankAccounts();


  }, []);








  return (
    <>
      <Col xs={24} className="mb-24">
        <Card style={{ borderRadius: '15px' }}
          className="header-solid h-full ant-card-p-0"
          title={
            <>
              <Row
                gutter={[24, 0]}
                className="ant-row-flex ant-row-flex-middle"
              >
                <Col xs={24} md={12}>
                  <h6 className="font-semibold m-0">{t('settings_banktab_title_1')}</h6>
                </Col>
                <Col xs={24} md={12} className="d-flex">
                  <Button
                    style={{
                      background: props.colors.primary,
                      color: props.colors.white,
                    }}
                    onClick={showModal}
                  >
                    {t('settings_banktab_add_account_1')}
                  </Button>
                </Col>
              </Row>
            </>
          }
        >

          <Row gutter={[24, 0]}>
            {accounts.map(item =>
              <Col span={24} md={12}>
                <CardBankAccount key={item.id} getBankAccounts={getBankAccounts} bank_account={item} />
              </Col>

            )}


          </Row>
        </Card>
      </Col>

      <Col xs={24}>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            {/* <TablePayments colors={props.colors} /> */}
          </Col>
        </Row>
      </Col>



      <Modal
        title={t('settings_banktab_popup_title_1')}
        open={isModalOpen}
        onOk={handleOk}
        cancelText={t('keyword_cancel')}
        onCancel={handleCancel}>

        <Row>
          <Col xs={24}>


            <Input
              style={{ height: '60px', borderRadius: '10px' }}
              prefix={<BankOutlined className="site-form-item-icon" />}
              type="text"
              value={newBankAccount}
              placeholder="...-......../...."
              onChange={(e) => { setNewBankAccount(e.target.value) }
              }
            />
          </Col>

        </Row>


      </Modal>




    </>
  );
};

export default PaymentInfos;
