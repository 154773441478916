import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './charts.css';
import {
  Row,
  Col,
  Card,
  Button,
  Timeline,
  Typography,
} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import printPrice from '../../Tools/printPrice';






class TimelineHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  // componentDidMount() {

  // }



  render() {

    const { transactions, t } = this.props


    return (
      <ResponsiveContainer className="full-card" width="100%" height="100%">
        <Card bordered={false} className="criclebox h-full">

          <Typography.Title level={5}>{t('dashboard_timeline_transactions_title_1')}</Typography.Title>
          <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
            <span className="bnb2">{`${transactions.length} ${t('keyword_projects')}`} </span>
          </Paragraph>

          <Timeline
            pending={`${t('keyword_recording')}...`}
            className="timelinelist"
            reverse={false}
            style={{ paddingLeft: '10px' }}
          >
            {transactions.map((t, index) => (
              <Timeline.Item color={t.deleted ? 'red' : t.status ? 'green' : 'orange'} key={'myTransactions' + index}>
                <Typography.Title level={5}>{t.title} - {printPrice(t.amount)} kč </Typography.Title>
                <Typography.Text>{t.date && t.date.split('T')[0]} - <a href={`project?id=${t.project_id}`} >{t.title}</a></Typography.Text>
              </Timeline.Item>
            ))}
          </Timeline>
          {/* <Button
    type="primary"
  >
    {<MenuUnfoldOutlined />} Refresh data
  </Button> */}

        </Card>
      </ResponsiveContainer>
    );
  }
}


export default withTranslation()(TimelineHistory);