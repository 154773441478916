import React from "react";

import { Col, Row } from "antd";

import t from "../../Fixtures/allTextsLogOut";

import vasiMakleri from "../../../images/vasiMakleri.png";
import vasiPoradci from "../../../images/vasiPoradci.svg";
import benesova from "../../../images/benesova.png";
import aposConsult from "../../../images/aposConsult.png";
import goldenFin from "../../../images/goldenFin.png";

const Partners = (props) => {
  return (
    <Row style={{ padding: "20px 100px", maxWidth: "1500px", margin: "auto" }}>
      <Col xs={24} style={{ textAlign: "left" }}>
        <h2 style={{ color: "#003464"}}>OUR PARTNERS</h2>
      </Col>
      <Col xs={24}>
        <Row justify="space-between" wrap="true" gutter={[50, 20]}>
          <Col xs={12} md={8} lg={6} xl={4}>
            <img
              src={vasiMakleri}
              alt="vasi Makleri"
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={12} md={8} lg={6} xl={4}>
            <img
              src={vasiPoradci}
              alt="vasi Makleri"
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={12} md={8} lg={6} xl={4}>
            <img src={benesova} alt="vasi Makleri" style={{ width: "100%" }} />
          </Col>
          <Col xs={12} md={8} lg={6} xl={4}>
            <img
              src={aposConsult}
              alt="vasi Makleri"
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={12} md={8} lg={6} xl={4}>
            <img src={goldenFin} alt="vasi Makleri" style={{ width: "100%" }} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Partners;
