import React from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

import { Button, Col, Drawer, Menu, Row } from 'antd';

import CardProject from '../../../components/Cards/CardProject';
import Fixtures from '../../../components/Fixtures/ProjectsFixtures';
import MarketHeader from '../../../components/MarketHeader/MarketHeader';
import ListProjects from '../../../components/HomePage/ListProjects/ListProjects';
import t from "../../../components/Fixtures/allTextsLogOut"

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerInfos: {
        total_invested: '...',
        total_amount: '...',
        total_projects: '...',
      },
      projects: [],
      errorLoading: false,
      loading: true,
    };
  }

  componentDidMount() {
    axios
      .get(`api/project/projectHeader`, {})
      .then((res) => {
        this.setState({
          headerInfos: res.data,
          loadMore: false,
        });
      })
      .catch((err) => console.log(err));

    axios
      .get('api/project/getpublicprojects')
      .then((response) => {
        if (response.data.projects.length >= 10) {
          this.setState({
            loadMore: true,
          });
        }
        this.setState({
          loading: false,
          projects: response.data.projects,
        });
      })
      .catch((error) => {
        console.log('ERROR ', error);
        this.setState({
          loading: false,
          errorLoading: true,
        });
      });
  }

  render() {
    const { datas, t, i18n } = this.props;

    return (
      <div
        style={{ width: '100vw', backgroundColor: 'white', padding: '50px 0 ' }}
      >
        <Row
          style={{
            maxWidth: '1500px',
            margin: 'auto',
            backgroundColor: 'white',
          }}
        >
          <MarketHeader headerInfos={this.state.headerInfos} />
          <Col
            xs={24}
            style={{
              backgroundColor: '#FFF',
              minHeight: '80vh',
              marginTop: '20px',
              padding: '15px',
            }}
          >
            <Row gutter={[10, 10]}>
              <ListProjects
                projects={this.state.projects}
                error={this.state.errorLoading}
                loading={this.state.loading}
              />
            </Row>
          </Col>
          {this.state.loadMore && (
            <Col xs={24}>
              <Row justify="center">
                <Col xs={20}>
                  <button
                    style={{
                      backgroundColor: '#003464',
                      color: '#FFF',
                      borderRadius: '10px',
                      height: '50px',
                      width: '200px',
                      fontSize: '1.2em',
                      margin: 'auto',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {t('contact_form_button')}
                  </button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default withTranslation()(Projects);
