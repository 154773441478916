
import { SearchOutlined,EyeTwoTone } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Button, Input,Image, Space, Table, Progress, Column } from 'antd';
import React, { useRef, useState } from 'react';
/* import Highlighter from 'react-highlight-words'; */


const TableMarket = (props) => {
  const { t, i18n } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
     /*  searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ), */

      text
  });

  const columns = [
    {
        title: t('keyword_picture'),
        dataIndex: 'url',
        key: 'url',
        render: (url) => url && url.length && <Image width={125} style={{maxHeight: 100}} src={url[0]} />,
      },
    {
      title: t('keyword_project_title'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.length - b.title.length,
      ...getColumnSearchProps('title'),
    },
    {
        title: t('keyword_location'),
        dataIndex: 'city',
        key: 'location',
        sorter: (a, b) => a.location.length - b.location.length,
        ...getColumnSearchProps('location'),
      },
    {
      title: t('keyword_duration'),
      dataIndex: 'duration',
      key: 'duration',
      sorter: (a, b) => a.duration - b.duration,
      render: (duration) => <span>{duration } Months </span>  ,
      sortDirections: ['descend', 'ascend'],

    },
    {
        title: t('keyword_completion'),
        dataIndex: 'total_paid',
        key: 'total_paid',
        render: (text, record) =>   <Progress
        percent={(record.total_paid*100/record.target).toFixed(0)}
        type='line'
      /> ,
     
  
      },
    {
      title: t('keyword_annual_return'),
      dataIndex: 'annual_rate',
      key: 'annual_rate',
      ...getColumnSearchProps('annual_return'),
      sorter: (a, b) => a.annual_return - b.annual_return,
      render: (annual_return) => <span>{annual_return } % </span>  ,

      sortDirections: ['descend', 'ascend'],
    },
    {
        title: t('keyword_open'),
        dataIndex: 'id',
        key: 'id',
        render: (id) =>   <Button href={'project?id='+id} ><EyeTwoTone /></Button>,
      },
  ];
  return <Table columns={columns} dataSource={props.infos}>


  </Table>;
};



export default TableMarket