import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import axios from 'axios';
import {
  Row,
  Col,
  Card,
  Input,
  Segmented,
  Avatar,
  Modal,
  Button,
} from "antd";


import czech_flag from '../../images/czech_flag.png';
import english_flag from '../../images/english_flag.png';
const Translator = (props) => {
  const { t, i18n } = useTranslation();

  const [accounts, setAccounts] = useState([]);

 const handleChangeLng=(lng)=>{
    i18n.changeLanguage(lng);
localStorage.setItem('i18lng', lng);
 }

  return (
    <>
   

   <Segmented
value={window.localStorage.getItem('i18lng') || i18n.language}

options={[

    {
        label: (
            <div 
                style={{
                    padding: 4,
                  
                }}
                onClick={()=>{handleChangeLng('en')}}
            >
                <Avatar
                    style={{
                        backgroundColor: '#f56a00',
                    }}
                    icon={<img src={english_flag} />}
                >
               
                </Avatar>
                <div>EN</div>
            </div>
        ),
        value: 'en',
    },
    {
        label: (
            <div
                style={{
                    padding: 4,
                }}
                onClick={()=>{handleChangeLng('cz')}}
            >
                <Avatar
                    style={{
                        backgroundColor: '#87d068',
                    }}
                    icon={<img src={czech_flag} />}
                />
                <div>CZ</div>
            </div>
        ),
        value: 'cz',
    },
]}
/>



    </>
  );
};

export default Translator;
